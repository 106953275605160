import useKeyboard from 'hooks/common/useKeyboard';
import { ModalProps, ModalType } from '../../../types/common/modal';
import Button from '../button/Button';
import { ButtonColor } from '../button/Button.type';
import ModalContainer from '../modal/ModalContainer';

const FailPopup = ({ closeModal, text }: ModalProps) => {
  useKeyboard({ closeModal, modalType: ModalType.alert });

  return (
    <ModalContainer>
      <div className='flex flex-col items-center justify-center gap-10 px-24 py-16 border rounded bg-gray-f9'>
        <pre className='font-sans text-sm text-center break-words'>{text}</pre>
        <div className='flex justify-center w-full'>
          <Button text='닫기' height='h-12' color={ButtonColor.primary} onClick={closeModal} />
        </div>
      </div>
    </ModalContainer>
  );
};
export default FailPopup;
