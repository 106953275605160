/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-constant-condition */
import LoadingContainer from 'components/common/loading/LoadingContainer';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useLoadingStore from 'stores/loading';
import { ScreenType } from 'types/feature/content/contents';
import { Floor } from 'types/feature/floor/floor';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import MapDraw from 'utils/map/mapDraw';
import FloorList from './floor/FloorList';
import MapScreen from './screen/MapScreen';
import MachineCenterSign from './sign/MachineCenterSign';

type Props = {
  mode: 'register' | 'edit';
  registeredMachine?: RegisterMachine;
  screenType: ScreenType;
  floorList: Floor[];
};

const MachineMapContainer = ({ screenType, mode, registeredMachine, floorList }: Props) => {
  // ref
  const mapRef = useRef<HTMLDivElement>(null);

  // hook
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  // store
  const loadingStore = useLoadingStore();

  const mapInstance = new MapDraw();

  const fetchWorkspaceAndMapData = async () => {
    try {
      if (!workspaceId) return;
      if (!mapRef.current) return;

      const response = await getWorkspace(workspaceId);

      if (response.data) {
        const workspace = response.data;

        const mapData = await MapDraw.dabeeoMaps.getMapData({
          clientId: workspace.map.clientId,
          clientSecret: workspace.map.clientSecret,
          version: workspace.map.version,
          serverType: 'SERVER_STUDIO4',
        });

        if (mapData) {
          MapDraw.mapData = mapData;

          /**
           * 메인층을 찾는다.
           */
          const findMainFloor = floorList?.find(floor => floor.main);

          if (findMainFloor) {
            MapDraw.defaultFloor = { ...findMainFloor };

            /**
             * 기기 수정일 경우
             */
            if (mode === 'edit') {
              if (registeredMachine) {
                await mapInstance.showMap(mapRef.current, {
                  controlOption: {
                    zoom: Number(registeredMachine.mapPosition.zoom) || 0,
                    rotate: Number(registeredMachine.mapPosition.rotation) || 0,
                    pan: {
                      x: Number(registeredMachine.mapPosition.centerPositionX) || 0,
                      y: Number(registeredMachine.mapPosition.centerPositionY) || 0,
                      z: 0,
                    },
                  },
                  floor: registeredMachine.floorId,
                  enableGeoreferencing: false,
                });
              }

              return;
            }

            /**
             * 기기 등록일 경우
             */
            if (mode === 'register') {
              await mapInstance.showMap(mapRef.current, { floor: findMainFloor.id, enableGeoreferencing: false });
            }
          }
        }
      }
    } catch {
      loadingStore.setLoading(false);
    } finally {
      loadingStore.setLoading(false);
    }
  };

  /**
   * 등록
   */
  useEffect(() => {
    if (mode === 'register') {
      fetchWorkspaceAndMapData();
    }

    /**
     * cleanup
     */
    return () => {
      if (mode === 'register') {
        if (mapRef.current) {
          mapInstance.cleanup();
        }
      }
    };
  }, [mode, workspaceId, floorList]);

  /**
   * 수정
   */
  useEffect(() => {
    if (mode === 'edit') {
      fetchWorkspaceAndMapData();
    }

    /**
     * cleanup
     */
    return () => {
      if (mode === 'edit') {
        if (mapRef.current) {
          mapInstance.cleanup();
        }
      }
    };
  }, [mode, workspaceId, floorList, registeredMachine]);

  useEffect(() => {
    return () => {
      loadingStore.setLoading(true);
    };
  }, []);

  return (
    <div className='w-[900px] h-[666px] shrink-0 relative overflow-hidden'>
      {loadingStore.loading && <LoadingContainer />}

      <>
        <MachineCenterSign />
        <MapScreen screenType={screenType} />

        {/* 층 선택 */}
        {mode === 'edit' && (
          <FloorList floors={floorList || []} currentFloorId={registeredMachine && registeredMachine?.floorId} />
        )}

        {/* 지도 Viewer */}
        <div ref={mapRef} className='w-full h-full' />
      </>
    </div>
  );
};

export default MachineMapContainer;
