import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ModalContainer from 'components/common/modal/ModalContainer';
import useKeyboard from 'hooks/common/useKeyboard';
import { ModalType } from 'types/common/modal';

type ConfirmPopupProps = {
  name: string;
  message: string | undefined;
  okMessage?: string;
  cancelMessage?: string;
  onClickCancel: (e?: any) => void;
  onClickOk: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
  disabled?: boolean;
};

const DeleteConfirmPopup = ({
  name,
  message,
  onClickCancel,
  onClickOk,
  okMessage = '확인',
  cancelMessage = '취소',
  disabled,
}: ConfirmPopupProps) => {
  useKeyboard({ closeModal: onClickCancel, modalType: ModalType.confirm, callback: onClickOk });
  return (
    <ModalContainer>
      <div className='flex flex-col items-center justify-center py-20 bg-white border gap-7 px-28 border-gray-cc'>
        <div className='text-gray-44 text-center font-bold leading-5 tracking-[-0.32px]'>{name}</div>
        <pre className='font-sans text-sm text-center'>{message}</pre>
        <div className='flex justify-center w-full gap-2'>
          <Button text={cancelMessage} color={ButtonColor.secondary} onClick={onClickCancel} />
          <Button disabled={disabled} text={okMessage} color={ButtonColor.primary} onClick={onClickOk} />
        </div>
      </div>
    </ModalContainer>
  );
};

export default DeleteConfirmPopup;
