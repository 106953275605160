import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { NavigationSetting, Workspace, WorkspaceBasicForm, WorkspaceForm } from 'types/feature/workspace/workspace';

const useWorkspace = () => {
  const { api } = useAxios();

  const getWorkspaces = async () => {
    const res = await api.get<ApiResponse<Workspace[]>>(Endpoint.workspaces);
    return res.data;
  };

  const getWorkspace = async (id: string) => {
    const result = await api.get<ApiResponse<Workspace>>(Endpoint.workspaceV2.replace(':workspaceId', id));
    return result;
  };

  const saveWorkspace = async (workspace: WorkspaceForm) => {
    const result = await api.post<ApiResponse<{ id: string }>>(Endpoint.workspaces, workspace);
    return result.data.id;
  };

  const deleteWorkspace = async (id: string) => {
    const res = api.delete(`${Endpoint.workspaces}/${id}`);
    return res;
  };

  const putWorkspace = async (id: string, updateForm: WorkspaceForm) => {
    const result = api.put<ApiResponse<WorkspaceForm>>(Endpoint.workspace.replace(':workspaceId', id), updateForm);
    return result;
  };

  // 워크스페이스 모의보행 설정
  const patchWorkspaceNaviSetting = async (workspaceId: string, naviSetting: NavigationSetting) => {
    const result = api.patch<ApiResponse<any>>(
      Endpoint.workspaceNavigation.replace(':workspaceId', workspaceId),
      naviSetting,
    );
    return result;
  };

  // 워크스페이스 기본 설정 수정
  const putWorkspaceBasic = async (workspaceId: string, basicForm: WorkspaceBasicForm) => {
    const result = api.put(Endpoint.workspace.replace(':workspaceId', workspaceId), basicForm);
    return result;
  };

  // 워크스페이스 client path 수정
  const patchWorkspaceClientPath = async (workspaceId: string, clientPath: string) => {
    const result = api.patch(Endpoint.workspaceClientPath.replace(':workspaceId', workspaceId), {
      clientPath,
    });
    return result;
  };

  // 워크스페이스 웹 사용 유무 수정
  const patchWorkspaceWebUsed = async (workspaceId: string, used: boolean) => {
    const result = api.patch(Endpoint.workspaceWebUsed.replace(':workspaceId', workspaceId), {
      used,
    });
    return result;
  };

  // 워크스페이스 모바일 사용 유무 수정
  const patchWorkspaceMobileUsed = async (workspaceId: string, used: boolean) => {
    const result = api.patch(Endpoint.workspaceMobileUsed.replace(':workspaceId', workspaceId), {
      used,
    });
    return result;
  };

  return {
    getWorkspaces,
    getWorkspace,
    saveWorkspace,
    deleteWorkspace,
    putWorkspace,
    patchWorkspaceNaviSetting,
    putWorkspaceBasic,
    patchWorkspaceClientPath,
    patchWorkspaceWebUsed,
    patchWorkspaceMobileUsed,
  };
};
export default useWorkspace;
