import Input from 'components/common/input/Input';
import Label from 'components/common/label/Label';
import { CustomContentTitleWordLimit } from 'data/length';
import useLanguage from 'hooks/feature/language/useLanguage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextType } from 'types/common/text';
import { Language, LanguageName, MultiLangs } from 'types/feature/language/language';

type Props = {
  isEdit: boolean;
  defaultValue: MultiLangs;
  handleChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomContentMultiLangEditInputs = ({ isEdit, defaultValue, handleChangeTitle }: Props) => {
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();

  const [languages, setLanguages] = useState<Language[]>([]);

  const fetchLanguages = async () => {
    if (workspaceId) {
      const result = await getLanguages(workspaceId);
      const displayLanguages: Language[] = result;
      setLanguages(displayLanguages);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  return (
    <div className='flex flex-col gap-2.5 flex-1'>
      {isEdit
        ? languages.map(({ code }) => (
            <div key={code} className='flex items-center gap-2.5'>
              <Label
                required={false}
                name={LanguageName[code]}
                flexStyle='justify-end'
                labelTextSize='h5_bold'
                labelSize='md'
              />

              <Input
                width='w-full'
                textStyle='h3'
                maxLength={CustomContentTitleWordLimit[code.toLowerCase()]}
                placeholder={`${LanguageName[code]} 타이틀을 입력해주세요.`}
                name='title'
                onChange={e => {
                  const { length } = e.target.value;
                  if (length > CustomContentTitleWordLimit[code.toLowerCase()]) return;
                  handleChangeTitle(e);
                }}
                id={code.toLowerCase()}
                defaultValue={defaultValue[code.toLowerCase()]}
              />
            </div>
          ))
        : languages.map(({ code }) => (
            <div key={code} className='flex gap-2.5 h-9 items-center'>
              <div className='text-gray-99 font-medium text-xs flex justify-end text-right w-[110px]'>
                {LanguageName[code]}
              </div>
              <div className={`${TextType.h4} w-80`}>{defaultValue[code.toLowerCase()]}</div>
            </div>
          ))}
    </div>
  );
};
export default CustomContentMultiLangEditInputs;
