import UsedBadge from 'components/common/badge/used-badge/UsedBadge';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import FormControl from 'components/common/form-control/FormControl';
import { ChangeEvent } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { TextType } from 'types/common/text';
import { PointOperation } from 'types/feature/workspace/workspace';
import HoursTextInputs from './HoursTextInputs';
import HoursTime from './HoursTime';

type Props = {
  operation: PointOperation;
  onChangeHours: (name: string, value: string) => void;
  onChangeHoursUsed: () => void;
  onChangeHoursText: (name: string, value: string) => void;
  onChangeHoursType: (e: ChangeEvent<HTMLInputElement>) => void;
};

const PointBusinessHours = ({
  operation,
  onChangeHours,
  onChangeHoursUsed,
  onChangeHoursText,
  onChangeHoursType,
}: Props) => {
  const props = {
    operation,
    onChangeHours,
    onChangeHoursText,
  };

  return (
    <div className='flex flex-col w-full gap-5'>
      <div className='flex flex-col gap-5'>
        <FlexFormControl
          name='운영시간 사용 여부'
          labelSize='md2'
          control={<UsedBadge used={operation.hoursUsed} toggleBadge={onChangeHoursUsed} />}
        />

        {operation.hoursUsed && (
          <FormControl
            name='노출 타입'
            required
            control={
              <div className='flex flex-col w-[60%] border-t border-b'>
                {hourTypeComponents.map((hourType, index) => {
                  const Component = hourType.component;
                  const checked = operation.hoursType === hourType.type;
                  return (
                    <Fragment key={hourType.type}>
                      <section className={`flex items-center w-full px-4 ${checked && 'bg-state-blue_bg'}`}>
                        {/* 라디오 버튼 */}
                        <input
                          id={`hourType-${hourType.type}`}
                          type='radio'
                          value={hourType.type}
                          checked={checked}
                          onChange={onChangeHoursType}
                          className='w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500'
                        />

                        <label
                          htmlFor={`hourType-${hourType.type}`}
                          className='inline-flex items-center w-full cursor-pointer'
                        >
                          <section className='flex items-center w-full p-8'>
                            {/* 제목 */}
                            <span className={`${TextType.h4} text-gray-77 w-[80px] text-nowrap`}>{hourType.title}</span>
                            {/* 컴포넌트 */}
                            <Component {...props} />
                          </section>
                        </label>
                      </section>

                      {index !== hourTypeComponents.length - 1 && <div className='w-full border-b' />}
                    </Fragment>
                  );
                })}
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};
export default PointBusinessHours;

// 노출 타입 컴포넌트 데이터
const hourTypeComponents = [
  {
    type: 'DATE',
    title: '시간',
    component: HoursTime,
  },
  {
    type: 'TEXT',
    title: '텍스트',
    component: HoursTextInputs,
  },
];
