import { Fragment } from 'react';
import { Tenant } from 'types/feature/tenant/getTenant';
import { Workspace } from 'types/feature/workspace/workspace';
import TenantRow from './row/TenantRow';

type TenantsTableProps = {
  tenants: Tenant[];
  handleSelectedTenant: (tenant: Tenant) => void;
  selectedTenantId: string | null;
  pointName: string;
  pointId: string;
  workspace: Workspace | undefined;
};

const TenantsTable = ({
  workspace,
  tenants,
  handleSelectedTenant,
  selectedTenantId,
  pointName,
  pointId,
}: TenantsTableProps) => {
  return (
    <div className='flex-1 w-full'>
      {/* thead */}
      <div className='flex items-center w-full h-[40px] font-bold text-center border-t border-b-2 cursor-default text-gray-44 border-gray-44 whitespace-nowrap'>
        <div className='w-[80px] text-[11px] text-center'>No.</div>
        <div className='grow px-2.5 text-[11px] text-left'>노출 타이틀</div>
        <div className='w-[140px] px-5 text-[11px] text-center'>층</div>
        <div className='w-[140px] px-5 text-[11px] text-center'>타입</div>
        <div className='w-[140px] px-5 text-[11px] text-center'>상태</div>
        <div className='w-[120px] px-5 text-[11px] text-center'>QR 다운로드</div>
      </div>

      {/* tbody */}
      <div className='h-[600px] overflow-auto'>
        {tenants
          .sort((a: Tenant, b: Tenant) => a.name.ko.localeCompare(b.name.ko))
          .map((tenant: Tenant, index: number) => {
            return (
              <Fragment key={index}>
                <TenantRow
                  tenant={tenant}
                  selectedTenantId={selectedTenantId}
                  handleSelectedTenant={handleSelectedTenant}
                  numbering={index + 1}
                  pointName={pointName}
                  pointId={pointId}
                  workspace={workspace}
                />
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};
export default TenantsTable;
