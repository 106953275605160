import { AxiosError } from 'axios';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import SucceedPopup from 'components/common/popup/SucceedPopup';
import MapInfo from 'components/feature/setting/basic/map/MapInfo';
import useUserAccount from 'hooks/common/useUserAccount';
import usePoint from 'hooks/feature/point/usePoint';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { isEmpty } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useToastStore from 'stores/toast';
import { AccountMap } from 'types/common/account';
import { RoutePath } from 'types/common/paths';
import { SelectOption } from 'types/common/selectOption';
import { Workspace, WorkspaceForm } from 'types/feature/workspace/workspace';
import NaviSetting from './navi/NaviSetting';
import DeleteConfirmPopup from './popup/DeleteConfirmPopup';
import UrlSetting from './url/UrlSetting';
import WorkspaceSetting from './workspace/WorkspaceSetting';

const BasicSetting = () => {
  // state
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceForm, setWorkspaceForm] = useState<WorkspaceForm>({
    name: workspace?.name || '',
    description: workspace?.description || '',
    mobileUsed: workspace?.mobileUsed || false,
    webUsed: workspace?.webUsed || false,
    zoomButtonUsed: workspace?.zoomButtonUsed || false,
    parkingUsed: workspace?.parkingUsed || false,
    type: workspace?.type || '',
    useType: workspace?.useType || '',
    clientPath: workspace?.clientPath || '',
  });
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [userSyncMap, setUserSyncMap] = useState<string>('');
  const [mainBuildingId, setMainBuildingId] = useState<string>('');
  const [buildingOptions, setBuildingOptions] = useState<SelectOption[]>([]);
  // ref
  const shareToastTimerRef = useRef(null);
  // hook
  const navigate = useNavigate();
  const { deleteWorkspace } = useWorkspace();
  const { getWorkspace, putWorkspace } = useWorkspace();
  const { getAccount } = useUserAccount();
  const { workspaceId } = useParams();
  const { patchMainPoint } = usePoint();
  // store
  const toastStore = useToastStore();

  /**
   * 메인 빌딩을 변경한다.
   */
  const onChangeMainBuilding = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setMainBuildingId(value);
  };

  // workspace delete service logic
  const handleDelete = async (id: string) => {
    try {
      await deleteWorkspace(id);
      navigate(RoutePath.workspaces); // 화면 이동
      toastStore.setToastPopupMessage('삭제가 완료되었습니다');
      toastStore.setIsToastOpen(true);
      toastStore.setToastTimerRef(shareToastTimerRef); // toast 용 타이머 ref 설정
    } catch (error) {
      console.error(error);
    }
  };

  // workspace delete
  const deleteItem = (e: MouseEvent<HTMLElement>) => {
    if (!workspace) return;
    handleDelete(workspace.id);
    handleConfirmPopup(e);
  };

  // delete popup visible, isvisible
  const handleConfirmPopup = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpenDelete(!openDelete);
  };

  // 계정 정보 조회
  const fetchAccount = async () => {
    const account = await getAccount();
    if (account) {
      const findMap = account.maps.find((map: AccountMap) => map.id === workspace?.map.id || '');

      if (findMap) {
        setUserSyncMap(findMap.name);
      }
    }
  };

  // 워크스페이스 정보 조회
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);

      if (result) {
        setWorkspace(result.data);
        setWorkspaceForm(result.data);
      }
    }
  };

  // 저장
  const onSubmitWorkspace = () => {
    if (isEmpty(workspaceForm.name)) {
      setOpenFailPopup(true);
      setActiveConfirm(false);
      return;
    }

    setActiveConfirm(true);
  };

  // 워크스페이스 수정
  const updateWorkspace = async () => {
    try {
      if (workspaceForm) {
        const result = await putWorkspace(workspace?.id || '', workspaceForm);
        await patchMainPoint(mainBuildingId);

        if (result.result === 'SUCCESS') {
          setActiveConfirm(false);
          setOpenSuccessPopup(true);
        }

        await fetchWorkspace();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenFailPopup(true);
        }
      }
    }
  };

  useLayoutEffect(() => {
    fetchAccount();
  }, [workspace]);

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace();
    }
  }, [workspaceId]);

  // building id, options
  useEffect(() => {
    if (workspace && workspace.points) {
      const options = workspace.points.map(point => ({ id: point.id, name: point.name.ko, main: point.main }));
      const filteredBuilding = options.filter(option => option.main === true)?.[0];
      setMainBuildingId(filteredBuilding.id);
      setBuildingOptions(options);
    }
  }, [workspace]);

  if (!workspace) return <> </>;

  return (
    <div className='flex flex-col justify-start w-full h-full gap-12'>
      {/* 지도 정보 */}
      <MapInfo workspace={workspace} mapName={userSyncMap} fetchWorkspace={fetchWorkspace} />

      {/* 워크스페이스 */}
      <WorkspaceSetting
        onChangeMainBuilding={onChangeMainBuilding}
        workspace={workspace}
        buildingOptions={buildingOptions}
        buildingId={mainBuildingId}
        fetchWorkspace={fetchWorkspace}
      />

      {/* URL 설정 */}
      <UrlSetting workspace={workspace} fetchWorkspace={fetchWorkspace} />

      {/* 모의보행 설정 */}
      <NaviSetting />

      {activeConfirm && (
        <ConfirmPopup
          message='워크 스페이스 정보를 수정하시겠습니까?'
          onClickCancel={() => setActiveConfirm(false)}
          onClickOk={updateWorkspace}
        />
      )}
      {openFailPopup && (
        <FailPopup closeModal={() => setOpenFailPopup(false)} text='워크스페이스 이름을 입력해주세요.' />
      )}

      {openSuccessPopup && <SucceedPopup text='수정되었습니다.' closeModal={() => setOpenSuccessPopup(false)} />}
      {openDelete && (
        <DeleteConfirmPopup
          name={workspace.name}
          message='워크스페이스를 삭제하시겠습니까?'
          onClickOk={deleteItem}
          onClickCancel={handleConfirmPopup}
        />
      )}
    </div>
  );
};

export default BasicSetting;
