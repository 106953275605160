/* eslint-disable no-useless-return */
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import useMachine from 'hooks/feature/machine/useMachine';
import { isEmpty, isNaN } from 'lodash';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAccountStore from 'stores/account';
import useWorkspaceStore from 'stores/workspace';
import { RoutePath } from 'types/common/paths';
import { LocationForm, MachinePlaceType, RegisterAccount } from 'types/feature/menu/machine/machine';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  machineType: string;
  machineBasicForm: RegisterAccount & { placeType: MachinePlaceType };
  machinePlaceForm: LocationForm;
};

const MachineAddButtons = ({ machineType, machinePlaceForm, machineBasicForm }: Props) => {
  // state
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  // hook
  const { workspaceId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { postWebMachine, postDesktopMachine } = useMachine();
  const { workspace } = useWorkspaceStore();
  const { checkDuplicate, duplicateUsername } = useAccountStore();

  const goToMachineList = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.machine, workspaceId), {
        state: {
          point: state?.point,
          placeType: machineBasicForm.placeType || '',
          status: state?.status,
        },
      });
    }
  };

  const handleOpenConfirmPopup = () => {
    setOpenConfirmPopup(true);
  };

  // 포인트별로 기기를 저장합니다.
  const postKioskMachine = async () => {
    try {
      // 기기 타입에 따라 분기 처리 (web에서는 계정 정보가 들어감)
      if (machineType === 'WEB') {
        const webRequest = {
          ...machinePlaceForm,
          pointId: state.point?.id,
          account: {
            username: machineBasicForm.username,
            password: machineBasicForm.password,
          },
          placeType: machineBasicForm.placeType,
        };
        await postWebMachine(webRequest);
      }

      if (machineType === 'DESKTOP') {
        const desktopRequest = {
          ...machinePlaceForm,
          pointId: state.point?.id,
          placeType: machineBasicForm.placeType,
        };
        await postDesktopMachine(desktopRequest);
      }
      goToMachineList();
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlertPopup(true);
      });
      setAlertMessage('기기 정보를 등록하는 중에\n 문제가 발생하였습니다.');
    }
  };

  const handleRegisterMachine = async () => {
    setOpenConfirmPopup(false);
    if (machineType === 'WEB') {
      if (isEmpty(machineBasicForm.username) || isEmpty(machineBasicForm.password)) {
        setAlertMessage('접속 정보를 입력해주세요.');
        setOpenAlertPopup(true);
        return;
      }

      if (!checkDuplicate) {
        setAlertMessage('아이디 중복 체크가 필요합니다.');
        setOpenAlertPopup(true);
        return;
      }

      if (duplicateUsername) {
        setAlertMessage('중복된 아이디로는 계정 생성이 불가능 합니다.');
        setOpenAlertPopup(true);
        return;
      }
    }

    if (workspace) {
      if (state?.point) {
        if (
          machinePlaceForm.mapPosition.zoom > state.point.mapMaxZoom ||
          machinePlaceForm.mapPosition.zoom < state.point.mapMinZoom
        ) {
          setAlertMessage('줌 레벨이 워크 스페이스의 min, max 값 내의 값으로 변경 되어야 합니다.');
          setOpenAlertPopup(true);
          return;
        }
      }
    }

    if (
      isEmpty(machinePlaceForm.positionX) ||
      isEmpty(machinePlaceForm.positionY) ||
      isEmpty(machinePlaceForm.description) ||
      isEmpty(machinePlaceForm.mapPosition.centerPositionX) ||
      isEmpty(machinePlaceForm.mapPosition.centerPositionY) ||
      isEmpty(machinePlaceForm.mapPosition.rotation) ||
      isEmpty(machinePlaceForm.mapPosition.zoom)
    ) {
      setAlertMessage('기기 위치 정보를 입력해주세요.');
      setOpenAlertPopup(true);
      return;
    }

    if (
      isNaN(Number(machinePlaceForm.positionX)) ||
      isNaN(Number(machinePlaceForm.positionY)) ||
      isNaN(Number(machinePlaceForm.mapPosition.centerPositionX)) ||
      isNaN(Number(machinePlaceForm.mapPosition.centerPositionY)) ||
      isNaN(Number(machinePlaceForm.mapPosition.rotation)) ||
      isNaN(Number(machinePlaceForm.mapPosition.zoom))
    ) {
      setOpenAlertPopup(true);
      setAlertMessage('숫자만 입력해주세요');
      return;
    }
    await postKioskMachine();
  };

  return (
    <>
      <div className='flex justify-center gap-2.5 pt-10'>
        <Button text='취소' color={ButtonColor.secondary} size={120} onClick={goToMachineList} />
        <Button text='저장' color={ButtonColor.primary} size={120} onClick={handleOpenConfirmPopup} />
      </div>
      {openConfirmPopup && (
        <ConfirmPopup
          message='등록한 정보로 기기를 등록 하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleRegisterMachine}
        />
      )}
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default MachineAddButtons;
