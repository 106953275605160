import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import useAuth from 'hooks/common/useAuth';
import useCustomNavigate from 'hooks/common/useCustomNavigate';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAccountStore from 'stores/account';
import useWorkspaceStore from 'stores/workspace';
import { RoutePath } from 'types/common/paths';

const SettingNav = () => {
  // store
  const workspaceStore = useWorkspaceStore();

  const navigate = useNavigate();
  const { navigateToReplacedPath } = useCustomNavigate();
  const { workspaceId } = useParams();
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const moveToWorkspacePage = () => {
    navigate(RoutePath.workspaces);
  };

  const moveToSetting = () => {
    navigateToReplacedPath(RoutePath.content, workspaceId);
  };

  const handleConfirmPopup = () => {
    setOpenConfirmPopup(!openConfirmPopup);
  };
  const accountStore = useAccountStore();
  const { logout } = useAuth();

  // 로그아웃
  const handleLogout = async () => {
    try {
      await logout();

      setOpenConfirmPopup(false);
      accountStore.setAccount(null);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('로그아웃을 할 수 없습니다.');
        }
      }
    }
  };

  return (
    <>
      <div className='flex items-center justify-between w-[1400px] h-20 '>
        <div className='fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full bg-white border-b'>
          <div className='flex items-center w-full h-20 max-w-[1400px] gap-10'>
            {/* 이름 */}
            <div
              onClick={moveToSetting}
              className='flex items-center justify-start w-full gap-2.5 cursor-pointer text-gray-22 h-[28px]'
            >
              <span className='text-xl font-bold'>{workspaceStore.workspaceName}</span>
              <span className='text-xl font-normal'>워크스페이스 설정</span>
            </div>
            <div className={`flex items-center gap-5 shrink-0 pr-5`}>
              {/* 워크스페이스 목록으로 이동하는 버튼 */}
              <div className='cursor-pointer w-[24px] h-[24px]' onClick={moveToWorkspacePage}>
                <img src={ICON.WORKSPACE} alt='워크스페이스' />
              </div>
              {/* 설정 */}
              <div
                className='w-[24px] h-[24px] cursor-pointer'
                onClick={moveToSetting}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {/* <div>홈</div> */}
                <img src={ICON.HOME} alt='홈' style={{ aspectRatio: '1', height: '20px' }} />
              </div>
              {/* 로그아웃 */}
              <div className='w-[24px] h-[24px] cursor-pointer' onClick={handleConfirmPopup}>
                <img src={ICON.LOGOUT} alt='로그아웃' />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openConfirmPopup && (
        <ConfirmPopup message='로그아웃 하시겠습니까?' onClickOk={handleLogout} onClickCancel={handleConfirmPopup} />
      )}
      {openAlertPopup && (
        <AlertPopup
          message={alertMessage}
          onClick={() => {
            setOpenAlertPopup(false);
          }}
        />
      )}
    </>
  );
};

export default SettingNav;
