import React from 'react';
import { Point } from 'types/feature/workspace/workspace';

type Props = {
  point: Point;
};

const PointTab = ({ point }: Props) => {
  return (
    <div className='flex w-full border-b'>
      <div
        key={point.id}
        className={`min-w-[120px] h-[60px] px-6 flex items-center justify-center cursor-pointer 
        'text-gray-44 border-b-2 border-gray-22' `}
      >
        {point.name.ko}
      </div>
    </div>
  );
};
export default PointTab;
