import Input from 'components/common/input/Input';
import useLanguage from 'hooks/feature/language/useLanguage';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageName } from 'types/feature/language/language';
import { PointOperation } from 'types/feature/workspace/workspace';
import { WORD_LIMIT } from './constant';

type Props = {
  onChangeHoursText: (name: string, value: string) => void;
  operation: PointOperation;
};

const HoursTextInputs = ({ onChangeHoursText, operation }: Props) => {
  const { getLanguages } = useLanguage();
  const { workspaceId: paramWorkspaceId } = useParams();

  const [langs, setLangs] = useState<{ code: string; title: string }[]>([]);

  const fetchLangs = useCallback(
    async (workspaceId: string) => {
      const langList = await getLanguages(workspaceId);
      if (langList) {
        const languages = langList.map(lang => {
          return {
            code: lang.code,
            title: LanguageName[lang.code],
          };
        });

        setLangs(languages);
      }
    },
    [paramWorkspaceId],
  );

  useEffect(() => {
    if (paramWorkspaceId) {
      fetchLangs(paramWorkspaceId);
    }
  }, [paramWorkspaceId]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    onChangeHoursText(name.toLowerCase(), value);
  };

  return (
    <div className='flex flex-col justify-center w-[400px] gap-1'>
      {langs.map(language => {
        return (
          <div key={language.code} className='flex items-center w-full gap-2'>
            {/* 언어 코드 */}
            <div
              className={`flex items-center justify-center bg-[#999] text-white w-[24px] h-[16px] text-[11px] leading-4`}
            >
              {language.code}
            </div>

            {/* 입력창 */}
            <Input
              width='w-full'
              onChange={onChange}
              placeholder={`${language.title} 운영시간을 입력해주세요`}
              value={operation.hoursText[language.code.toLowerCase()]}
              name={language.code}
            />
          </div>
        );
      })}
      <div className='text-h5 text-gray-99'>{`최대 ${WORD_LIMIT}자까지 입력 가능합니다.`}</div>
    </div>
  );
};
export default HoursTextInputs;
