import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { ScreenType } from 'types/feature/content/contents';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import { PatchScreensaver } from 'types/feature/screensaver/patchScreensaver';
import { PostScreensaver } from 'types/feature/screensaver/postScreensaver';

const useScreensaver = () => {
  const { api } = useAxios();

  // ! GET
  const getScreensavers = async (pointId: string, screenType: ScreenType) => {
    const response = await api.get<ApiResponse<GetScreensaver[]>>(Endpoint.screensaversV2, {
      params: {
        pointId,
        screenType,
      },
    });

    if (response) {
      return response.data;
    }
  };

  // ! POST
  const postScreensaver = async (screensaver: PostScreensaver) => {
    await api.post(Endpoint.screensaversV2, screensaver);
  };

  // ! DELETE
  const deleteScreensaver = async (id: string) => {
    await api.delete(`${Endpoint.screensavers}/${id}`);
  };

  // ! UPDATE(PATCH)
  const updateScreensaver = async (id: string, data: PatchScreensaver) => {
    await api.patch(`${Endpoint.screensavers}/${id}`, data);
  };

  return {
    getScreensavers,
    postScreensaver,
    deleteScreensaver,
    updateScreensaver,
  };
};
export default useScreensaver;
