import FormControl from 'components/common/form-control/FormControl';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { SCREEN_TYPE_FILTER } from 'data/filter';
import { ChangeEvent } from 'react';
import { ScreenType } from 'types/feature/content/contents';

type FilterContainerProps = {
  screenType: ScreenType;
  handleScreenType: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const FilterContainer = ({ screenType, handleScreenType }: FilterContainerProps) => {
  return (
    <FormControl
      name='유형'
      control={
        <CommonSelectBox selected={screenType} width='w-52' options={SCREEN_TYPE_FILTER} onChange={handleScreenType} />
      }
      required
    />
  );
};
export default FilterContainer;
