import CommonTableHeader from 'components/common/table/header/CommonTableHeader';
import { REGISTER_TABLE_HEADER } from 'data/table/monitoring';
import { Floor } from 'types/feature/floor/floor';
import { RegisteredMachine } from 'types/feature/menu/machine/machine';
import RegisterRow from './row/RegisterRow';

type Props = {
  registeredMachineList: RegisteredMachine[];
  floorList: Floor[];
  goToMachineDetailPage: (machineId: string) => void;
};

const RegisteredMachineTable = ({ registeredMachineList, floorList, goToMachineDetailPage }: Props) => {
  return (
    <table>
      <CommonTableHeader tableHead={REGISTER_TABLE_HEADER} />
      {registeredMachineList.length < 1 ? (
        <tbody>
          <tr className='h-[48px]'>
            <td colSpan={7} className='text-gray-44'>
              등록된 기기가 없습니다.
            </td>
          </tr>
        </tbody>
      ) : (
        registeredMachineList.map(machine => {
          const machineFloor = floorList.find(floor => floor.id === machine.floorId);

          return (
            <RegisterRow
              goToMachineDetailPage={goToMachineDetailPage}
              key={machine.id}
              floor={machineFloor}
              registeredMachine={machine}
            />
          );
        })
      )}
    </table>
  );
};

export default RegisteredMachineTable;
