import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import useFloor from 'hooks/feature/floor/useFloor';
import useMachine from 'hooks/feature/machine/useMachine';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { RegisterMachine } from 'types/feature/menu/machine/machine';
import { MachineType } from 'types/feature/workspace/workspace';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import MachineDetailBasic from './basic/MachineDetailBasic';
import MachineDetailContainer from './location/MachineDetailContainer';

const MachineDetail = () => {
  // hook
  const { workspaceId, machineId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchFloors, floorList } = useFloor();
  const { getMachine } = useMachine();
  const { getWorkspace } = useWorkspace();
  // state
  const [registeredMachine, setRegisteredMachine] = useState<RegisterMachine>();
  const [screenType, setScreenType] = useState<string>('');
  const [machineType, setMachineType] = useState<MachineType>('WEB');

  /**
   * 기기 목록으로 돌아간다
   */
  const moveToMachineList = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.machine, workspaceId), {
        state: {
          point: location.state?.point,
          placeType: location.state?.placeType,
          status: location.state?.status,
        },
      });
    }
  };

  const fetchWorkspaceAndMachine = async () => {
    try {
      if (workspaceId && machineId) {
        const workspace = await getWorkspace(workspaceId);
        setMachineType(workspace.data.machineType as MachineType);

        const response = await getMachine(workspace.data.machineType as MachineType, machineId);
        if (response) {
          setRegisteredMachine(response.data);
          setScreenType(response.data.screenType);
        }
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response && e.response.status === 400) {
          console.error('기기 정보를 수정할 수 없습니다', e);
        }
      }
    }
  };

  useEffect(() => {
    if (location.state && location.state.point) {
      fetchFloors(location.state.point.id || '');
    }
  }, []);

  useEffect(() => {
    fetchWorkspaceAndMachine();
  }, []);

  if (!registeredMachine) {
    return <> </>;
  }

  return (
    <div className='w-full pb-20'>
      {/* 기본 정보 */}
      <MachineDetailBasic
        machineType={machineType}
        registeredMachine={registeredMachine}
        fetchWorkspaceAndMachine={fetchWorkspaceAndMachine}
      />

      {/* 기기위치 정보 */}
      <MachineDetailContainer
        machineFloor={floorList.find(floor => floor.id === registeredMachine?.floorId)}
        registeredMachine={registeredMachine}
        floorList={floorList}
        screenType={screenType}
        setScreenType={setScreenType}
        fetchWorkspaceAndMachine={fetchWorkspaceAndMachine}
      />

      <div className='flex justify-center gap-2.5 pt-10'>
        <Button text='목록' color={ButtonColor.secondary} onClick={moveToMachineList} />
      </div>
    </div>
  );
};

export default MachineDetail;
