import { useEffect } from 'react';
import { ModalType } from 'types/common/modal';

type Props = {
  closeModal: () => void;
  modalType: ModalType;
  callback?: CallableFunction;
};

const useKeyboard = ({ modalType, closeModal, callback }: Props) => {
  const handleKeyboard = (e: any) => {
    (document.activeElement as HTMLElement).blur(); // focus 되어 있는 element blur 처리 (ex button)
    e.preventDefault();

    // 알림창일 경우 (버튼이 한개일 경우)
    if (modalType === ModalType.alert) {
      if (e.code === 'Enter' || e.code === 'Escape') {
        closeModal();
      }

      return;
    }

    // 컨펌창일 경우 (버튼이 두개일 경우)
    if (modalType === ModalType.confirm) {
      if (e.code === 'Enter') {
        if (callback) {
          callback();
        }
      }

      if (e.code === 'Escape') {
        closeModal();
      }
    }
  };

  useEffect(() => {
    (document.activeElement as HTMLElement).blur();

    document.addEventListener('keydown', handleKeyboard);

    return () => {
      document.removeEventListener('keydown', handleKeyboard);
    };
  }, []);

  return { handleKeyboard };
};
export default useKeyboard;
