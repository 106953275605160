/* eslint-disable react/jsx-no-useless-fragment */
import CopyButton from 'components/common/copy/CopyButton';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import { ICON } from 'constants/icons';
import useQrDownload from 'hooks/feature/tenant/share/useQrDownload';
import { MouseEvent } from 'react';
import { Workspace } from 'types/feature/workspace/workspace';

type Props = {
  workspace: Workspace;
};

const WebUrl = ({ workspace }: Props) => {
  const { downQrImage } = useQrDownload();

  const webUrl = workspace.webEndpoint;

  const onClickDownload = (e: MouseEvent) => {
    e.stopPropagation();

    if (!workspace) {
      return;
    }

    // 일반 워크스페이스는 바로 QR 다운로드 받는다
    downQrImage(`${workspace.name}-매장 위치 확인`, webUrl);
  };

  return (
    <>
      <FlexFormControl
        name='웹 URL'
        control={
          <section className='flex items-center w-full h-9'>
            {/* url */}
            <div className='text-[14px] w-[380px] break-words line-clamp-1'>{webUrl}</div>

            {/* buttons */}
            <div className='flex items-center gap-[10px] shrink-0'>
              <div className='cursor-pointer' onClick={onClickDownload}>
                <img src={ICON.DOWNLOAD_BUTTON} alt='다운로드' className='h-full' />
              </div>

              {/* 복사 버튼 */}
              <CopyButton text={webUrl} />
            </div>
          </section>
        }
      />
    </>
  );
};

export default WebUrl;
