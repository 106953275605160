import { RoutePath } from 'types/common/paths';

export const SETTING_MENUS = [
  { path: RoutePath.basicSetting, name: '기본', id: 'basic', order: 1 },
  {
    path: RoutePath.pointSetting,
    name: '건물 관리',
    id: 'point',
    order: 2,
  },
  { path: RoutePath.languageSetting, name: '언어', id: 'language', order: 3 },
  // { path: RoutePath.customFieldSetting, name: '사용자 정의' },
  { path: RoutePath.themeSetting, name: '테마', id: 'theme', order: 4 },
];
