import { useRef } from 'react';
import useToastStore from 'stores/toast';
import { errorConsole } from 'utils/common/console';

const useClipBoard = () => {
  // ref
  const shareToastTimerRef = useRef(null);

  // store
  const toastStore = useToastStore();

  // 클립보드 복사
  const copyUrlOnClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      // toast open
      toastStore.setToastPopupMessage('클립보드에 복사되었습니다.');
      toastStore.setIsToastOpen(true);
      // toast 용 타이머 ref 설정
      toastStore.setToastTimerRef(shareToastTimerRef);
    } catch (error) {
      toastStore.setIsToastOpen(true);
      toastStore.setToastPopupMessage('브라우저가 이 기능을 지원하지 않습니다.');

      errorConsole('클립보드 복사 실패:', error);
    }
  };
  return { copyUrlOnClipboard };
};
export default useClipBoard;
