import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import SucceedPopup from 'components/common/popup/SucceedPopup';
import Title from 'components/common/title/Title';
import usePoint from 'hooks/feature/point/usePoint';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { Logo, Point } from 'types/feature/workspace/workspace';
import usePointStore from 'stores/point';
import PointLogo from './PointLogo';
import PointName from './PointName';
import PointTab from './PointTab';

type Props = {
  pointId: string;
};
const PointBasicSetting = ({ pointId }: Props) => {
  // state
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [point, setPoint] = useState<Point | null>(null);

  // hook
  const { putPointV2, getPoint } = usePoint();

  // store
  const pointStore = usePointStore();

  /**
   * 빌딩 이름을 수정한다.
   */
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!point) return;

    const { value, id } = e.target;

    setPoint(prev => {
      if (!prev) return null;
      return {
        ...prev,
        name: {
          ...prev.name,
          [id]: value,
        },
      };
    });
  };

  /**
   * 빌딩 정보를 업데이트한다.
   */
  const updatePoint = async () => {
    try {
      if (!point) return;
      const useLogo = isEmpty(point.logo?.originalName);

      await putPointV2(point.id, {
        // name: point.name,
        logo: {
          delete: useLogo,
          name: point.logo?.name || '',
          originalName: point.logo?.originalName || '',
        },
      });
      await fetchPoint(pointStore.currentPointId);

      setOpenConfirm(false);
      setOpenSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenFail(true);
        }
      }
    }
  };

  /**
   * point의 로고를 변경한다.
   * @param {Logo} logo
   */
  const onChangePointLogo = (logo: Logo) => {
    setPoint(prev => {
      if (!prev) return null;
      return {
        ...prev,
        logo,
      };
    });
  };

  /**
   * 포인트 조회
   * @param {string} pointId
   */
  const fetchPoint = async (id: string) => {
    const response = await getPoint(id);
    if (response) {
      setPoint(response);
    }
  };

  useEffect(() => {
    fetchPoint(pointId);
  }, [pointId]);

  if (!point) {
    return <> </>;
  }

  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <Title text='기본 정보' titleType='h2' />
      {/* 포인트 탭 */}
      <PointTab point={point} />

      <FlexFormControl
        name='이름'
        labelSize='md2'
        control={<PointName pointName={point.name} onChange={onChangeInput} />}
      />

      <FlexFormControl
        name='로고 이미지'
        labelSize='md2'
        control={<PointLogo onChangePointLogo={onChangePointLogo} point={point} />}
      />

      <div className='flex justify-center w-full mt-5'>
        <Button text='저장' type='button' size={120} color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
      </div>

      {/* 확인 팝업 */}
      {openConfirm && (
        <ConfirmPopup
          message='포인트 정보를 수정하시겠습니까?'
          onClickCancel={() => setOpenConfirm(false)}
          onClickOk={updatePoint}
        />
      )}
      {/* 성공 팝업 */}
      {openSuccess && <SucceedPopup text='저장 완료되었습니다.' closeModal={() => setOpenSuccess(false)} />}

      {/* 실패 팝업 */}
      {openFail && <FailPopup closeModal={() => setOpenFail(false)} text={'포인트 정보를 수정할 수 없습니다.'} />}
    </section>
  );
};
export default PointBasicSetting;
