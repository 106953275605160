import Badge from 'components/common/badge/Badge';
import { Dispatch, SetStateAction, useState } from 'react';
import { WorkspaceForm } from 'types/feature/workspace/workspace';

type Props = {
  webUsed: boolean;
  setWorkspaceForm: Dispatch<SetStateAction<WorkspaceForm>>;
};

const ToggleWebUsed = ({ webUsed, setWorkspaceForm }: Props) => {
  const [toggleWebUsed, setToggleWebUsed] = useState(webUsed);

  const handleToggleMobileUsed = () => {
    setToggleWebUsed(!toggleWebUsed);
    setWorkspaceForm((prev: WorkspaceForm) => ({
      ...prev,
      webUsed: !toggleWebUsed,
    }));
  };

  return (
    <div
      className={`
      w-20 
      flex justify-start items-center
      `}
      onClick={handleToggleMobileUsed}
    >
      <Badge height='h-9' name={toggleWebUsed ? '사용' : '미사용'} badgeType={toggleWebUsed ? 'USED' : 'NONE'} />
    </div>
  );
};

export default ToggleWebUsed;
