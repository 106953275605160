import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import { THEME_GUIDES } from 'data/guide/setting';
import usePoint from 'hooks/feature/point/usePoint';
import useTheme from 'hooks/feature/theme/useTheme';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Point } from 'types/feature/workspace/workspace';
import ColorBox from './color/ColorBox';
import ThemePreviews from './preview/ThemePreviews';

const ThemeSettingContainer = () => {
  // hooks
  const {
    usedColorSet,
    themes,
    activateColorSet,
    openAlert,
    closeAlert,
    handleOpenConfirm,
    openConfirm,
    closeConfirm,
    alertMessage,
    handleClickTheme,
    disableSave,
    fetchAndSetThemes,
  } = useTheme();
  const { workspaceId } = useParams();
  const { getPoints } = usePoint();
  // state
  const [mainPoint, setMainPoint] = useState<Point>();

  const fetchPointsAndMainPoint = async () => {
    if (!workspaceId) return;
    const pointList = await getPoints(workspaceId);
    if (pointList) {
      setMainPoint(pointList.find(point => point.main));
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchPointsAndMainPoint();
    }
  }, [workspaceId]);

  useEffect(() => {
    if (mainPoint) {
      fetchAndSetThemes(mainPoint.id);
    }
  }, [mainPoint]);

  return (
    <div className='flex flex-col gap-5 w-[1000px]'>
      <Title text='테마' titleType='h2' />

      <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
        <GuideDescription guides={THEME_GUIDES} width='w-[766px]' />
        <div className='flex w-full gap-5'>
          {themes?.colorSets.map(colorSet => (
            <Fragment key={colorSet.id}>
              <ColorBox colorSet={colorSet} handleClickTheme={handleClickTheme} usedColorSet={usedColorSet} />
            </Fragment>
          ))}
        </div>

        <div className='flex flex-col items-center gap-5'>
          <ThemePreviews usedColorSet={usedColorSet} />

          <Button
            disabled={disableSave || !themes}
            onClick={handleOpenConfirm}
            color={ButtonColor.primary}
            size={120}
            text='저장'
          />
        </div>
      </section>

      {openAlert && <AlertPopup message={alertMessage} onClick={closeAlert} />}

      {openConfirm && (
        <ConfirmPopup
          message='컬러셋을 변경하시겠습니까?'
          onClickOk={() => {
            if (!mainPoint) return;
            activateColorSet(mainPoint.id);
          }}
          onClickCancel={closeConfirm}
        />
      )}
    </div>
  );
};
export default ThemeSettingContainer;
