import MapDraw from './mapDraw';

// 층 변경
export const changeFloor = async (floorId: string): Promise<void> => {
  if (MapDraw.map) {
    await MapDraw.map.context.changeFloor(floorId);
  }
};

/**
 * 마커 그리기
 */

type Position = {
  x: number;
  y: number;
};
export const drawKioskMarker = async (position: Position): Promise<void> => {
  if (MapDraw.map) {
    clearMarkers();

    await MapDraw.map.markers.set({
      marker: [
        {
          x: position.x,
          y: position.y,
          iconOption: {
            anchor: {
              x: 0.5,
              y: 0.5,
            },
            width: 40,
            height: 40,
            iconUrl: '/assets/image/kiosk.png',
          },
        },
      ],
    });
  }
};

// 마커 제거
export const clearMarkers = () => {
  if (MapDraw.map) {
    MapDraw.map.markers.clearAll();
  }
};

/**
 * 카메라 포커스 이동
 */
export const changeCameraFocus = (position: Position) => {
  if (MapDraw.map) {
    MapDraw.map.control.moveTo({ transition: true, position: { x: isNaN(position.x), y: isNaN(position.y) } });
  }
};

const isNaN = (value: number) => {
  return Number.isNaN(value) ? 0 : value;
};

/**
 * 방향각 회전
 */
export const changeRotation = (rotation: number) => {
  if (MapDraw.map) {
    MapDraw.map.control.set({
      rotation,
      transition: true,
    });
  }
};

/**
 * 줌 변경
 */
export const changeZoom = (zoomLevel: number) => {
  if (MapDraw.map) {
    MapDraw.map.control.changeZoom({
      zoom: Number(zoomLevel),
      transition: true,
    });
  }
};

// clean up
