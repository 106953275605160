export const typeOptions = [
  { id: 'RETAIL', name: 'Retail' },
  { id: 'EXHIBITION', name: 'Exhibition' },
  { id: 'OFFICE', name: 'Office' },
  { id: 'ETC', name: 'Etc' },
];

export const useTypeOptions = [
  { id: 'DRAFT', name: 'Draft' },
  { id: 'SERVICE', name: 'Service' },
  { id: 'ARCHIVE', name: 'Archive' },
  { id: 'TEST', name: 'Test' },
];
