import { AxiosError } from 'axios';
import Badge from 'components/common/badge/Badge';
import AlertPopup from 'components/common/popup/AlertPopup';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useState } from 'react';
import { Workspace } from 'types/feature/workspace/workspace';

type Props = {
  workspace: Workspace;
  fetchWorkspace: () => Promise<void>;
};
const ToggleWebUsed = ({ workspace, fetchWorkspace }: Props) => {
  const [used, setUsed] = useState(workspace.webUsed);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { patchWorkspaceWebUsed } = useWorkspace();

  const onClick = async () => {
    try {
      setUsed(!used);
      await patchWorkspaceWebUsed(workspace.id, !used);
      await fetchWorkspace();
      setIsAlert(true);
      setAlertMessage('웹 키오스크 사용 유무를 변경했습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setIsAlert(true);
          setAlertMessage('웹 키오스크 사용 유무 변경을 실패했습니다.');
        }
      }
    }
  };

  const resetPopups = () => {
    setIsAlert(false);
    setAlertMessage('');
  };

  return (
    <div
      className={`
      w-20 
      flex justify-start items-center
      `}
      onClick={onClick}
    >
      <Badge height='h-9' name={used ? '사용' : '미사용'} badgeType={used ? 'USED' : 'NONE'} />

      {isAlert && <AlertPopup onClick={resetPopups} message={alertMessage} />}
    </div>
  );
};

export default ToggleWebUsed;
