import { IndexType } from 'types/common';

export type Language = {
  id: string;
  code: string;
  main: boolean;
  display: boolean;
  sortOrder: number;
  displayName: string;
};

export type UpdateLanguage = {
  used?: boolean;
  display?: boolean;
  sortOrder?: number;
};

export const LanguageName: IndexType = {
  KO: '한국어',
  EN: '영어',
  JA: '일본어',
  CN: '중국어 간체',
  TW: '중국어 번체',
};

export const LanguageExposureName: IndexType = {
  KO: '한국어',
  EN: 'English',
  JA: '日本語',
  CN: '簡體中文',
  TW: '繁體中文',
};

export enum UpdateLanguageType {
  display = 'display',
  used = 'used',
}

export type PatchLanguage = {
  [index: string]: string;
  ko: string;
  en: string;
  ja: string;
  cn: string;
  tw: string;
};

export type MultiLangs = {
  [code: string]: string;
  ko: string;
  en: string;
  ja: string;
  cn: string;
  tw: string;
};

export type MultiLangsWordLimit = {
  [code: string]: number;
  ko: number;
  en: number;
  ja: number;
  cn: number;
  tw: number;
};

export type DisplayNameUpdateData = { displayName: string };
