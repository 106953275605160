import { isNaN } from 'lodash';

// 입력값이 숫자인지 검증
export const validNumber = (value: any) => {
  // 빈 문자일때
  if (value === '') return true;

  // 숫자 일 때
  if (!isNaN(Number(value)) && value.trim() !== '') {
    return true;
  }
  return false;
};
