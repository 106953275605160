import AlertPopup from 'components/common/popup/AlertPopup';
import { ChangeEvent, useState } from 'react';
import useWaitingListStore from 'stores/waitingList';
import { DisplayType } from 'types/common/display';
import { convertDateToString } from 'utils/convert-date/convertDate';
import DisplaySelectBox from './select/DisplaySelectBox';
import Calendar from './calendar/Calendar';

type DisplayRangeContainerProps = {
  disabled: boolean;
  rowId: string;
};

const DisplayRangeContainer = ({ disabled, rowId }: DisplayRangeContainerProps) => {
  const [type, setType] = useState(DisplayType.always);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { waitingList, setWaitingList } = useWaitingListStore();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const displayType = e.target.value as DisplayType;

    setType(displayType);

    const selected = waitingList.filter(item => item.id === rowId)[0];
    const selectedIdx = waitingList.findIndex(item => item.id === rowId);

    const newSelected = {
      ...selected,
      displayType,
    };

    if (!newSelected) return;
    waitingList.splice(selectedIdx, 1, newSelected);
    setWaitingList(waitingList);
  };

  const handleDate = (date: Date | null) => {
    try {
      if (!date) {
        setOpenAlert(true);
        setAlertMessage('날짜를 입력해주세요.');
        return;
      }

      setStartDate(date);
      updateSelectedRow(date, date);
    } catch (e) {
      if (e instanceof Error) {
        setOpenAlert(true);
        setAlertMessage('날짜 설정을 다시 해주세요.');
      }
    }
  };

  const handleRange = (dates: [Date | null, Date | null]) => {
    try {
      const [start, end] = dates;

      if (!start) {
        setOpenAlert(true);
        setAlertMessage('날짜를 입력해주세요.');
        return;
      }

      setStartDate(start);
      setEndDate(end);
      updateSelectedRow(start, end);
    } catch (e) {
      if (e instanceof Error) {
        setOpenAlert(true);
        setAlertMessage('기간 설정을 다시 해주세요.');
      }
    }
  };

  const updateSelectedRow = (start: Date | null, end: Date | null) => {
    if (!waitingList) return;

    const selected = waitingList.filter(item => item.id === rowId)[0];
    const selectedIdx = waitingList.findIndex(item => item.id === rowId);

    const newSelected = {
      ...selected,
      startDate: convertDateToString(start),
      endDate: convertDateToString(end),
    };

    if (!newSelected) return;

    waitingList.splice(selectedIdx, 1, newSelected);
    setWaitingList(waitingList);
  };

  return (
    <>
      <div className='flex items-center justify-start w-full gap-4'>
        <DisplaySelectBox selected={type} handleChange={handleChange} disabled={disabled} />
        <Calendar
          style={`bg-transparent h-8`}
          displayType={type}
          disabled={disabled}
          handleDate={handleDate}
          handleRange={handleRange}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default DisplayRangeContainer;
