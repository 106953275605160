import { InputStatus } from 'types/feature/workspace/workspace';
import { InputProps } from './Input.type';

const Input = ({
  width = 'w-52',
  height = 'h-9',
  placeholder,
  type,
  readOnly,
  value,
  defaultValue,
  min,
  max,
  maxLength,
  onChange,
  id,
  autoComplete,
  inputRef,
  name,
  disabled,
  icon,
  showWarning,
  required,
  status,
}: InputProps) => {
  const wordLength = defaultValue?.length || value?.length;

  /**
   * 인풋에 적용할 스타일을 반환한다.
   * @param {InputStatus} inputStatus
   * @returns {string}
   */
  const getBorderStyleByStatus = (inputStatus: InputStatus): string => {
    switch (inputStatus) {
      case 'success':
        return 'border border-primary-DA_Green';
      case 'error':
        return 'border border-[#D94545]';
      default:
        return 'border border-gray-99';
    }
  };
  return (
    <label
      className={`${width} ${height} flex items-center justify-start gap-2 bg-white 
      ${readOnly ? 'border-none' : 'border'}
      ${showWarning ? '!border-[#D94545] !focus:border-[#D94545]' : 'border-gray-99 focus:border-gray-99'}
      ${status && getBorderStyleByStatus(status)}
      ${disabled && 'opacity-50'}
      `}
    >
      {icon && <div className='pl-2'>{icon}</div>}
      <input
        className={` 
        placeholder:text-gray-cc placeholder:text-sm
        text-sm
        outline-none focus:outline-none border-none
        px-2
        w-full 
        `}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        id={id}
        min={min}
        max={max}
        maxLength={maxLength}
        onChange={onChange}
        defaultValue={defaultValue}
        readOnly={readOnly}
        type={type ?? 'text'}
        value={value}
        ref={inputRef}
        name={name}
        required={required}
      />

      {maxLength && (
        <div className='ml-3 mr-3'>
          <div className='text-gray-ea text-[10px] leading-4 font-normal'>
            <span className={`${Number(wordLength) > 0 ? 'text-gray-ea font-h3_bold' : 'text-gray-ea'}`}>
              {wordLength || 0}
            </span>
            <span className='text-inherit'>/</span>
            <span className={`${wordLength === maxLength ? 'text-gray-ea' : 'text-gray-ea'} `}>{maxLength || 0}</span>
          </div>
        </div>
      )}
    </label>
  );
};
export default Input;
