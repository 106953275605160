import { IndexType } from 'types/common';

export enum MachinePlaceTypes {
  CLIENT = 'CLIENT',
  CLIENT_OFFICE = 'CLIENT_OFFICE',
  DEVELOPER = 'DEVELOPER',
  ETC = 'ETC',
}

export const MACHINE_PLACE_TYPE_VALUES: IndexType = {
  CLIENT: '고객사',
  CLIENT_OFFICE: '고객사 사무실',
  DEVELOPER: '개발자',
  ETC: '기타',
};

export type MachinePlaceType = keyof typeof MachinePlaceTypes | '';

export enum MachineStates {
  RUNNING = 'RUNNING',
  PREPARED = 'PREPARED',
  EXPIRED = 'EXPIRED',
  NONE = 'NONE',
}

export const MACHINE_STATE_VALUES: IndexType = {
  RUNNING: '운영중',
  PREPARED: '미배치',
  EXPIRED: '폐기',
  NONE: '없음',
};

export type MachineStatus = keyof typeof MachineStates | '';

export type ConnectedMachine = {
  id: string;
  idSuffix: string;
  name: string;
  description: string;
  type: string;
  placeType: MachinePlaceType;
  floorId: string;
  connected: boolean;
  fileManagerConnected: boolean;
};

export type UpdatedMachine = {
  machineId: string;
  connected: boolean;
};

export type MachineParams = {
  pointId: string;
  placeType?: MachinePlaceType;
  status?: MachineStatus;
};

export type RegisteredMachine = {
  id: string;
  idSuffix: string;
  name: string;
  description: string;
  updatedDate: string;
  status: string;
  placeType: string;
  screenType: string;
  floorId: string;
};

export type RegisterMachine = {
  id: string;
  name: string;
  idSuffix: string;
  description: string;
  positionX: string;
  positionY: string;
  placeType: string;
  screenType: string;
  mapPosition: {
    rotation: string;
    centerPositionX: string;
    centerPositionY: string;
    zoom: string;
  };
  account?: RegisterAccount;
  floorId: string;
  updatedDate: string;
  status: string;
  shortCode: string;
};

export type LocationForm = {
  name: string;
  description: string;
  positionX: string;
  positionY: string;
  screenType: string;
  mapPosition: {
    rotation: string;
    centerPositionX: string;
    centerPositionY: string;
    zoom: string;
  };
  floorId: string;
};

export type RegisterAccount = {
  username: string;
  password: string;
};

export type PatchMachine = {
  description?: string;
  positionX?: string;
  positionY?: string;
  screenType?: string;
  mapPosition?: {
    rotation?: string;
    centerPositionX?: string;
    centerPositionY?: string;
    zoom?: string;
  };
  account?: {
    password: string;
  };
};
