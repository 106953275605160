import usePoint from 'hooks/feature/point/usePoint';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Point } from 'types/feature/workspace/workspace';
import usePointStore from 'stores/point';
import PointBasicSetting from './basic/PointBasicSetting';
import PointMapPositionSetting from './map/PointMapPositionSetting';
import PointOperationSetting from './operation/PointOperationSetting';

const PointSettingContainer = () => {
  const pointStore = usePointStore();

  if (!pointStore.currentPointId) {
    return <> </>;
  }

  return (
    <div className='flex flex-col gap-6'>
      {/* 기본 정보 */}
      <PointBasicSetting pointId={pointStore.currentPointId} />

      {/* 운영시간 및 휴무일 */}
      <PointOperationSetting pointId={pointStore.currentPointId} />

      {/* 지도 기본 설정  */}
      <PointMapPositionSetting pointId={pointStore.currentPointId} />
    </div>
  );
};

export default PointSettingContainer;
