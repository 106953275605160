import GuideDescription from 'components/common/description/GuideDescription';
import AlertPopup from 'components/common/popup/AlertPopup';
import Title from 'components/common/title/Title';
import { ZOOM_SETTING_GUIDES } from 'data/guide/setting';
import usePoint from 'hooks/feature/point/usePoint';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PointKiosk } from 'types/feature/workspace/point';
import { Mobile, Point, Workspace } from 'types/feature/workspace/workspace';
import { validNumber } from 'utils/common/validNumber';
import PointKioskMapPosition from './PointKioskMapPosition';
import PointMobileMapPosition from './PointMobileMapPosition';
import PointTab from '../basic/PointTab';

type Props = {
  pointId: string;
};
const PointMapPositionSetting = ({ pointId }: Props) => {
  // state
  const [point, setPoint] = useState<Point | null>(null);
  const [webForm, setWebForm] = useState<PointKiosk | null>(null);
  const [mobileForm, setMobileForm] = useState<Mobile | null>(null);
  const [workspace, setWorkspace] = useState<Workspace>();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // hook
  const { workspaceId } = useParams();
  const { getPoint } = usePoint();
  const { getWorkspace } = useWorkspace();

  // useEffect(() => {
  //   if (points) {
  //     setPoint(prev => prev || points[0]);
  //     setWebForm(prev => {
  //       if (prev) return prev;

  //       const { mapMinZoom, mapMaxZoom, mapPosition } = points[0];

  //       return {
  //         mapMinZoom,
  //         mapMaxZoom,
  //         mapPosition,
  //       };
  //     });
  //     setMobileForm(prev => {
  //       if (prev) return prev;
  //       const { mobile } = points[0];
  //       return mobile;
  //     });
  //   }
  // }, [points]);

  /**
   * @desc 워크스페이스 정보 조회 : workspace mobileUsed 유뮤를 확인하기 위함
   */
  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);
      if (result) {
        setWorkspace(result.data);
      }
    }
  };

  // machinePositionX, machinePositionY, mapMinZoom, mapMaxZoom
  const onChangeWebForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setWebForm(prev => {
      if (!prev) return null;
      return { ...prev, [name]: value };
    });
  };

  // machinePositionX, machinePositionY, mapMinZoom, mapMaxZoom
  const onChangeMobileForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }
    setMobileForm(prev => {
      if (!prev) return null;
      return { ...prev, [name]: value };
    });
  };

  // mapPosition (rotation, centerPositionX, centerPositionY, zoom)
  const onChangeMobileMapPosition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setMobileForm(prev => {
      if (!prev) return null;
      return {
        ...prev,
        mapPosition: {
          ...prev.mapPosition,
          [name]: value,
        },
      };
    });
  };

  const onChangeWebMapPosition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!validNumber(value)) {
      setOpenAlert(true);
      setAlertMessage('숫자만 입력가능합니다.');
      return;
    }

    setWebForm(prev => {
      if (!prev) return null;
      return {
        ...prev,
        mapPosition: {
          ...prev.mapPosition,
          [name]: value,
        },
      };
    });
  };

  useEffect(() => {
    if (point) {
      const { mapMinZoom, mapMaxZoom, mapPosition } = point;
      setWebForm({
        mapMinZoom,
        mapMaxZoom,
        mapPosition: { ...mapPosition },
      });
      setMobileForm(point.mobile);
    }
  }, [point]);

  useEffect(() => {
    fetchWorkspace();
  }, []);

  /**
   * 포인트 조회
   * @param {string} pointId
   */
  const fetchPoint = async (id: string) => {
    const response = await getPoint(id);

    if (response) {
      setPoint(response);
    }
  };

  useEffect(() => {
    fetchPoint(pointId);
  }, [pointId]);

  if (!mobileForm || !webForm || !point) {
    return <> </>;
  }

  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <div className='flex flex-col gap-2.5'>
        <div className='flex items-center justify-start h-9'>
          <Title text='지도 기본 설정' titleType='h2' />
        </div>
      </div>
      <GuideDescription guides={ZOOM_SETTING_GUIDES} width='w-[500px]' />

      {/* 포인트 탭 */}
      <PointTab point={point} />

      <div className='flex flex-col gap-8'>
        {/* 키오스크 */}
        <PointKioskMapPosition
          webForm={webForm}
          onChangeKioskForm={onChangeWebForm}
          onChangeKioskMapPosition={onChangeWebMapPosition}
          pointId={point.id}
          fetchPoint={fetchPoint}
        />
        {/* 모바일  */}
        {workspace?.mobileUsed && (
          <PointMobileMapPosition
            onChangeMobileForm={onChangeMobileForm}
            onChangeMobileMapPosition={onChangeMobileMapPosition}
            mobileForm={mobileForm}
            fetchPoint={fetchPoint}
          />
        )}
      </div>

      {openAlert && <AlertPopup onClick={() => setOpenAlert(false)} message={alertMessage} />}
    </section>
  );
};
export default PointMapPositionSetting;
