import { IMAGES } from 'constants/images';
import { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Language, UpdateLanguageType } from 'types/feature/language/language';

type Props = {
  name: string;
  options: { name: string }[];
  currentState: boolean;
  language: Language;
  openConfirm: (value: boolean) => void;
};

const DropDownBtn = ({ options, currentState, name, language, openConfirm }: Props) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState('');

  const handleBadgeStyle = () => {
    if (name === UpdateLanguageType.display) {
      if (!language.display) {
        return `border-[#EAEAEA] bg-badge-white-bg text-badge-white-txt cursor-pointer`;
      }
      return `border-badge-positive-bg bg-badge-positive-bg text-badge-positive-txt border-badge-pending-txt cursor-pointer`;
    }
  };

  // 미사용하는 노출배지일 경우
  // const isDisabledDisplayBadge = () => {
  //   if (name === UpdateLanguageType.display) {
  //     return true;
  //   }
  // };

  // drop down
  const handleOpenDropDown = () => {
    // if (isDisabledDisplayBadge()) return;

    setOpenDropDown(true);
  };

  const handleCloseDropDown = () => {
    // if (isDisabledDisplayBadge()) return;

    setOpenDropDown(false);
  };

  // option
  const handleClickItem = (e: any) => {
    // if (isDisabledDisplayBadge()) return;

    const { id } = e.target;

    if (!id) {
      handleCloseDropDown();
      return;
    }

    handleCloseDropDown();

    if (text === id) {
      return;
    }

    openConfirm(true);
  };

  useEffect(() => {
    if (name === 'display') {
      if (currentState) {
        setText('사용');
      } else {
        setText('미사용');
      }
    }
  }, [language.display]);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenDropDown(false)}>
      <div className='flex justify-center'>
        {/* Badge */}
        <div
          className={`${handleBadgeStyle()} border relative pr-2 pl-3 flex items-center justify-between w-[77px] h-[32px] rounded-2xl`}
        >
          {/* Badge Text */}
          <div onClick={handleOpenDropDown} className='flex items-center justify-between w-full'>
            <div className={`${handleBadgeStyle()} text-xs`}>{text}</div>
            <img
              className='w-[18px] h-[18px] flex'
              src={openDropDown ? IMAGES.ARROW_UP_SQUARE : IMAGES.ARROW_DOWN_SQUARE}
              alt='arrow'
            />
          </div>

          {/* DropDown */}
          {openDropDown && (
            <div
              onClick={handleClickItem}
              ref={dropDownRef}
              className={`border absolute pr-2 pl-3  top-0 left-0 z-40 bg-white w-[77px] rounded-2xl shadow-md`}
            >
              <div className='flex flex-col items-start'>
                {options.map((option, optionIndex) => (
                  <div id={option.name} key={optionIndex} className='w-full'>
                    {optionIndex === 0 ? (
                      <div id={option.name} className='w-full flex items-center justify-between h-[32px]'>
                        <div id={option.name} className={`text-xs bg-white`}>
                          {option.name}
                        </div>
                        <img src={IMAGES.ARROW_UP_SQUARE} alt='arrow' />
                      </div>
                    ) : (
                      <div id={option.name} className={`w-full flex items-center text-xs h-[32px] bg-white`}>
                        {option.name}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DropDownBtn;
