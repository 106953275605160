import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { useEffect, useState } from 'react';
import { ApiResponse } from 'types/api/response';
import { ColorSet, Theme } from 'types/feature/setting/theme/theme.type';
import { handle400Error } from 'utils/error/handle400Error';

const useTheme = () => {
  const { api } = useAxios();

  const [themes, setThemes] = useState<Theme>();
  const [usedColorSet, setUsedColorSet] = useState<ColorSet>();
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [disableSave, setDisableSave] = useState(false);

  const handleModalIfError = () => {
    setOpenAlert(true);
    setOpenConfirm(false);
  };

  // 테마 조회
  const fetchAndSetThemes = async (mainPointId: string) => {
    try {
      const response = await api.get<ApiResponse<Theme>>(Endpoint.themes, {
        params: {
          pointId: mainPointId,
        },
      });

      if (response.data) {
        setThemes(response.data);

        const findColorSet = response.data.colorSets.find(colorSet => colorSet.used);
        setUsedColorSet(findColorSet);
      }
    } catch (error) {
      handle400Error(error, handleModalIfError);
      setAlertMessage('테마를 불러올 수 없습니다.');
    }
  };

  // 컬러셋 활성화
  const activateColorSet = async (mainPointId: string) => {
    try {
      if (usedColorSet) {
        await api.patch(Endpoint.activeColorSet.replace(':colorSetId', usedColorSet.id));
        await fetchAndSetThemes(mainPointId);

        setOpenConfirm(false);
        setOpenAlert(true);
        setAlertMessage(`${usedColorSet.name} 컬러셋으로 변경되었습니다.`);
        setDisableSave(false);
      }
    } catch (error) {
      handle400Error(error, handleModalIfError);
      setAlertMessage('컬러셋을 변경할 수 없습니다.\n다시 시도해주세요.');
    }
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleClickTheme = (colorSet: ColorSet) => {
    setUsedColorSet(colorSet);
  };

  useEffect(() => {
    // 사용하고 있는 컬러셋이라면 버튼 비활성화
    if (usedColorSet?.used) {
      setDisableSave(true);
      return;
    }

    setDisableSave(false);
  }, [usedColorSet]);

  return {
    usedColorSet,
    themes,
    setUsedColorSet,
    activateColorSet,
    openAlert,
    closeAlert: () => setOpenAlert(false),
    handleOpenConfirm,
    closeConfirm: () => setOpenConfirm(false),
    openConfirm,
    alertMessage,
    handleClickTheme,
    disableSave,
    fetchAndSetThemes,
  };
};
export default useTheme;
