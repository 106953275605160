import ARROW_DOWN from 'assets/select/arrow-down.svg';
import ARROW_DOWN_SQUARE from 'assets/select/arrow-down-square.svg';
import ARROW_UP_SQUARE from 'assets/select/arrow-up-square.svg';
import ARROW_RIGHT from 'assets/select/arrow-right.svg';
import LOGO_DABEEO from 'assets/navigation/logo_dabeeo_platform.svg';
import LOGO_PLATFORM from 'assets/navigation/logo_platform.svg';
import MENU_STORE from 'assets/menu/menu_store.svg';
import MENU_FLOOR from 'assets/menu/menu_floor.svg';
import DEFAULT from 'assets/default/default.png';
import DEFAULT_THUMBNAIL from 'assets/default/default-thumbnail.svg';
import FILE_UPLOAD from 'assets/base/file-upload.svg';
import SETTING from 'assets/base/setting.svg';
import TRASH from 'assets/base/trash.svg';
import PENCIL from 'assets/base/pencil.svg';
import CANCEL from 'assets/base/cancel.svg';
import SUCCESS from 'assets/base/success.svg';
import HAMBURGER from 'assets/base/hamburger.svg';
import INFO from 'assets/base/info.svg';
import LOADING_ARROW from 'assets/loading/loading-arrow.svg';
import LOADING from 'assets/loading/loading.gif';
import PREVIEW_SCREENSAVER from 'assets/setting/preview-screensaver.svg';
import PREVIEW_MAP from 'assets/setting/preview-map.svg';
import PREVIEW_TENANT_LIST from 'assets/setting/preview-tenant-list.svg';
import PREVIEW_TENANT_POPUP from 'assets/setting/preview-tenant-popup.svg';
import PREVIEW_MENU_LOGO from 'assets/setting/preview-logo.svg';
import CENTER_SIGN from 'assets/map/center.svg';
import SERVICE_LOGO from 'assets/setting/service-logo.png';

export const IMAGES = {
  ARROW_DOWN,
  ARROW_DOWN_SQUARE,
  ARROW_UP_SQUARE,
  ARROW_RIGHT,

  LOGO_DABEEO,
  LOGO_PLATFORM,

  MENU_FLOOR,
  MENU_STORE,

  DEFAULT,
  DEFAULT_THUMBNAIL,

  FILE_UPLOAD,

  SETTING,
  TRASH,
  PENCIL,
  CANCEL,
  SUCCESS,
  HAMBURGER,
  INFO,

  LOADING_ARROW,
  LOADING,

  PREVIEW_SCREENSAVER,
  PREVIEW_MAP,
  PREVIEW_TENANT_LIST,
  PREVIEW_TENANT_POPUP,
  PREVIEW_MENU_LOGO,

  CENTER_SIGN,

  SERVICE_LOGO,
};
