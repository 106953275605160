import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAccountStore from 'stores/account';
import { AccountMap } from 'types/common/account';
import { RoutePath } from 'types/common/paths';
import { Workspace } from 'types/feature/workspace/workspace';
import dayjs from 'dayjs';
import TypeCard from './card/TypeCard';
import UseTypeCard from './card/UseTypeCard';

type WorkspaceItemProps = {
  workspace: Workspace;
};

const WorkspaceItem = ({ workspace }: WorkspaceItemProps) => {
  const [mapName, setMapName] = useState('');
  const navigate = useNavigate();

  const accountStore = useAccountStore();

  useLayoutEffect(() => {
    fetchMapName();
  }, [workspace, accountStore.account]);

  // 계정 정보로부터 지도 이름 가져옴
  const fetchMapName = async () => {
    try {
      if (accountStore.account) {
        const findMap = accountStore.account.maps.find((map: AccountMap) => map.id === workspace.map.id);

        if (findMap) {
          setMapName(findMap.name);
        }
      }
    } catch (error) {
      console.error('계정 정보를 불러올 수 없습니다.', error);
    }
  };

  const moveToMainPage = () => {
    navigate(RoutePath.content);
  };

  const today = dayjs().format('YYYY-MM-DDTHH:mm:ss');

  const setDate = (updatedDate: string) => {
    // 당일 수정일 경우 시간으로 표기한다.
    if (updatedDate.slice(0, 10) === today.slice(0, 10)) {
      return updatedDate.slice(11);
    }
    // 해당 워크스페이스를 최종 수정한 날짜 표기.
    return updatedDate.slice(0, 10).replaceAll('-', '.');
  };

  return (
    <div
      onClick={moveToMainPage}
      className={`flex w-[335px] h-[204px] px-[40px] py-[36px] flex-col justify-between items-start rounded-[12px] border-r-[2px] border-b-[2px] border-[#EAEAEA] bg-[#FFF]
          hover:bg-state-blue_bg hover:border-state-blue hover:border-2`}
    >
      <div className='flex flex-col w-full gap-[8px]'>
        <div className='flex items-start justify-between w-full h-[26px]'>
          <div className='flex justify-between items-center self-stretch' style={{ width: '100%' }}>
            <div className='flex items-start gap-[8px]'>
              {/* 상태 */}
              <UseTypeCard status={workspace.useType} />
              {/* 타입 */}
              <TypeCard type={workspace.type} />
            </div>
            <div>
              {/* 수정일 */}
              <span className='text-xs text-gray-99'>{setDate(workspace.updatedDate)}</span>
            </div>
          </div>
        </div>

        {/* 워크스페이스 이름 */}
        <div className='h-[56px] flex-1 text-[#444] font-pretendard text-[20px] font-bold leading-[28px] tracking-[-0.4px] line-clamp-2'>
          {workspace.name || '새로운 워크스페이스'}
        </div>
      </div>

      {/* 설명 */}
      <div className='flex flex-col gap-1'>
        {/* 지도 이름 */}
        <div className='flex gap-2'>
          {/* <span className='text-xs font-semibold text-gray-77 shrink-0'>지도명</span> */}
          <span className='text-xs text-gray-99 line-clamp-1 max-h-[20px]'>{mapName}</span>
        </div>
      </div>
    </div>
  );
};
export default WorkspaceItem;
