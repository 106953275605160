import Title from 'components/common/title/Title';
import { ColorSet } from 'types/feature/setting/theme/theme.type';

type Props = {
  colorSet: ColorSet;
  usedColorSet: ColorSet | undefined;
  handleClickTheme: (colorSet: ColorSet) => void;
};

const ColorBox = ({ colorSet, usedColorSet, handleClickTheme }: Props) => {
  const isCurrentTheme = usedColorSet?.id === colorSet.id;

  return (
    <div
      onClick={handleClickTheme.bind(this, colorSet)}
      className={`${
        isCurrentTheme ? 'border-primary-DA_Black' : 'border-gray-ea'
      } w-[142px] h-[108px] border-2 rounded p-5 gap-5 flex flex-col cursor-pointer`}
    >
      <Title text={colorSet.name} titleType={isCurrentTheme ? 'h4_bold' : 'h4'} />

      <div className='flex gap-[6px]'>
        {colorSet.colors.slice(0, 3).map(color => (
          <div key={color.id} className='w-[30px] h-[30px] rounded-full' style={{ background: `${color.color}` }} />
        ))}
      </div>
    </div>
  );
};
export default ColorBox;
