import { ICON } from 'constants/icons';
import React, { useEffect } from 'react';
import { TextType } from 'types/common/text';

type Props = {
  message: string;
  closeToastAfterSeconds: () => void;
  border?: string;
};

const ToastPopup = (props: Props) => {
  useEffect(() => {
    props.closeToastAfterSeconds();
  }, []);

  return (
    <div
      className={`px-[32px] py-5 fixed top-[80%] gap-2.5 z-50 animate-fadeIn
     left-1/2 -translate-x-1/2 bg-opacity-70 bg-black rounded flex items-center cursor-default
     ${props.border || 'border border-gray-cc'}
     `}
    >
      <div className='w-6 h-6'>
        <img src={ICON.TOAST_COMPLETE} alt='완료' />
      </div>
      <div className={`${TextType.h4_bold} text-white`}>{props.message}</div>
    </div>
  );
};
export default ToastPopup;
