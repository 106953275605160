import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import ContentAddForm from 'components/feature/content/add/ContentAddForm';
import useContents from 'hooks/feature/content/useContents';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FileUploadType, UploadFileType } from 'types/common/file/fileUpload';
import { RoutePath } from 'types/common/paths';
import { ScreenType } from 'types/feature/content/contents';
import { replaceWorkspaceId } from 'utils/common/replaceId';

export type Form = {
  title: string | null;
  fileType: FileUploadType | null;
  screenType: ScreenType;
  file: {
    name: string;
    originalName: string;
  };
};

const ContentAddPage = () => {
  // hook
  const { uploadContent } = useContents();
  const { workspaceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // state
  const [confirmSave, setConfirmSave] = useState(false);
  const [addForm, setAddForm] = useState<Form>({
    title: '',
    fileType: null,
    screenType: (location.state?.screenType as ScreenType) || 'VERTICAL',
    file: {
      name: '',
      originalName: '',
    },
  });
  const [openFailPopup, setOpenFailPopup] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const validateContent = () => {
    if (isEmpty(addForm.title)) {
      handleAlert('타이틀을 입력해주세요.');
      return;
    }
    if (isEmpty(addForm.fileType)) {
      handleAlert('파일 유형을 선택해주세요.');
      return;
    }

    if (isEmpty(addForm.screenType)) {
      handleAlert('스크린 타입을 선택해주세요.');
      return;
    }

    if (isEmpty(addForm.file.name) || isEmpty(addForm.file.originalName)) {
      handleAlert('파일을 등록해주세요.');
      return;
    }

    setConfirmSave(true);
  };

  const handleAlert = (message: string) => {
    setAlertMessage(message);
    setConfirmSave(false);
    setOpenFailPopup(true);
  };

  // 콘텐츠 등록 api 요청
  const createContent = async () => {
    if (disableSave) return;

    try {
      setDisableSave(true);
      const form = {
        ...addForm,
        workspaceId,
        type: 'KIOSK',
        fileType: addForm.fileType && UploadFileType[addForm.fileType],
      };

      const result = await uploadContent(form);
      if (result?.result === 'SUCCESS') {
        moveToContentPage();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          handleAlert(`에러가 발생했습니다.`);
        }
      }
    }
  };

  // 취소 클릭
  const clickCancelBtn = () => {
    moveToContentPage();
  };

  const moveToContentPage = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.content, workspaceId), {
        state: {
          pageIndex: location.state?.pageIndex || 0,
          screenType: addForm.screenType || location.state?.screenType || 'VERTICAL',
          searchWord: location.state?.searchWord || '',
        },
      });
    }
  };

  return (
    <>
      <div className='flex flex-col items-center w-full gap-16 pb-20'>
        <ContentAddForm addForm={addForm} setAddForm={setAddForm} />
        <Border borderColor={BorderColor.black} />
        <div className='flex gap-3'>
          <Button text='취소' color={ButtonColor.secondary} onClick={clickCancelBtn} />
          <Button text='저장' color={ButtonColor.primary} onClick={validateContent} />
        </div>
      </div>
      {openFailPopup && <FailPopup closeModal={() => setOpenFailPopup(false)} text={alertMessage} />}
      {confirmSave && (
        <ConfirmPopup
          message='콘텐츠를 등록하시겠습니까?'
          onClickCancel={() => setConfirmSave(false)}
          onClickOk={createContent}
        />
      )}
    </>
  );
};
export default ContentAddPage;
