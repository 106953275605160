import React from 'react';

const ScreensaverListTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width={60} />
        <col width={120} />
        <col width={480} />
        <col width={160} />
        <col width={160} />
        <col width={120} />
        <col width={100} />
      </colgroup>

      <thead>
        <tr className='whitespace-nowrap'>
          <th />
          <th>노출 순서</th>
          <th className='px-2 text-left'>타이틀</th>
          <th>노출 시작일</th>
          <th>노출 종료일</th>
          <th>상태</th>
          <th />
        </tr>
      </thead>
    </>
  );
};
export default ScreensaverListTableHeader;
