/* eslint-disable no-nested-ternary */
import CopyButton from 'components/common/copy/CopyButton';
import FormControl from 'components/common/form-control/FormControl';
import Title from 'components/common/title/Title';
import { TextType } from 'types/common/text';
import { Workspace } from 'types/feature/workspace/workspace';
// import LoadingBackground from 'components/common/loading/LoadingBackground';
// import AlertPopup from 'components/common/popup/AlertPopup';
// import ConfirmPopup from 'components/common/popup/ConfirmPopup';
// import RetryPopup from 'components/common/popup/RetryPopup';
// import SyncResultModal from 'components/feature/setting/basic/map/SyncResultModal';
// import useSync from 'hooks/common/useSync';
// import { useEffect, useState } from 'react';
// import { SyncResult } from 'types/common/sync';
type Props = {
  workspace: Workspace;
  mapName: string;
  fetchWorkspace: () => Promise<void>;
};

// TODO: 지도 동기화 미사용으로 주석처리
const MapInfo = ({ workspace, mapName, fetchWorkspace }: Props) => {
  // const [isSync, setIsSync] = useState(false);
  // const [ableSync, setAbleSync] = useState(false);
  // const [syncResult, setSyncResult] = useState<SyncResult | null>(null);

  // const [isLoadingSync, setIsLoadingSync] = useState(true);

  // const [openConfirm, setOpenConfirm] = useState(false);
  // const [openResultModal, setOpenResultModal] = useState(false);
  // const [openSyncFailed, setSyncFailed] = useState(false);

  // const [openAlert, setOpenAlert] = useState(false);

  // const { getSyncCheck, postSync, getSyncResult } = useSync();

  // 동기화 가능 여부 확인
  // const handleAbleSync = async () => {
  //   try {
  //     const result = await getSyncCheck();
  //     if (result && result.synchronizable) {
  //       setAbleSync(true);
  //     }

  //     setIsLoadingSync(false);
  //   } catch (error) {
  //     setIsLoadingSync(false);
  //     setOpenAlert(true);
  //   }
  // };

  // 지도 동기화
  // const handleMapSync = async () => {
  //   try {
  //     setOpenConfirm(false);
  //     setIsSync(true);

  //     const syncedResult = await postSync();

  //     // 동기화 결과
  //     if (syncedResult) {
  //       setOpenResultModal(true);
  //       setSyncResult(syncedResult);
  //       setAbleSync(false);
  //     }

  //     setIsSync(false);
  //     await fetchWorkspace();
  //   } catch (error) {
  //     handleSyncError();
  //   }
  // };

  // 동기화 예외처리
  // const handleSyncError = () => {
  //   setIsSync(false);
  //   setSyncFailed(true);
  // };

  // 동기화 재시도
  // const handleRetry = async () => {
  //   setSyncFailed(false);
  //   setIsSync(false);
  //   setOpenConfirm(false);

  //   await handleMapSync();
  // };

  // 재시도 취소
  // const handleClickCancelRetry = () => {
  //   setSyncFailed(false);
  //   setIsSync(false);
  //   setOpenConfirm(false);
  // };

  // 지도 동기화 결과보기
  // const handleOpenSyncResult = async () => {
  //   try {
  //     setOpenResultModal(true);

  //     const result = await getSyncResult();

  //     if (result) {
  //       setSyncResult(result);
  //     }
  //   } catch (error) {
  //     setOpenAlert(true);
  //   }
  // };

  // useEffect(() => {
  //   handleAbleSync();
  // }, []);

  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <Title text='지도 정보' titleType='h2' />

      <div className='flex flex-col gap-2.5'>
        <div className='flex flex-col gap-5'>
          <FormControl
            name='지도명'
            labelTextSize='h4'
            control={
              <div className='flex items-center gap-2'>
                <span className={`text-gray-44 ${TextType.body1}`}>{mapName}</span>
                <CopyButton text={mapName} />
              </div>
            }
          />

          <FormControl
            name='지도 아이디'
            labelTextSize='h4'
            control={
              <div className='flex items-center gap-2'>
                <span className={`text-gray-44 ${TextType.body1}`}>{workspace.map.id}</span>
                <CopyButton text={workspace.map.id} />
              </div>
            }
          />

          <FormControl
            name='버전'
            labelTextSize='h4'
            control={
              <div className='flex items-end gap-2'>
                {/* 버전 */}
                <span className={`text-gray-44 ${TextType.body1}`}>{workspace.map.version}v</span>

                {/* 동기화 가능 문구 */}
                {/* <div
                  className={`${TextType.body3} ${ableSync ? 'text-primary-DA_Red' : 'text-primary-DA_Blue_light'} h-4`}
                >
                  {isLoadingSync ? (
                    <div className='h-4' />
                  ) : ableSync ? (
                    '최신버전으로 지도 동기화가 가능합니다.'
                  ) : (
                    '최신버전 지도입니다.'
                  )}
                </div> */}

                {/* 버튼 */}
                {/* <div className='flex gap-2.5'>
                  <Button
                    onClick={() => setOpenConfirm(true)}
                    color={ButtonColor.primary}
                    text='지도 동기화'
                    textStyle={TextType.body2}
                    disabled={!ableSync}
                    size='rounded'
                  />

                  <Button
                    onClick={handleOpenSyncResult}
                    color={ButtonColor.secondary}
                    text='결과보기'
                    textStyle={TextType.body2}
                    size='rounded'
                  />
                </div> */}
              </div>
            }
          />
        </div>
      </div>
      {/* {openConfirm && (
        <ConfirmPopup
          message='최신 버전 지도로 동기화하시겠습니까?'
          onClickOk={handleMapSync}
          onClickCancel={() => {
            setOpenConfirm(false);
          }}
          disabled={isSync}
        />
      )} */}

      {/* {isSync && <LoadingBackground message='지도를 최신 버전으로 동기화 중입니다.' />} */}

      {/* {openResultModal && syncResult && (
        <SyncResultModal syncResult={syncResult} closeModal={() => setOpenResultModal(false)} />
      )} */}

      {/* {openSyncFailed && (
        <RetryPopup
          message={`동기화에 실패하였습니다.\n다시 시도해주세요.`}
          onClickCancel={handleClickCancelRetry}
          onRetry={handleRetry}
          disabled={isSync}
        />
      )} */}

      {/* {openAlert && <AlertPopup message='동기화 정보를 불러올 수 없습니다.' onClick={() => setOpenAlert(false)} />} */}
    </section>
  );
};

export default MapInfo;
