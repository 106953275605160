import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useState } from 'react';
import useScreensaverStore from 'stores/screensaver';
import { ScreenType } from 'types/feature/content/contents';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import DisplayEditPopup from '../../../popup/DisplayEditPopup';

type ListActionButtonsProps = {
  screensaver: GetScreensaver;
  pointId: string;
  screenType: ScreenType;
};

const ListActionButtons = ({ screensaver, pointId, screenType }: ListActionButtonsProps) => {
  // state
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // hook
  const { deleteScreensaver, getScreensavers } = useScreensaver();
  // store
  const screensaverStore = useScreensaverStore();

  const handleEditPopup = () => {
    setOpenEditPopup(!openEditPopup);
  };

  // 스크린세이버 삭제
  const handleDelete = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      e.stopPropagation();

      await deleteScreensaver(screensaver.id);
      const screensavers = await getScreensavers(pointId, screenType);

      if (screensavers) {
        screensaverStore.setScreensavers(screensavers);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setAlertMessage('스크린세이버를 삭제할 수 없습니다.');
        setOpenAlert(true);
      }
    }
  };

  return (
    <>
      <div className={`flex gap-2.5 justify-center items-center h-full`}>
        <div onClick={handleEditPopup}>
          <img src={ICON.CALENDAR_BUTTON} alt='edit' />
        </div>

        <div onClick={() => setOpenDeletePopup(true)}>
          <img src={ICON.TRASH_BUTTON} alt='delete' />
        </div>
      </div>

      {openEditPopup && (
        <DisplayEditPopup
          screensaver={screensaver}
          closePopup={handleEditPopup}
          pointId={pointId}
          screenType={screenType}
        />
      )}

      {openDeletePopup && (
        <ConfirmPopup
          message='스크린세이버 스케줄을 삭제하시겠습니까?'
          onClickOk={handleDelete}
          onClickCancel={() => setOpenDeletePopup(false)}
        />
      )}

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default ListActionButtons;
