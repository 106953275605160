import Button from '../button/Button';
import { ButtonColor } from '../button/Button.type';
import ModalContainer from '../modal/ModalContainer';

type ConfirmPopupProps = {
  message: string | undefined;
  okMessage?: string;
  cancelMessage?: string;
  onClickCancel: (e?: any) => void;
  onClickOk: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
  disabled?: boolean;
  onKeyDown?: any;
};

const ConfirmNoKeybordPopup = ({
  message,
  onClickCancel,
  onClickOk,
  okMessage = '확인',
  cancelMessage = '취소',
  disabled,
  onKeyDown,
}: ConfirmPopupProps) => {
  return (
    <ModalContainer>
      <div
        tabIndex={-1}
        onKeyDown={onKeyDown}
        className='flex flex-col items-center justify-center gap-8 py-20 bg-white px-28'
      >
        <pre className='font-sans text-sm text-center'>{message}</pre>
        <div className='flex justify-center w-full gap-2'>
          <Button disabled={disabled} text={okMessage} color={ButtonColor.primary} onClick={onClickOk} />
          <Button text={cancelMessage} color={ButtonColor.secondary} onClick={onClickCancel} />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConfirmNoKeybordPopup;
