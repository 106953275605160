import { WorkspaceUseType } from 'types/feature/workspace/workspace';

const UseTypeCard = ({ status }: { status: WorkspaceUseType }) => {
  // 상태에 따른 색상 정의
  const getColors = () => {
    switch (status) {
      case 'SERVICE':
        return { bgColor: '#EBFAF4', textColor: '#43CA92' };
      case 'DRAFT':
        return { bgColor: '#F5F5F5', textColor: '#BFBFBF' };
      case 'ARCHIVE':
        return { bgColor: '#FFF3D4', textColor: '#EAB222' };
      case 'TEST':
        return { bgColor: '#F5F5F5', textColor: '#BFBFBF' };
      default:
        return { bgColor: '#FFFFFF', textColor: '#000000' }; // 기본 색상
    }
  };

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // 색상 설정
  const { bgColor, textColor } = getColors();

  return (
    <div
      className='flex justify-center items-center h-[18px] px-[6px] rounded-[4px]'
      style={{
        backgroundColor: bgColor,
        border: `1px solid ${textColor}`,
      }}
    >
      <span
        className='inline-block text-center text-[11px] font-medium leading-[12px] tracking-[-0.22px]'
        style={{
          color: textColor,
        }}
      >
        {capitalizeFirstLetter(status)}
      </span>
    </div>
  );
};

export default UseTypeCard;
