import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { DisplayNameUpdateData, Language, UpdateLanguage } from 'types/feature/language/language';

const useLanguage = () => {
  const { api } = useAxios();

  // 사용자 언어 목록 조회
  const getLanguages = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<Language[]>>(Endpoint.language, {
      params: {
        workspaceId,
      },
    });

    return result.data;
  };

  // 사용자 언어 수정 (노출 유무, 정렬 순서, 사용 유무)
  const updateLanguage = async (id: string, updateContent: UpdateLanguage) => {
    const result = await api.patch<ApiResponse<any>>(Endpoint.language.concat(`/${id}`), updateContent);
    return result;
  };

  // 메인언어 변경
  const updateMainLanguage = async (id: string) => {
    const result = await api.patch(`${Endpoint.language.concat(`/${id}/main`)}`);
    return result;
  };

  // 노출명 수정
  const updateLanguageDisplayName = async (id: string, payload: DisplayNameUpdateData) => {
    const result = await api.patch(`${Endpoint.language.concat(`/${id}`)}`, payload);
    return result;
  };

  return {
    getLanguages,
    updateLanguage,
    updateMainLanguage,
    updateLanguageDisplayName,
  };
};

export default useLanguage;
