import { AxiosError } from 'axios';
import Border from 'components/common/border/Border';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ModalContainer from 'components/common/modal/ModalContainer';
import Title from 'components/common/title/Title';
import useCategory from 'hooks/feature/category/useCategory';
import { useState } from 'react';
import { MultiLangs } from 'types/feature/language/language';
import useHandleKeyDown from 'hooks/common/useHandleKeyDown';
import ConfirmNoKeybordPopup from 'components/common/popup/ConfirmNoKeybordPopup';
import AlertNoKeybordPopup from 'components/common/popup/AlertNoKeybordPopup';
import CategoryMultiLangInputs from './inputs/CategoryMultiLangInputs';

type Props = {
  closePopup: () => void;
  tenantCategoryId: string;
  refetch: () => Promise<void>;
  name: MultiLangs;
};

const MultiLangPopup = ({ closePopup, tenantCategoryId, refetch, name }: Props) => {
  const [categoryName, setCategoryName] = useState<MultiLangs>({ ...name });
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { updateTenantCategory } = useCategory();

  const handleChangeCategoryName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setCategoryName((prev: MultiLangs) => ({
      ...prev,
      [id as string]: value,
    }));
  };

  // 카테고리 이름 저장
  const handleUpdateCategory = async () => {
    try {
      await updateTenantCategory(tenantCategoryId, { name: categoryName });
      await refetch();
      setOpenConfirmPopup(false);
      closePopup();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('카테고리 이름을 설정하는데 문제가 발생하였습니다.');
        }
      }
    }
  };

  const { handleKeyDown } = useHandleKeyDown({
    closeModal: closePopup,
    callback: () => setOpenConfirmPopup(true),
  });

  return (
    <>
      <ModalContainer>
        <div
          className='flex flex-col items-center w-1/4 p-10 bg-white'
          tabIndex={-1} // 포커스를 받을 수 있도록 설정
          onKeyDown={handleKeyDown}
        >
          <div className='flex flex-col items-center w-full gap-8'>
            <Title text='카테고리 타이틀 설정' titleType='h3_bold' />

            <CategoryMultiLangInputs handleChangeCategoryName={handleChangeCategoryName} name={name} />

            <Border />

            <div className='flex gap-4'>
              <Button onClick={closePopup} color={ButtonColor.secondary} size={120}>
                <div>취소</div>
              </Button>

              <Button onClick={() => setOpenConfirmPopup(true)} color={ButtonColor.primary} size={120}>
                <div className='text-white'>저장</div>
              </Button>
            </div>
          </div>
        </div>
      </ModalContainer>

      {openConfirmPopup && (
        <ConfirmNoKeybordPopup
          message='다국어 정보를 수정하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleUpdateCategory}
        />
      )}

      {openAlertPopup && <AlertNoKeybordPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};
export default MultiLangPopup;
