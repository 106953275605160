import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { PointKiosk, PointPutForm } from 'types/feature/workspace/point';
import { Point, PointOperation } from 'types/feature/workspace/workspace';

const usePoint = () => {
  const { api } = useAxios();

  const getPoints = async (workspaceId: string) => {
    const result = await api.get<ApiResponse<Point[]>>(Endpoint.points, {
      params: {
        workspaceId,
      },
    });

    if (result) {
      return result.data;
    }
  };

  const getPoint = async (pointId: string) => {
    const result = await api.get<ApiResponse<Point>>(`${Endpoint.points}/${pointId}`);

    if (result) {
      return result.data;
    }
  };

  const putPoint = async (pointId: string, point: Point) => {
    const result = await api.put<ApiResponse<Point>>(`${Endpoint.points}/${pointId}`, point);
    return result;
  };

  const putPointV2 = async (pointId: string, pointForm: PointPutForm) => {
    const result = await api.put<ApiResponse<Point>>(`${Endpoint.points}/${pointId}`, pointForm);
    return result;
  };

  const putPointOperation = async (pointId: string, pointForm: PointOperation) => {
    const result = await api.put<ApiResponse<Point>>(Endpoint.pointOperation.replace(':pointId', pointId), pointForm);
    return result;
  };

  const putPointMapPosition = async (pointId: string, kioskForm: PointKiosk) => {
    const result = await api.put<ApiResponse<Point>>(Endpoint.pointMapPosition.replace(':pointId', pointId), kioskForm);
    return result;
  };

  const patchMainPoint = async (pointId: string) => {
    const result = await api.patch<ApiResponse<any>>(`${Endpoint.mainPoint.replace(':pointId', pointId)}`);
    return result;
  };

  return {
    getPoints,
    getPoint,
    putPointV2,
    patchMainPoint,
    putPoint,
    putPointOperation,
    putPointMapPosition,
  };
};
export default usePoint;
