/**
 * @desc 지도 zoom 유효성 검사
 */
export const validDefaultZoom = (zoom: number, min: number, max: number) => {
  // mapMinZoom <= zoom <= mapMaxZoom
  const zoomValid = min <= zoom && zoom <= max;
  if (!zoomValid) {
    return false;
  }

  return true;
};

/**
 * @desc 지도 mapMinZoom, mapMaxZoom 유효성 검사
 */
export const validMinMaxZoom = (minZoom: number, maxZoom: number) => {
  if (maxZoom < 15 || maxZoom > 24 || minZoom < 15 || minZoom > 24) {
    return false;
  }

  return true;
};
