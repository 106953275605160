import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import useMenu from 'hooks/feature/menu/useMenu';
import { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import usePointStore from 'stores/point';
import { MainMenu, MainMenuIcon } from 'types/feature/menu/menu';
import { handleSortOrderAsc } from 'utils/sort-order/handle/handleSortOrderAsc';
import MenuItem from './item/MenuItem';

type Props = {
  menuIcons: MainMenuIcon[];
  mainMenus: MainMenu[];
  fetchMainMenus: (pointId: string) => Promise<void>;
};

const MenuList = ({ menuIcons, mainMenus, fetchMainMenus }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const { patchMainMenu } = useMenu();

  const pointStore = usePointStore();

  // 메뉴 순서 변경
  const handleDragEnd = async (result: DropResult) => {
    try {
      const sourceIndex = result.source.index;
      const currentItemId = mainMenus[sourceIndex].id;

      const sortOrder = handleSortOrderAsc(result, mainMenus);

      if (sortOrder) {
        await updateMenuListOrder(currentItemId, sortOrder);
      }
    } catch (e) {
      if (e instanceof Error) {
        setAlertMessage(e.message);
        setOpenAlertMessage(true);
      }
    }
  };

  // 메뉴 리스트 순서 변경
  const updateMenuListOrder = async (menuId: string, sortOrder: number) => {
    try {
      await patchMainMenu(menuId, {
        sortOrder,
      });
      await fetchMainMenus(pointStore.currentPoint?.id || '');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setAlertMessage('메뉴 순서를 수정할 수 없습니다.');
          setOpenAlertMessage(true);
        }
      }
    }
  };

  return (
    <>
      <div className='flex flex-col gap-5'>
        <div className='flex gap-4'>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='droppableId' direction='horizontal'>
              {/* 메뉴 리스트 */}
              {provided => {
                return (
                  <div className='flex gap-4' {...provided.droppableProps} ref={provided.innerRef}>
                    {mainMenus
                      .sort((a, b) => a.sortOrder - b.sortOrder)
                      .map((menu, index) => (
                        <Draggable key={`drag-${menu.id}`} draggableId={`drag-${menu.id}`} index={index}>
                          {draggableProvided => (
                            <MenuItem
                              fetchMainMenus={fetchMainMenus}
                              provided={draggableProvided}
                              menu={menu}
                              menuIcons={menuIcons}
                            />
                          )}
                        </Draggable>
                      ))}

                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      {openAlertMessage && <AlertPopup message={alertMessage} onClick={() => setOpenAlertMessage(false)} />}
    </>
  );
};

export default MenuList;
