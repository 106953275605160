export const MAKE_ACCOUNT_GUIDE = {
  id: '영문, 숫자를 포함하여 5자 이상 입력해주세요.',
  password: '영문, 특수문자를 사용하여 6자 이상 입력해주세요.',
  confirm: '비밀번호를 한번 더 입력해주세요.',
};

export const MAKE_ACCOUNT_HELPER = {
  id: '영문, 숫자를 포함하여 5자 이상 입력해주세요.',
  password: '영문, 특수문자를 사용하여 6자 이상 입력해주세요.',
  confirm: '비밀번호를 확인해주세요.',
};
