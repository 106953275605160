import { ICON } from 'constants/icons';
import useClipBoard from 'hooks/feature/tenant/share/useClipBoard';

const CopyButton = ({ text }: { text: string }) => {
  const { copyUrlOnClipboard } = useClipBoard();

  return (
    <div
      onClick={() => copyUrlOnClipboard(text)}
      className='flex w-[24px] h-[24px] p-1.5 justify-center items-center rounded-[3px] border border-gray-cc bg-white cursor-pointer'
    >
      <img src={ICON.COPY_BUTTON} alt='복사' className='h-full' />
    </div>
  );
};
export default CopyButton;
