import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Title from 'components/common/title/Title';
import { SCREEN_TYPE_FILTER } from 'data/filter';
import useFloor from 'hooks/feature/floor/useFloor';
import useMapController from 'hooks/feature/machine/control/useMapController';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import usePointStore from 'stores/point';
import useWorkspaceStore from 'stores/workspace';
import { LocationForm, MachinePlaceType, RegisterAccount } from 'types/feature/menu/machine/machine';
import { MachineType } from 'types/feature/workspace/workspace';
import { changeCameraFocus, drawKioskMarker } from 'utils/map/mapControl';
import MachineAddButtons from './footer/MachineAddButtons';
import BasicMachineRegisterForm from './form/BasicMachineRegisterForm';
import MachineMapContainer from './map/MachineMapContainer';
import CenterController from './map/controller/center/CenterController';
import DescriptionController from './map/controller/description/DescriptionController';
import FloorController from './map/controller/floor/FloorController';
import MachineTypeController from './map/controller/machine-type/MachineTypeController';
import PositionController from './map/controller/position/PositionController';
import RotationController from './map/controller/rotation/RotationController';
import ZoomController from './map/controller/zoom/ZoomController';

const MachineAddContainer = () => {
  // hook
  const { getWorkspace } = useWorkspace();
  const { fetchFloors, floorList } = useFloor();
  const { workspaceId } = useParams();
  const { state } = useLocation();
  const { screenType, handleChangeScreenType } = useMapController();
  // store
  const workspaceStore = useWorkspaceStore();
  const pointStore = usePointStore();
  // state
  const [machineBasicForm, setMachineBasicForm] = useState<RegisterAccount & { placeType: MachinePlaceType }>({
    username: '',
    password: '',
    placeType: state?.placeType || 'CLIENT',
  });
  const [machinePlaceForm, setMachinePlaceForm] = useState<LocationForm>({
    name: '',
    floorId: '',
    screenType: SCREEN_TYPE_FILTER[0].id,
    positionX: '',
    positionY: '',
    mapPosition: {
      rotation: '',
      centerPositionX: '',
      centerPositionY: '',
      zoom: '',
    },
    description: '',
  });
  const [selectedFloorId, setSelectedFloorId] = useState<string>('');

  const fetchWorkspace = async () => {
    if (workspaceId) {
      const result = await getWorkspace(workspaceId);
      workspaceStore.setWorkspace(result.data);
      workspaceStore.setWorkspaceMachineType(result.data.machineType as MachineType);

      await fetchFloors(state.point?.id);
    }
  };

  const handleMachinePlaceForm = () => {
    const mainFloor = floorList.find(floor => floor.main);

    if (mainFloor) {
      setMachinePlaceForm((prev: LocationForm) => ({
        ...prev,
        floorId: mainFloor.id,
      }));
    }
  };

  useEffect(() => {
    fetchWorkspace();
  }, []);

  useEffect(() => {
    handleMachinePlaceForm();
  }, [floorList]);

  useEffect(() => {
    // 기기 등록시 입력한 지도의 센터값이 바뀔 때, 카메라 포커스를 변경
    changeCameraFocus({
      x: Number(machinePlaceForm.mapPosition.centerPositionX),
      y: Number(machinePlaceForm.mapPosition.centerPositionY),
    });
  }, [machinePlaceForm.mapPosition.centerPositionX, machinePlaceForm.mapPosition.centerPositionY]);

  useEffect(() => {
    // 기기 등록시 입력한 기기의 위치값이 바뀔 때, 마커 위치를 변경
    drawKioskMarker({
      x: Number(machinePlaceForm.positionX),
      y: Number(machinePlaceForm.positionY),
    });
  }, [machinePlaceForm.positionX, machinePlaceForm.positionY]);

  useEffect(() => {
    return () => {
      pointStore.resetCurrentPoint();
    };
  }, []);

  /**
   * 기본층을 설정한다
   */
  const initMainFloor = () => {
    const findMainFloor = floorList.find(floor => floor.main);
    if (findMainFloor) {
      setSelectedFloorId(findMainFloor.id);
    }
  };

  useEffect(() => {
    initMainFloor();
  }, [floorList]);

  const controllerProps = {
    setMachinePlaceForm,
    floorList,
    selectedFloorId,
    setSelectedFloorId,
  };

  return (
    <div className='flex flex-col w-full gap-10 pb-20'>
      <Border borderColor={BorderColor.gray} />

      {/* 기본 정보 */}
      <BasicMachineRegisterForm
        machineType={workspaceStore.workspaceMachineType}
        placeType={machineBasicForm.placeType}
        setMachineBasicForm={setMachineBasicForm}
      />

      <Border borderColor={BorderColor.gray} />

      {/* 지도 정보 */}
      <div className='flex flex-col gap-5'>
        <div className='flex gap-2.5 items-center'>
          <Title text='기기위치 정보' titleType='h3_bold' />
          <div className='text-xs'>
            기기가 등록될 포인트는 <b>{state?.point?.name.ko || ''}입니다.</b>
          </div>
        </div>

        <section className='h-[666px] flex border'>
          {/* 지도 */}
          <MachineMapContainer floorList={floorList} mode='register' screenType={screenType} />

          <div className='w-full border-l'>
            <FloorController {...controllerProps} />
            <MachineTypeController handleChangeScreenType={handleChangeScreenType} {...controllerProps} />
            <PositionController {...controllerProps} />
            <DescriptionController {...controllerProps} />
            <CenterController {...controllerProps} />
            <RotationController {...controllerProps} />
            <ZoomController {...controllerProps} />
          </div>
        </section>
      </div>

      <Border borderColor={BorderColor.gray} />

      {/* 저장, 취소 버튼 */}
      <MachineAddButtons
        machineType={workspaceStore.workspaceMachineType}
        machinePlaceForm={machinePlaceForm}
        machineBasicForm={machineBasicForm}
      />
    </div>
  );
};

export default MachineAddContainer;
