import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import ModalContainer from 'components/common/modal/ModalContainer';
import Title from 'components/common/title/Title';
import ToggleIconUsed from 'components/feature/menu/popup/ToggleIconUsed';
import MenuPopupHeader from 'components/feature/menu/popup/header/MenuPopupHeader';
import MenuIcons from 'components/feature/menu/popup/menu-icon/MenuIcons';
import MenuMultiLangInputs from 'components/feature/menu/popup/multi-language/MenuMultiLangInputs';
import useMenu from 'hooks/feature/menu/useMenu';
import { useRef, useState } from 'react';
import usePointStore from 'stores/point';
import { MultiLangs } from 'types/feature/language/language';
import { MainMenu, MainMenuIcon } from 'types/feature/menu/menu';
import useHandleKeyDown from 'hooks/common/useHandleKeyDown';
import ConfirmNoKeybordPopup from 'components/common/popup/ConfirmNoKeybordPopup';
import AlertNoKeybordPopup from 'components/common/popup/AlertNoKeybordPopup';
import EditMenuType from './menu-type/EditMenuType';
import ToastPopup from './toast/ToastPopup';

type Props = {
  menuIcons: MainMenuIcon[];
  menu: MainMenu;
  closePopup: () => void;
  fetchMainMenus: (pointId: string) => Promise<void>;
};

const DetailMenuPopup = ({ menuIcons, menu, closePopup, fetchMainMenus }: Props) => {
  // state
  const [menuName, setMenuName] = useState<MultiLangs>({
    ko: menu.name.ko,
    en: menu.name.en,
    ja: menu.name.ja,
    cn: menu.name.cn,
    tw: menu.name.tw,
  });
  const [selectedIconId, setSelectedIconId] = useState<string>(menu.iconId);
  const [selectedIconUsed, setSelectedIconUsed] = useState<boolean>(menu.iconUsed);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSaveTitle, setConfirmSaveTitle] = useState(false);
  const [confirmSaveIcon, setConfirmSaveIcon] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastPopupMessage] = useState('');
  // ref
  const toastTimerRef = useRef<NodeJS.Timer | null>(null);

  // hook
  const { deleteMainMenu, patchMainMenu } = useMenu();

  // store
  const pointStore = usePointStore();

  // 메뉴 이름 입력 시
  const handleChangeMenuName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setMenuName((prev: MultiLangs) => ({
      ...prev,
      [id as string]: value,
    }));
  };

  // 메뉴 아이콘 선택 시
  const handleClickIconId = (id: string) => {
    setSelectedIconId(id);
  };

  // 메뉴 아이콘 사용 여부 선택 시
  const handleClickIconUsed = (used: boolean) => {
    setSelectedIconUsed(used);
  };

  // 타이틀 저장 버튼 클릭 시
  const handleClickSaveTitle = () => {
    setConfirmSaveTitle(true);
  };

  // 아이콘 저장 버튼 클릭 시
  const handleClickSaveIcon = () => {
    setConfirmSaveIcon(true);
  };

  // 삭제 버튼 클릭 시
  const handleClickDelete = () => {
    setConfirmDelete(true);
  };

  // 메뉴 타이틀 수정
  const handleUpdateTitle = async () => {
    try {
      if (!menu) return;

      await patchMainMenu(menu.id, {
        name: menuName,
      });

      setConfirmSaveTitle(false);

      setIsToastOpen(true);
      setToastPopupMessage('저장되었습니다');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setConfirmDelete(false);
          setOpenAlert(true);
          setAlertMessage('메뉴 이름을 수정하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
        }
      }
    }
  };

  // 메뉴 아이콘 수정
  const handleUpdateIcon = async () => {
    try {
      if (!menu) return;

      await patchMainMenu(menu.id, {
        iconId: selectedIconId,
        iconUsed: selectedIconUsed,
      });

      setConfirmSaveIcon(false);

      setIsToastOpen(true);
      setToastPopupMessage('저장되었습니다');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setConfirmDelete(false);
          setOpenAlert(true);
          setAlertMessage('메뉴를 아이콘을 수정하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
        }
      }
    }
  };

  // 메뉴 삭제
  const deleteMenu = async () => {
    try {
      await deleteMainMenu(menu.id);
      await fetchMainMenus(pointStore.currentPoint?.id || '');
      setConfirmDelete(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setConfirmDelete(false);
          setOpenAlert(true);
          setAlertMessage('메뉴를 삭제하는 중에 문제가 발생하였습니다. \n다시 시도해주세요.');
        }
      }
    }
  };

  const handleAlert = () => {
    setOpenAlert(false);
    setConfirmDelete(false);
    setConfirmSaveTitle(false);
    setConfirmSaveIcon(false);
  };

  /**
   * 토스트 팝업 타이머를 제거한다
   */
  function clearToastTimer() {
    if (toastTimerRef && toastTimerRef.current) {
      clearTimeout(toastTimerRef.current);
    }
  }

  /**
   * 토스트 팝업을 1.5초 뒤에 닫는다
   */
  function closeToastAfterSeconds() {
    if (toastTimerRef) {
      toastTimerRef.current = setTimeout(() => {
        setIsToastOpen(false);
        clearToastTimer();
      }, 1500);
    }
  }

  const { handleKeyDown } = useHandleKeyDown({
    closeModal: closePopup,
    callback: handleClickDelete,
  });

  return (
    <>
      <ModalContainer>
        <div
          className='flex flex-col items-start bg-white shadow-xl w-[840px]'
          tabIndex={-1} // 포커스를 받을 수 있도록 설정
          onKeyDown={handleKeyDown}
        >
          <MenuPopupHeader headerText='메뉴 설정' />

          {/* 메뉴 타입 */}
          <EditMenuType menuType={menu.type as string} customPageType={menu.customPage?.type || ''} />

          <div className='flex w-full min-h-[278px]'>
            {/* 메뉴 이름 */}
            <div className='flex flex-col flex-1 w-full h-full gap-3 px-10 py-5 border-r shrink-0'>
              <div className='flex items-center justify-between w-full h-9'>
                <Title text='메뉴 이름' titleType='h3' />

                <Button onClick={handleClickSaveTitle} text='저장' color={ButtonColor.primary} size={80} />
              </div>
              <MenuMultiLangInputs menuName={menuName} handleChangeMenuName={handleChangeMenuName} />
            </div>

            {/* 메뉴 아이콘 */}
            <div className='flex flex-col flex-1 w-full gap-3 px-10 py-5 shrink-0'>
              <div className='flex items-center justify-between w-full h-9'>
                <Title text='메뉴 아이콘' titleType='h3' />

                <Button onClick={handleClickSaveIcon} text='저장' color={ButtonColor.primary} size={80} />
              </div>

              <div className='flex flex-col items-start w-full gap-5'>
                <FlexFormControl
                  labelSize='md'
                  name={'사용 유무'}
                  control={<ToggleIconUsed iconUsed={selectedIconUsed} handleClickIconUsed={handleClickIconUsed} />}
                />
                <MenuIcons
                  menuIcons={menuIcons}
                  handleClickIconId={handleClickIconId}
                  selectedIconId={selectedIconId}
                />
              </div>
            </div>
          </div>

          <div className='flex items-center justify-center w-full h-[104px] gap-2.5 border-t'>
            {/* 메뉴 삭제 */}
            {!menu.default && <Button onClick={handleClickDelete} color={ButtonColor.red} text='메뉴 삭제' />}

            {/* 취소 */}
            <Button onClick={closePopup} text='닫기' color={ButtonColor.secondary} />
          </div>
        </div>
      </ModalContainer>

      {confirmDelete && (
        <ConfirmNoKeybordPopup
          message='선택한 메뉴를 삭제하시겠습니까?'
          onClickOk={deleteMenu}
          onClickCancel={() => setConfirmDelete(false)}
        />
      )}

      {confirmSaveTitle && (
        <ConfirmNoKeybordPopup
          message='메뉴 이름을 수정하시겠습니까?'
          onClickOk={handleUpdateTitle}
          onClickCancel={() => setConfirmSaveTitle(false)}
        />
      )}

      {confirmSaveIcon && (
        <ConfirmNoKeybordPopup
          message='메뉴 아이콘을 수정하시겠습니까?'
          onClickOk={handleUpdateIcon}
          onClickCancel={() => setConfirmSaveIcon(false)}
        />
      )}
      {openAlert && <AlertNoKeybordPopup message={alertMessage} onClick={handleAlert} />}
      {/* 성공/실패 토스트 팝업 */}
      {isToastOpen && (
        <ToastPopup message={toastMessage} closeToastAfterSeconds={closeToastAfterSeconds} border='border-none' />
      )}
    </>
  );
};

export default DetailMenuPopup;
