import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import AlertPopup from 'components/common/popup/AlertPopup';
import usePoint from 'hooks/feature/point/usePoint';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useScreensaverStore from 'stores/screensaver';
import { RoutePath } from 'types/common/paths';
import { TextType } from 'types/common/text';
import { ScreenType } from 'types/feature/content/contents';
import { Point } from 'types/feature/workspace/workspace';
import { replacePointId, replaceWorkspaceId } from 'utils/common/replaceId';
import FilterContainer from './filter/FilterContainer';
import ScreensaversTable from './table/ScreensaversTable';

const ScreensaverContainer = () => {
  // hook
  const navigate = useNavigate();
  const location = useLocation();
  const { workspaceId: paramWorkspaceId, pointId: paramPointId } = useParams();
  const { getPoints } = usePoint();
  const { getScreensavers } = useScreensaver();
  // state
  const [points, setPoints] = useState<Point[]>([]);
  const [selectedPointId, setSelectedPointId] = useState<string>('');
  const [screenType, setScreenType] = useState<ScreenType>(location.state?.screenType || 'VERTICAL');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // store
  const { setScreensavers } = useScreensaverStore();

  const fetchPoints = async (workspaceId: string, type: ScreenType) => {
    const pointList = await getPoints(workspaceId);

    if (pointList) {
      setPoints(pointList);

      if (paramPointId) {
        setSelectedPointId(paramPointId);
        await fetchScreensavers(paramPointId, type);
      } else {
        setSelectedPointId(pointList[0].id);
        await fetchScreensavers(pointList[0].id, type);
      }
    }
  };

  const fetchScreensavers = async (pointId: string, type: ScreenType) => {
    try {
      if (pointId) {
        const screensaverList = await getScreensavers(pointId, type);
        if (screensaverList) {
          return setScreensavers(screensaverList);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          setAlertMessage('스크린세이버를 불러올 수 없습니다.');
          setOpenAlert(true);
        }
      }
    }
  };

  /**
   * 빌딩을 변경한다.
   */
  const onChangePoint = async (e: ChangeEvent<HTMLSelectElement>) => {
    const pointId = e.target.value;

    setSelectedPointId(pointId);
    await fetchScreensavers(pointId, screenType);

    if (paramWorkspaceId) {
      navigate(replacePointId(replaceWorkspaceId(RoutePath.pointScreensaver, paramWorkspaceId), selectedPointId), {
        state: {
          screenType,
        },
      });
    }
  };

  /**
   * 스크린 유형을 변경한다.
   */
  const onChangeScreenType = async (e: ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as ScreenType;
    setScreenType(type);
    await fetchScreensavers(selectedPointId, type);
  };

  /**
   * 스케쥴 추가 버튼을 클릭한다.
   */
  const onClickAdd = () => {
    if (paramWorkspaceId) {
      navigate(replacePointId(replaceWorkspaceId(RoutePath.pointScreensaverAdd, paramWorkspaceId), selectedPointId), {
        state: {
          screenType,
        },
      });
    }
  };

  useEffect(() => {
    if (paramWorkspaceId) {
      fetchPoints(paramWorkspaceId, screenType);
    }
  }, [paramWorkspaceId, paramPointId]);

  return (
    <div className='flex flex-col w-full gap-4 pb-20'>
      <div className='flex items-center justify-between w-full'>
        <FilterContainer
          points={points}
          selectedType={screenType}
          selectedPointId={selectedPointId}
          handleChangePoint={onChangePoint}
          handleChangeType={onChangeScreenType}
        />
        <Button
          onClick={onClickAdd}
          text='스케줄 추가'
          color={ButtonColor.primary}
          textStyle={TextType.h5_bold}
          height='h-[36px]'
          width='w-[96px]'
        />
      </div>

      <div className='flex flex-col items-end gap-6'>
        <span className={`text-primary-DA_Red text-xs font-semibold`}>모든 변동 사항은 실시간 반영됩니다.</span>
        <ScreensaversTable screenType={screenType} pointId={selectedPointId} fetchScreensavers={fetchScreensavers} />
      </div>

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </div>
  );
};

export default ScreensaverContainer;
