export const OPERATION_GUIDES = [
  '운영시간 대체 설명은 15자까지만 작성할 수 있습니다.',
  '운영시간과 휴점일을 모두 사용하지 않는 경우 해당 영역에 시계가 노출됩니다.',
];

export const HOLIDAY_GUIDES = ['아래 달력에서 날짜를 선택해주세요.', '휴점일은 4개까지 등록 가능합니다.'];

export const LOGO_GUIDES = [
  '파일 사이즈는 최대 2MB입니다.',
  '로고이미지 권장 사이즈 : 가로 최대 400 * 세로 최대 180 (UHD기준)',
  '배경이 없는 png 이미지, 어두운색 배경에 잘 보일 수 있는 색상의 이미지 권장',
];

export const MAP_MIN_MAX_ZOOM_GUIDES = ['zoom값은 0 ~ 24까지 가능합니다.'];

export const THEME_GUIDES = [
  '키오스크의 색상 테마를 설정합니다.',
  '메인 버튼, 서브 버튼, 길안내 경로 색상이 한 셋으로 구성된 테마 중 하나를 선택하면 아래 화면에서 적용된 모습을 확인할 수 있습니다.',
];

export const LANGUAGE_GUIDES = [
  '현재 제공되는 언어는 한국어, 영어, 일본어, 중국어 간체, 중국어 번체입니다.',
  '키오스크에서 노출할 언어를 선택할 수 있습니다.',
  '키오스크의 메인 언어를 선택할 수 있습니다.',
  '키오스크에서 사용자가 선택할 수 있는 언어의 순서를 변경할 수 있습니다.',
];

export const CUSTOM_FIELD_GUIDES = ['상세 정보창에 새로운 항목을 직접 추가하고 관리할 수 있습니다.'];

export const ZOOM_SETTING_GUIDES = [
  'zoom 값은 15 ~ 24 사이의 양수만 가능합니다.',
  '기본 zoom 값은 최소값과 최대값 사이의 수만 가능합니다.',
];

export const URL_SETTING_GUIDES = [
  'URL은 워크스페이스 생성 후 최초 1회만 변경 가능합니다.',
  '영문 대소문자, 숫자, -, _ 조합으로 최대 16자 입력 가능합니다.',
  '공백 입력, 중복된 URL은 사용 불가합니다.',
];
