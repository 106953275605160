import React from 'react';
import { TextType, FontType } from 'types/common/text';

type TitleProps = {
  text: string;
  titleType: FontType;
  textColor?: string;
  style?: string;
};

const Title = ({ text, titleType, textColor, style }: TitleProps) => {
  return <div className={`${TextType[titleType]} whitespace-nowrap ${textColor} ${style}`}>{text}</div>;
};

export default Title;
