import useKeyboard from 'hooks/common/useKeyboard';
import { ModalProps, ModalType } from '../../../types/common/modal';
import Button from '../button/Button';
import { ButtonColor } from '../button/Button.type';
import ModalContainer from '../modal/ModalContainer';

const SucceedPopup = ({ text, closeModal }: ModalProps) => {
  useKeyboard({ closeModal, modalType: ModalType.alert });

  return (
    <ModalContainer>
      <div className='flex flex-col items-center justify-center gap-8 p-20 px-32 border rounded bg-gray-f9'>
        <div className='text-sm'>{text}</div>
        <div className='flex w-full' onClick={closeModal}>
          <Button text='닫기' color={ButtonColor.primary} />
        </div>
      </div>
    </ModalContainer>
  );
};
export default SucceedPopup;
