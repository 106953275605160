import { IndexType } from 'types/common';

export enum ScreenType {
  all = '',
  verti = 'VERTICAL',
  hori = 'HORIZONTAL',
}

export const SCREEN_TYPE_VALUES: IndexType = {
  ALL: '전체',
  VERTICAL: '세로',
  HORIZONTAL: '가로',
};
