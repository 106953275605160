import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import SucceedPopup from 'components/common/popup/SucceedPopup';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import Textarea from 'components/common/textarea/Textarea';
import Title from 'components/common/title/Title';
import { typeOptions, useTypeOptions } from 'data/workspace/Workspace';
import usePoint from 'hooks/feature/point/usePoint';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useState } from 'react';
import { SelectOption } from 'types/common/selectOption';
import { Workspace, WorkspaceBasicForm } from 'types/feature/workspace/workspace';
import ToggleParkingButtonUsed from './ToggleParkingButtonUsed';
import ToggleZoomButtonUsed from './ToggleZoomButtonUsed';

type Props = {
  workspace: Workspace;
  buildingOptions: SelectOption[];
  buildingId: string;
  fetchWorkspace: () => Promise<void>;
  onChangeMainBuilding: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const WorkspaceSetting = ({ workspace, buildingOptions, buildingId, fetchWorkspace, onChangeMainBuilding }: Props) => {
  // state
  const [isConfirm, setIsConfirm] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [basicForm, setBasicForm] = useState<WorkspaceBasicForm>({
    name: workspace.name || '',
    description: workspace.description || '',
    zoomButtonUsed: workspace.zoomButtonUsed || false,
    type: workspace.type || 'RETAIL',
    useType: workspace.useType || 'DRAFT',
    parkingUsed: workspace.parkingUsed || false,
  });

  // hook
  const { putWorkspaceBasic } = useWorkspace();
  const { patchMainPoint } = usePoint();

  // 저장
  const onClickSave = () => {
    if (!basicForm.name) {
      setIsFail(true);
      setIsConfirm(false);
      return;
    }

    setIsConfirm(true);
  };

  // 워크스페이스 수정
  const updateWorkspace = async () => {
    try {
      await putWorkspaceBasic(workspace?.id || '', basicForm);
      await patchMainPoint(buildingId);
      await fetchWorkspace();

      setIsConfirm(false);
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setIsFail(true);
        }
      }
    }
  };

  /**
   * 입력창 onChange (name, description)
   */
  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value, name } = e.target;

    setBasicForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * 선택창 onChange (type, useType)
   */
  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value, id } = e.target;
    setBasicForm(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <section className='flex flex-col gap-10 p-10 bg-white border border-gray-ea'>
      <Title text='워크스페이스' titleType='h2' />
      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
            onClick={handleConfirmPopup}
            size={80}
            color={ButtonColor.red}
            text='삭제'
            type='button'
            textStyle={'text-xs font-medium'}
          />
        </div> */}

      <div className='flex flex-col gap-3'>
        <FlexFormControl
          name='워크스페이스 이름'
          labelSize='md2'
          required
          control={
            <Input
              width='w-72'
              name='name'
              placeholder='워크스페이스 이름을 입력하세요'
              value={basicForm.name}
              onChange={onChange}
            />
          }
        />

        <FlexFormControl
          name='상태'
          labelSize='md2'
          required
          control={
            <CommonSelectBox
              selectId='useType'
              width='w-[276px]'
              onChange={onChangeSelect}
              selected={basicForm.useType}
              options={useTypeOptions.map(({ id, name }) => {
                return {
                  id,
                  value: name,
                };
              })}
            />
          }
        />

        <FlexFormControl
          name='타입'
          labelSize='md2'
          required
          control={
            <CommonSelectBox
              selectId='type'
              width='w-[276px]'
              onChange={onChangeSelect}
              selected={basicForm.type}
              options={typeOptions.map(({ id, name }) => {
                return {
                  id,
                  value: name,
                };
              })}
            />
          }
        />

        <FlexFormControl
          name='메인 빌딩'
          labelSize='md2'
          required
          control={
            <div className='flex flex-col gap-2.5'>
              <CommonSelectBox
                selectId='mainBuilding'
                width='w-72'
                onChange={onChangeMainBuilding}
                selected={buildingId}
                options={buildingOptions.map(({ id, name }) => {
                  return {
                    id,
                    value: name,
                  };
                })}
              />
              <GuideDescription
                manualInfo={'URL 접속 시, 메인으로 노출할 메인 빌딩을 설정합니다.'}
                bgColor='none'
                infoColor='default'
              />
            </div>
          }
        />

        <FlexFormControl
          name='상세 정보'
          labelSize='md2'
          control={
            <Textarea
              name='description'
              value={basicForm.description}
              placeholder='상세 정보를 입력하세요.'
              onChange={onChange}
              width='w-[518px]'
            />
          }
        />

        <FlexFormControl
          labelSize='md2'
          name='주차위치 지정'
          control={<ToggleParkingButtonUsed zoomButtonUsed={basicForm.parkingUsed} setWorkspaceForm={setBasicForm} />}
        />

        <FlexFormControl
          labelSize='md2'
          name='줌버튼'
          control={<ToggleZoomButtonUsed zoomButtonUsed={basicForm.zoomButtonUsed} setWorkspaceForm={setBasicForm} />}
        />
      </div>

      <div className='flex justify-center w-full'>
        <Button text='저장' type='button' size={120} color={ButtonColor.primary} onClick={onClickSave} />
      </div>

      {/* 확인 */}
      {isConfirm && (
        <ConfirmPopup
          message='워크스페이스 정보를 수정하시겠습니까?'
          onClickCancel={() => setIsConfirm(false)}
          onClickOk={updateWorkspace}
        />
      )}
      {/* 성공 */}
      {isSuccess && <SucceedPopup text='수정되었습니다.' closeModal={() => setIsSuccess(false)} />}
      {/* 실패 */}
      {isFail && <FailPopup closeModal={() => setIsFail(false)} text='워크스페이스 이름을 입력해주세요.' />}
    </section>
  );
};
export default WorkspaceSetting;
