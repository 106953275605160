import { useEffect } from 'react';

type Props = {
  closeModal: () => void;
  callback?: CallableFunction;
};

const useHandleKeyDown = ({ closeModal, callback }: Props) => {
  const handleKeyDown = (e: any) => {
    e.preventDefault();

    if (e.code === 'Enter') {
      if (callback) {
        callback();
      }
    }

    if (e.code === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    (document.activeElement as HTMLElement).blur();

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return { handleKeyDown };
};
export default useHandleKeyDown;
