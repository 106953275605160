import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import useMobile from 'hooks/feature/mobile/useMobile';
import React, { useState } from 'react';
import { TextType } from 'types/common/text';
import { Mobile } from 'types/feature/workspace/workspace';
import { validDefaultZoom, validMinMaxZoom } from 'utils/common/validZoom';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  mobileForm: Mobile;
  onChangeMobileForm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeMobileMapPosition: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fetchPoint: (pointId: string) => Promise<void>;
};

const PointMobileMapPosition = ({ mobileForm, onChangeMobileForm, onChangeMobileMapPosition, fetchPoint }: Props) => {
  // state
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  // hook
  const { patchMobile } = useMobile();

  const onSaveMobile = async () => {
    try {
      if (!mobileForm) return;

      // 줌값 유효성 처리
      const minZoom = Number(mobileForm.mapMinZoom);
      const maxZoom = Number(mobileForm.mapMaxZoom);
      const defaultZoom = Number(mobileForm.mapPosition.zoom);
      if (!validMinMaxZoom(minZoom, maxZoom)) {
        setOpenAlert(true);
        setAlertMessage(`지도 zoom 기본값은 최소값과\n최대값 사이의 숫자만 가능합니다.`);
        setOpenConfirm(false);
        return;
      }

      if (!validDefaultZoom(defaultZoom, minZoom, maxZoom)) {
        setOpenAlert(true);
        setAlertMessage(`zoom값은 15 ~ 22 사이의 값만 입력 가능합니다.`);
        setOpenConfirm(false);
        return;
      }

      await patchMobile(mobileForm.id, { ...mobileForm });
      await fetchPoint(mobileForm.pointId);

      setOpenConfirm(false);
      setOpenAlert(true);
      setAlertMessage('지도 기본 설정값을 저장하였습니다.');
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setAlertMessage('지도 기본 설정값을 수정할 수 없습니다.');
      });
    }
  };

  return (
    <section className='flex flex-col items-start gap-8 px-10 py-6 border border-[#CCC] w-full'>
      <Title text='모바일' titleType='h3_bold' />

      <div className='flex flex-col gap-5'>
        <FlexFormControl
          name='기기 좌표'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>X</div>
                <Input
                  width='w-[90px]'
                  name='machinePositionX'
                  placeholder='1000.123'
                  onChange={onChangeMobileForm}
                  // type='number'
                  value={mobileForm.machinePositionX}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>Y</div>

                <Input
                  width='w-[90px]'
                  name='machinePositionY'
                  placeholder='1000.123'
                  onChange={onChangeMobileForm}
                  // type='number'
                  value={mobileForm.machinePositionY}
                />
              </div>
            </div>
          }
        />
        <FlexFormControl
          name='센터 좌표'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>X</div>
                <Input
                  width='w-[90px]'
                  name='centerPositionX'
                  placeholder='1000.123'
                  onChange={onChangeMobileMapPosition}
                  // type='number'
                  value={mobileForm.mapPosition.centerPositionX}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>Y</div>

                <Input
                  width='w-[90px]'
                  name='centerPositionY'
                  placeholder='1000.123'
                  onChange={onChangeMobileMapPosition}
                  // type='number'
                  value={mobileForm.mapPosition.centerPositionY}
                />
              </div>
            </div>
          }
        />
        <FlexFormControl
          name='방향각'
          control={
            <div className='pl-[70px]'>
              <Input
                width='w-[90px]'
                name='rotation'
                placeholder='rotation'
                onChange={onChangeMobileMapPosition}
                // type='number'
                value={mobileForm.mapPosition.rotation}
              />
            </div>
          }
        />
        <FlexFormControl
          name='줌 레벨'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최소값</div>

                <Input
                  // type='number'
                  width='w-[90px]'
                  name='mapMinZoom'
                  placeholder='15~22'
                  onChange={onChangeMobileForm}
                  min='0'
                  max='24'
                  value={mobileForm.mapMinZoom}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>기본값</div>
                <Input
                  // type='number'
                  width='w-[90px]'
                  name='zoom'
                  placeholder='15~22'
                  onChange={onChangeMobileMapPosition}
                  min='0'
                  max='24'
                  value={mobileForm.mapPosition.zoom}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최대값</div>
                <Input
                  // type='number'
                  width='w-[90px]'
                  placeholder='15~22'
                  name='mapMaxZoom'
                  onChange={onChangeMobileForm}
                  min='0'
                  max='24'
                  value={mobileForm.mapMaxZoom}
                />
              </div>
            </div>
          }
        />
      </div>

      <div className={`flex justify-center w-full mt-10`}>
        <Button text='저장' type='button' color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
      </div>

      {openConfirm && (
        <ConfirmPopup
          message='지도 기본 설정을 저장하시겠습니까?'
          onClickOk={onSaveMobile}
          onClickCancel={() => {
            setOpenConfirm(false);
            setOpenAlert(false);
          }}
        />
      )}

      {/* 성공/실패 팝업 */}
      {openAlert && <AlertPopup onClick={() => setOpenAlert(false)} message={alertMessage} />}
    </section>
  );
};

export default PointMobileMapPosition;
