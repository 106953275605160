import { Workspace, MachineType } from 'types/feature/workspace/workspace';
import { create } from 'zustand';

type WorkspaceProps = {
  workspaceName: string;
  setWorkspaceName: (workspaceName: string) => void;

  workspace: Workspace | null;
  setWorkspace: (workspace: Workspace) => void;

  workspaceMachineType: MachineType;
  setWorkspaceMachineType: (workspaceMachineType: MachineType) => void;
};

const useWorkspaceStore = create<WorkspaceProps>((set, get) => ({
  workspaceName: '',
  setWorkspaceName(workspaceName: string) {
    set({
      workspaceName,
    });
  },

  workspaceMachineType: 'WEB',
  setWorkspaceMachineType: (workspaceMachineType: MachineType) => set(() => ({ workspaceMachineType })),

  workspace: null,
  setWorkspace: (workspace: Workspace) => set(() => ({ workspace })),
}));

export default useWorkspaceStore;
