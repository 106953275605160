/* eslint-disable react/self-closing-comp */
import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import useCategory from 'hooks/feature/category/useCategory';
import { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { TenantCategoryGroup } from 'types/feature/category/tenantCategory';
import { handleSortOrderAsc } from 'utils/sort-order/handle/handleSortOrderAsc';
import CategoryGroupsTableHeader from './header/CategoryGroupsTableHeader';
import CategoryGroupRow from './row/CategoryGroupRow';

type CategoryGroupsTableProps = {
  tenantCategoryGroups: TenantCategoryGroup[];
  pointId: string | undefined;
};

const CategoryGroupsTable = ({ tenantCategoryGroups, pointId }: CategoryGroupsTableProps) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { updateTenantCategory, refetchFeatureCategories } = useCategory();

  const refetchFacilityCategories = async () => {
    if (pointId) {
      await refetchFeatureCategories(pointId, tenantCategoryGroups[0].feature);
    }
  };

  // 테이블 내에서 순서를 변경했을 경우
  const handleDragCategoryOrder = async (dropResult: DropResult) => {
    try {
      const sourceIndex = dropResult.source.index;
      const currentItemId = tenantCategoryGroups[sourceIndex].id;

      const sortOrder = handleSortOrderAsc(dropResult, tenantCategoryGroups);

      if (sortOrder) {
        await updateListTable(currentItemId, sortOrder);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleAlert();
      }
    }
  };

  const updateListTable = async (currentItemId: string, sortOrder: number) => {
    try {
      await updateTenantCategory(currentItemId, { sortOrder });
      await refetchFacilityCategories();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          handleAlert();
        }
      }
    }
  };

  const handleAlert = () => {
    setAlertMessage('카테고리 순서를 변경할 수 없습니다.');
    setOpenAlert(true);
  };

  return (
    <>
      <div className='overflow-y-auto'>
        <table className='w-full'>
          <CategoryGroupsTableHeader />

          <DragDropContext onDragEnd={handleDragCategoryOrder}>
            <Droppable droppableId='category-groups-table'>
              {provided => {
                return (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {tenantCategoryGroups?.map((tenantCategoryGroup, idx) => {
                      return (
                        <Draggable
                          draggableId={`${tenantCategoryGroup.categories[0].contentCategoryCode}`}
                          index={idx}
                          key={`${tenantCategoryGroup.categories[0].contentCategoryCode}`}
                        >
                          {draggableProvided => (
                            <CategoryGroupRow
                              pointId={pointId}
                              draggableProvided={draggableProvided}
                              idx={idx}
                              tenantCategoryGroup={tenantCategoryGroup}
                              refetch={refetchFacilityCategories}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default CategoryGroupsTable;
