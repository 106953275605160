import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FormControl from 'components/common/form-control/FormControl';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import Title from 'components/common/title/Title';
import useModal from 'hooks/common/useModal';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NaviGationSpeedOption, NaviSpeedOptionKo } from 'types/feature/workspace/workspace';

const NaviSetting = () => {
  const [naviSpeedOption, setNaviSpeedOption] = useState<string>('LINEAR');
  const [speedRate, setSpeedRate] = useState<string>('35');
  const [lineWidth, setLineWidth] = useState<string>('13');

  const { openModalPopup, openConfirm, openAlert, closeModalPopup } = useModal();
  const { patchWorkspaceNaviSetting, getWorkspace } = useWorkspace();
  const { workspaceId } = useParams();

  /**
   * 모의보행 속도 옵션을 변경한다.
   */
  const onChangeNaviSpeedOption = (e: ChangeEvent<HTMLSelectElement>) => {
    setNaviSpeedOption(e.target.value);
  };

  /**
   * 모의보행 속도를 변경한다.
   */
  const onChangeSpeedRate = (e: ChangeEvent<HTMLInputElement>) => {
    const speed = e.target.value;
    setSpeedRate(speed);
  };

  /**
   * 모의보행 경로 안내선 굵기를 변경한다.
   */
  const onChangeLineWidth = (e: ChangeEvent<HTMLInputElement>) => {
    const width = e.target.value;
    setLineWidth(width);
  };

  /**
   * 워크스페이스를 조회한다.
   */
  const fetchWorkspace = async () => {
    if (!workspaceId) return;

    const result = await getWorkspace(workspaceId);
    const workspace = result.data;
    if (workspace) {
      setNaviSpeedOption(workspace.navigationSpeedOption);
      setSpeedRate(String(workspace.navigationSpeed));
      setLineWidth(String(workspace.lineThickness));
    }
  };

  /**
   * 저장한다.
   */
  const onClickSave = async () => {
    try {
      if (workspaceId) {
        await patchWorkspaceNaviSetting(workspaceId, {
          lineThickness: Number(lineWidth) || 0,
          navigationSpeed: Number(speedRate) || 0,
          navigationSpeedOption: (naviSpeedOption as NaviGationSpeedOption) || 'LINEAR',
        });

        await fetchWorkspace();
        closeModalPopup('CONFIRM');
      }
    } catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        closeModalPopup('CONFIRM');
        openModalPopup('ALERT');
      }
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspace();
    }
  }, [workspaceId]);

  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <Title text='모의보행 설정' titleType='h2' />
      <div className='flex flex-col gap-5'>
        {/* 속도 관련 */}
        <div className='grid grid-cols-2'>
          <FormControl
            name='모의보행 속도'
            control={<Input width='w-[200px]' placeholder='35' value={speedRate || ''} onChange={onChangeSpeedRate} />}
          />
          <FormControl
            name='모의보행 속도 옵션'
            control={
              <CommonSelectBox
                width='w-[200px]'
                options={naviSpeedOptions}
                selected={naviSpeedOption}
                onChange={onChangeNaviSpeedOption}
              />
            }
          />
        </div>

        {/* 경로 선 굵기 */}
        <FormControl
          name='경로 안내선 굵기'
          control={<Input width='w-[200px]' placeholder='13' value={lineWidth || ''} onChange={onChangeLineWidth} />}
        />

        <div className='flex justify-center w-full mt-5'>
          <Button text='저장' color={ButtonColor.primary} size={120} onClick={() => openModalPopup('CONFIRM')} />
        </div>
      </div>

      {openConfirm && (
        <ConfirmPopup
          message='저장 하시겠습니까?'
          onClickCancel={() => closeModalPopup('CONFIRM')}
          onClickOk={onClickSave}
        />
      )}
      {openAlert && <AlertPopup message='오류가 발생했습니다.' onClick={() => closeModalPopup('ALERT')} />}
    </section>
  );
};
export default NaviSetting;

/**
 * 모의보행 속도 옵션들
 */
const naviSpeedOptions: {
  id: string;
  value: string;
}[] = [
  {
    id: 'LINEAR',
    value: NaviSpeedOptionKo.LINEAR,
  },
  {
    id: 'EASE_IN',
    value: NaviSpeedOptionKo.EASE_IN,
  },
];
