import Input from 'components/common/input/Input';
import useLanguage from 'hooks/feature/language/useLanguage';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Language, LanguageName, MultiLangs } from 'types/feature/language/language';

type Props = {
  pointName: MultiLangs;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PointName = ({ pointName, onChange }: Props) => {
  const { workspaceId } = useParams();
  const { getLanguages } = useLanguage();

  const [languages, setLanguages] = useState<Language[]>([]);

  // 사용하는 언어 목록 조회 (노출여부 X, 사용여부 O)
  const fetchLangs = async () => {
    if (workspaceId) {
      const langs = await getLanguages(workspaceId);
      const usedLangs: Language[] = langs;
      setLanguages(usedLangs);
    }
  };

  useEffect(() => {
    fetchLangs();
  }, []);

  return (
    <div className='flex flex-col w-full gap-2.5'>
      {languages.map(language => {
        const lowerLangCode = language.code.toLowerCase();
        return (
          <div key={language.id} className='flex items-center gap-2.5 relative'>
            {/* 언어 */}
            <div className='absolute text-xs text-right w-[100px] -left-[110px] text-gray-99 whitespace-nowrap'>
              {LanguageName[language.code]}
            </div>

            <Input
              width='w-[360px]'
              height='h-9'
              // onChange={onChange}
              value={pointName[lowerLangCode]}
              placeholder={`${LanguageName[language.code]} 타이틀을 입력해주세요.`}
              id={lowerLangCode}
              name='name'
              readOnly
            />
          </div>
        );
      })}
    </div>
  );
};
export default PointName;
