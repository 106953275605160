import ADD from 'assets/base/add.svg';
import Cancel from 'assets/base/cancel.svg';
import CLOSE_BUTTON from 'assets/base/close-botton.svg';
import CLOSE from 'assets/base/close.svg';
import DOWNLOAD_BUTTON from 'assets/base/download-button.svg';
import DOWNLOAD from 'assets/base/download.svg';
import Hamburger from 'assets/base/hamburger.svg';
import InfoRed from 'assets/base/info-red.svg';
import Info from 'assets/base/info.svg';
import LOGOUT from 'assets/base/logout.svg';
import IMAGE_ICON from 'assets/base/image-icon.svg';
import VIDEO_ICON from 'assets/base/video-icon.svg';
import CALENDAR_BUTTON from 'assets/base/calendar-icon.svg';
import NEW_WINDOW from 'assets/base/new-window.svg';
import Pencil from 'assets/base/pencil.svg';
import Reload_Disabled from 'assets/base/refresh-machine-disabled.svg';
import Reload from 'assets/base/refresh-machine.svg';
import Reset from 'assets/base/reset-black.svg';
import SETTING from 'assets/base/setting.svg';
import Success from 'assets/base/success.svg';
import TOAST_COMPLETE from 'assets/base/toast-complete.svg';
import TRASH_BUTTON from 'assets/base/trash-button.svg';
import TRASH from 'assets/base/trash.svg';
import WORKSPACE from 'assets/base/workspace.svg';
import CurrentPosition from 'assets/setting/current-position.svg';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { CiLock, CiUser } from 'react-icons/ci';
import { GoPencil } from 'react-icons/go';
import { MdChevronRight } from 'react-icons/md';
import { PiNotePencil, PiPlusCircleThin, PiPlusThin, PiSmileySadThin } from 'react-icons/pi';
import COPY_BUTTON from 'assets/base/copy-button.svg';
import HOME from 'assets/base/home.svg';

export const ICON = {
  PiSmileySadThin,
  PiPlusCircleThin,
  CiUser,
  CiLock,
  MdChevronRight,
  PiNotePencil,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  GoPencil,
  PiPlusThin,

  DOWNLOAD,
  TOAST_COMPLETE,
  CLOSE,
  NEW_WINDOW,
  ADD,
  Setting: SETTING,
  WORKSPACE,
  LOGOUT,
  TRASH,
  Pencil,
  Cancel,
  Success,
  Hamburger,
  Info,
  InfoRed,
  CurrentPosition,
  Reload,
  Reload_Disabled,
  Reset,

  CALENDAR_BUTTON,
  TRASH_BUTTON,
  DOWNLOAD_BUTTON,
  COPY_BUTTON,
  CLOSE_BUTTON,

  IMAGE_ICON,
  VIDEO_ICON,
  HOME,
};
