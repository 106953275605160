import { DisplayType } from 'types/common/display';
import { ScreenType as TScreenType } from 'types/feature/content/contents';
import { MachinePlaceType, MachineStatus } from 'types/feature/menu/machine/machine';

export type Select = {
  id: string;
  value: string;
};

export const SCREEN_TYPE_FILTER: { id: TScreenType; value: string }[] = [
  { id: 'VERTICAL', value: '세로' },
  { id: 'HORIZONTAL', value: '가로' },
];

export const DISPLAY_TYPE = [
  { id: DisplayType.always, value: '상시' },
  { id: DisplayType.duration, value: '기간' },
];

export const MACHINE_PLACE_TYPE_FILTER: { id: MachinePlaceType; value: string }[] = [
  { id: 'CLIENT', value: '고객사' },
  { id: 'CLIENT_OFFICE', value: '고객사 사무실' },
  { id: 'DEVELOPER', value: '개발자' },
  { id: 'ETC', value: '기타' },
];

export const MACHINE_STATE_TYPE_FILTER: { id: MachineStatus; value: string }[] = [
  { id: 'RUNNING', value: '운영중' },
  { id: 'PREPARED', value: '미배치' },
  { id: 'EXPIRED', value: '폐기' },
];
