import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';

const UrlBorder = () => {
  return (
    <section className='flex items-center'>
      <div className='w-[160px]' />

      <Border borderColor={BorderColor.lightGray} />
    </section>
  );
};
export default UrlBorder;
