import Badge from 'components/common/badge/Badge';
import Border from 'components/common/border/Border';
import { BorderColor } from 'components/common/border/Border.type';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import FormControl from 'components/common/form-control/FormControl';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import ChangePasswordPopup from 'components/feature/machine/manage/detail/basic/ChangePasswordPopup';
import dayjs from 'dayjs';
import useMachine from 'hooks/feature/machine/useMachine';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextType } from 'types/common/text';
import {
  MACHINE_PLACE_TYPE_VALUES,
  MACHINE_STATE_VALUES,
  MachineStates,
  RegisterMachine,
} from 'types/feature/menu/machine/machine';
import { MachineType } from 'types/feature/workspace/workspace';

type Props = {
  machineType: MachineType;
  registeredMachine: RegisterMachine | undefined;
  fetchWorkspaceAndMachine: () => Promise<void>;
};

const MachineDetailBasic = ({ machineType, registeredMachine, fetchWorkspaceAndMachine }: Props) => {
  const { machineId } = useParams();
  const { discardMachine } = useMachine();
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openPasswordChangePopup, setOpenPasswordChangePopup] = useState(false);

  const handleExpiringMachine = async () => {
    if (machineId) {
      await discardMachine(machineType, machineId);
    }

    setOpenConfirmPopup(false);
    fetchWorkspaceAndMachine();
  };

  return (
    <div className='flex flex-col gap-10'>
      <Border borderColor={BorderColor.gray} />

      <div className='flex flex-col gap-5'>
        <Title text='기본정보' titleType='h3_bold' />
        <section className='flex w-full'>
          <div className='w-[600px] flex flex-col gap-7 flex-1'>
            {/* 구분 */}
            <FlexFormControl
              name='구분'
              control={
                <div className={`h-9 ${TextType.h4} flex items-center justify-center`}>
                  {MACHINE_PLACE_TYPE_VALUES[registeredMachine?.placeType || '']}
                </div>
              }
            />

            {/* 기기 코드 */}
            <FlexFormControl
              name='기기코드'
              control={
                <div className={`h-9 ${TextType.h4} flex items-center justify-center`}>
                  {registeredMachine?.idSuffix}
                </div>
              }
            />

            {/* 최근 수정일 */}
            <FlexFormControl
              name='최근 수정일'
              control={
                <div className={`h-9 ${TextType.h4} flex items-center justify-center`}>
                  {dayjs(registeredMachine?.updatedDate).format('YYYY.MM.DD')}
                </div>
              }
            />
          </div>

          <div className='w-[600px] flex flex-col gap-7 flex-1'>
            {/* 기기 상태 */}
            <FlexFormControl
              name='기기상태'
              control={
                <div className=' w-[320px] flex items-center justify-between'>
                  <Badge
                    badgeType={registeredMachine?.status || ''}
                    name={MACHINE_STATE_VALUES[registeredMachine?.status || '']}
                  />
                  {registeredMachine?.status === MachineStates.RUNNING && (
                    <Button
                      text='폐기처리'
                      color={ButtonColor.red}
                      size={80}
                      onClick={() => setOpenConfirmPopup(true)}
                    />
                  )}
                </div>
              }
            />

            {machineType === 'WEB'
              ? registeredMachine?.status !== MachineStates.EXPIRED && (
                  <>
                    <FlexFormControl
                      name='아이디'
                      control={
                        <div className={`h-9 ${TextType.h4} flex items-center justify-center`}>
                          {registeredMachine?.account?.username}
                        </div>
                      }
                    />
                    <FlexFormControl
                      name='비밀번호'
                      control={
                        <Button
                          text='비밀번호 변경'
                          color={ButtonColor.secondary}
                          size={100}
                          onClick={() => setOpenPasswordChangePopup(true)}
                        />
                      }
                    />
                  </>
                )
              : registeredMachine?.status !== MachineStates.EXPIRED && (
                  <FlexFormControl
                    name='아이디'
                    control={
                      <div className={`h-9 ${TextType.h4} flex items-center justify-center`}>
                        {registeredMachine?.shortCode}
                      </div>
                    }
                  />
                )}
          </div>
        </section>
      </div>

      <Border borderColor={BorderColor.gray} />

      {openConfirmPopup && (
        <ConfirmPopup
          message='현재 기기를 폐기 하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleExpiringMachine}
        />
      )}
      {openPasswordChangePopup && <ChangePasswordPopup onClickCancel={() => setOpenPasswordChangePopup(false)} />}
    </div>
  );
};

export default MachineDetailBasic;
