import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FLEX_CENTER } from 'styles/flex';
import { DisplayType } from 'types/common/display';
import './style/react-datepicker.css';
import CalendarHeader, { CalendarHeaderProps } from 'components/common/calendar/header/CalendarHeader';

type CalendarProps = {
  displayType: DisplayType;
  style?: string;
  disabled?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  handleDate: any;
  handleRange: any;
  hasIcon?: boolean;
};

const RangeCalendar = ({
  displayType,
  style = 'h-9',
  disabled,
  startDate,
  endDate,
  handleRange,
  handleDate,
}: CalendarProps) => {
  const today = new Date();

  // state
  const [rangeStartDate, setRangeStartDate] = useState<Date | null>(startDate);
  const [rangeEndDate, setRangeEndDate] = useState<Date | null>(endDate);

  // ref
  const calendarStartRef = useRef<DatePicker>(null);
  const calendarEndRef = useRef<DatePicker>(null);

  const onChange = (type: 'start' | 'end', date: Date | null) => {
    // 날짜 업데이트
    const updatedDates: [Date | null, Date | null] = type === 'start' ? [date, rangeEndDate] : [rangeStartDate, date];

    // 상태 업데이트
    if (type === 'start') {
      setRangeStartDate(date);
    } else {
      setRangeEndDate(date);
    }
    // 범위 처리 함수 호출
    handleRange(updatedDates);
  };

  const hasRange = displayType === DisplayType.duration;
  const calendarStyle = 'text-gray-44 text-[14px] font-normal leading-[20px] tracking-[-0.28px]';

  const renderHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => (
    <CalendarHeader decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} date={date} />
  );

  return (
    <div className='flex gap-[10px]'>
      {hasRange ? (
        <>
          <div
            className={`${FLEX_CENTER.row} w-[124px] h-9 
         ${disabled ? 'border border-gray-300' : 'border border-gray-99'}
             overflow-hidden gap-[10px] pl-[12px] pr-[8px]`}
          >
            <div>
              <DatePicker
                className={`${calendarStyle} w-[76px] ${style} ${
                  disabled ? 'text-gray-cc border-gray-99' : 'text-gray-300 border-gray-300'
                } text-xs h-8 `}
                selected={rangeStartDate}
                onChange={date => onChange('start', date)}
                selectsStart
                minDate={today}
                maxDate={rangeEndDate}
                startDate={rangeStartDate}
                endDate={rangeEndDate}
                renderCustomHeader={renderHeader}
                dateFormat='yyyy.MM.dd'
                ref={calendarStartRef}
              />
            </div>

            <div onClick={() => calendarStartRef.current?.setOpen(true)} className='cursor-pointer'>
              <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                <rect
                  x='0.6'
                  y='1.7355'
                  width='12.8'
                  height='11.6647'
                  stroke={disabled ? '#EAEAEA' : '#444444'}
                  strokeWidth='1.2'
                />
                <rect x='3.38281' width='1.4' height='3.40537' fill={disabled ? '#EAEAEA' : '#444444'} />
                <rect x='9.21582' width='1.4' height='3.40537' fill={disabled ? '#EAEAEA' : '#444444'} />
                <path
                  d='M0.875558 6.26687L13.1231 6.27273'
                  stroke={disabled ? '#EAEAEA' : '#444444'}
                  strokeWidth='1.2'
                />
              </svg>
            </div>
          </div>
          <div className='flex items-center justify-center text-gray-44 font-pretendard text-[14px] font-medium leading-[18px] tracking-[-0.28px]'>
            ~
          </div>
          <div
            className={`${FLEX_CENTER.row} w-[124px] h-9 
         ${disabled ? 'border border-gray-300' : 'border border-gray-99'}
             overflow-hidden gap-[10px] pl-[12px] pr-[8px]`}
          >
            <div>
              <DatePicker
                className={`${calendarStyle} w-[76px] ${style} ${
                  disabled ? 'text-gray-cc border-gray-99' : 'text-gray-300 border-gray-300'
                } text-xs h-8 `}
                selected={rangeEndDate}
                onChange={date => onChange('end', date)}
                selectsEnd
                startDate={rangeStartDate}
                endDate={rangeEndDate}
                minDate={rangeStartDate}
                renderCustomHeader={renderHeader}
                dateFormat='yyyy.MM.dd'
                ref={calendarEndRef}
                placeholderText='0000.00.00'
              />
            </div>
            <div onClick={() => calendarEndRef.current?.setOpen(true)} className='cursor-pointer'>
              <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                <rect
                  x='0.6'
                  y='1.7355'
                  width='12.8'
                  height='11.6647'
                  stroke={disabled ? '#EAEAEA' : '#444444'}
                  strokeWidth='1.2'
                />
                <rect x='3.38281' width='1.4' height='3.40537' fill={disabled ? '#EAEAEA' : '#444444'} />
                <rect x='9.21582' width='1.4' height='3.40537' fill={disabled ? '#EAEAEA' : '#444444'} />
                <path
                  d='M0.875558 6.26687L13.1231 6.27273'
                  stroke={disabled ? '#EAEAEA' : '#444444'}
                  strokeWidth='1.2'
                />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${FLEX_CENTER.row} w-[124px] h-9 ${
              disabled ? 'border border-gray-300' : 'border border-gray-99'
            }
            } overflow-hidden gap-[10px] pl-[12px] pr-[8px]`}
          >
            <div>
              <DatePicker
                className={`${calendarStyle} w-[76px] ${style} ${
                  disabled ? 'text-gray-cc border-gray-99' : 'text-gray-300 border-gray-300'
                } text-xs h-8 `}
                selected={startDate}
                onChange={handleDate}
                selectsStart
                startDate={startDate}
                minDate={today}
                renderCustomHeader={renderHeader}
                dateFormat='yyyy.MM.dd'
                ref={calendarStartRef}
              />
            </div>
            <div onClick={() => calendarStartRef.current?.setOpen(true)} className='cursor-pointer'>
              <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                <rect
                  x='0.6'
                  y='1.7355'
                  width='12.8'
                  height='11.6647'
                  stroke={disabled ? '#EAEAEA' : '#444444'}
                  strokeWidth='1.2'
                />
                <rect x='3.38281' width='1.4' height='3.40537' fill={disabled ? '#EAEAEA' : '#444444'} />
                <rect x='9.21582' width='1.4' height='3.40537' fill={disabled ? '#EAEAEA' : '#444444'} />
                <path
                  d='M0.875558 6.26687L13.1231 6.27273'
                  stroke={disabled ? '#EAEAEA' : '#444444'}
                  strokeWidth='1.2'
                />
              </svg>
            </div>
          </div>
          <div className='flex items-center justify-center text-gray-44 text-[14px] font-medium leading-[18px] tracking-[-0.28px]'>
            ~
          </div>
          <div className={`flex w-[124px] h-9 border border-gray-cc  pl-[12px] pr-[8px]`}>
            <div className='flex items-center justify-start text-gray-cc font-pretendard text-xs'>종료일 없음</div>
          </div>
        </>
      )}
    </div>
  );
};

export default RangeCalendar;
