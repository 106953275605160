import Badge from 'components/common/badge/Badge';
import ThumbnailTableRowCell from 'components/common/thumbnail/ThumbnailTableRowCell';
import { ICON } from 'constants/icons';
import { DraggableProvided } from 'react-beautiful-dnd';
import { DisplayStatus, DisplayType } from 'types/common/display';
import { TextType } from 'types/common/text';
import { ScreenType } from 'types/feature/content/contents';
import { GetScreensaver } from 'types/feature/screensaver/getScreensaver';
import ListActionButtons from './buttons/actions/ListActionButtons';
import DraggableButton from './buttons/drag/DraggableButton';

type ScreensaverListTableRowProps = {
  provided: DraggableProvided;
  screensaver: GetScreensaver;
  pointId: string;
  screenType: ScreenType;
  index: number;
};

const ScreensaverListTableRow = ({
  provided,
  screensaver,
  pointId,
  screenType,
  index,
}: ScreensaverListTableRowProps) => {
  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      key={screensaver.sortOrder}
      className='hover:bg-slate-50 h-[52px]'
    >
      {/* 순서 변경 */}
      <td {...provided.dragHandleProps}>
        <DraggableButton />
      </td>
      {/* 노출 순서 */}
      <td className='text-sm whitespace-nowrap text-gray-99'>{index + 1}</td>
      {/* 타이틑 */}
      <td>
        <div className='flex items-center gap-2'>
          <div className='w-[18px] h-[18px]'>
            {screensaver.content.fileType === 'IMAGE' ? (
              <img src={ICON.IMAGE_ICON} alt='이미지' />
            ) : (
              <img src={ICON.VIDEO_ICON} alt='동영상' />
            )}
          </div>
          <ThumbnailTableRowCell
            title={screensaver.content.title}
            files={screensaver.content.file}
            fileType={screensaver.content.fileType}
            titleStyle={TextType.h4}
          />
        </div>
      </td>
      {/* 노출 시작일 */}
      <td className={`text-gray-44 ${TextType.body1}`}>{screensaver.startDate}</td>
      {/* 노출 종료일 */}
      <td>
        {/* 기간노출일 경우에만 노출종료일 보여줌 */}
        {screensaver.displayType === DisplayType.duration ? (
          <div className={`text-gray-44 ${TextType.body1}`}>{screensaver.endDate}</div>
        ) : (
          <div className={`text-gray-99 ${TextType.body1}`}>종료일 없음</div>
        )}
      </td>
      <td>
        <Badge name={DisplayStatus[screensaver.displayStatus]} badgeType={screensaver.displayStatus} />
      </td>
      <td>
        <ListActionButtons screensaver={screensaver} pointId={pointId} screenType={screenType} />
      </td>
    </tr>
  );
};
export default ScreensaverListTableRow;
