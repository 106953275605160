import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import Input from 'components/common/input/Input';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { SCREEN_TYPE_FILTER } from 'data/filter';
import useContents from 'hooks/feature/content/useContents';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { Content, ContentItem, ScreenType, ScreenTypeCode } from 'types/feature/content/contents';
import { clearHistoryStates } from 'utils/common/clearHistoryStates';
import { replaceWorkspaceId } from 'utils/common/replaceId';
import ContentTable from './table/ContentTable';

const ContentContainer = () => {
  // hook
  const { workspaceId } = useParams();
  const { getContents } = useContents();
  const navigate = useNavigate();
  const location = useLocation();

  // state
  const [screenType, setScreenType] = useState<ScreenType>(location.state?.screenType || 'VERTICAL');
  const [searchWord, setSearchWord] = useState(location.state?.searchWord || '');
  const [currentPage, setCurrentPage] = useState(location.state?.pageIndex || 0);
  const [contentsList, setContentsList] = useState<ContentItem[]>([]);
  const [content, setContent] = useState<Content>();

  /**
   * content 목록을 조회한다.
   * @param {number} pageNum 페이지 번호
   * @param {string} title 제목
   * @param {ScreenType} type 가로/세로 스크린 유형
   */
  const fetchContents = async (pageNum: number, title: string, type: ScreenType) => {
    const result = await getContents(pageNum, title, type);

    if (result) {
      setContent(result);
      setContentsList(result.content);
    }
  };

  // 필터
  const onChangeScreenType = async (e: ChangeEvent<HTMLSelectElement>) => {
    setScreenType(e.target.value as ScreenTypeCode);
    setCurrentPage(0);
    await fetchContents(0, searchWord, e.target.value as ScreenTypeCode);
  };

  // 타이틀 검색
  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  // 페이지 클릭시 동작
  const onClickPage = async (pageIndex: number) => {
    if (contentsList.length > 0) {
      setCurrentPage(pageIndex);
      await fetchContents(pageIndex, searchWord, screenType);
    }
  };

  /**
   * form 을 제출한다.
   */
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setCurrentPage(0);
    await fetchContents(0, searchWord, screenType);
  };

  // 검색 초기화
  const resetFilter = async () => {
    clearHistoryStates();
    setSearchWord('');
    setScreenType('VERTICAL');
    setCurrentPage(0);
    await fetchContents(0, '', 'VERTICAL');
  };

  // 콘텐츠 등록
  const moveToAddPage = () => {
    if (workspaceId) {
      navigate(replaceWorkspaceId(RoutePath.contentAdd, workspaceId), {
        state: {
          pageIndex: currentPage,
          screenType,
          searchWord,
        },
      });
    }
  };

  useEffect(() => {
    fetchContents(currentPage, searchWord, screenType);
  }, []);

  return (
    <div className='flex flex-col w-full gap-10'>
      <div className='flex justify-between'>
        <div className='flex gap-2.5'>
          <CommonSelectBox
            width='w-[160px]'
            onChange={onChangeScreenType}
            selected={screenType}
            options={SCREEN_TYPE_FILTER}
          />
          <form onSubmit={onSubmit} className='flex h-10 gap-2'>
            <Input
              width='w-60'
              placeholder='타이틀 검색'
              value={searchWord}
              icon={
                <svg xmlns='http://www.w3.org/2000/svg' width='13' height='14' viewBox='0 0 13 14' fill='none'>
                  <path
                    d='M1.14711 6.31806C1.14711 3.99166 3.03304 2.10573 5.35945 2.10573C7.68585 2.10573 9.57178 3.99166 9.57178 6.31806C9.57178 6.85517 9.47153 7.36767 9.2891 7.83872L10.1579 8.70755C10.1945 8.63423 10.2294 8.55995 10.2627 8.48477C10.5562 7.82151 10.7189 7.08803 10.7189 6.31806C10.7189 3.35812 8.31939 0.958618 5.35945 0.958618C2.39951 0.958618 0 3.35812 0 6.31806C0 9.278 2.39951 11.6775 5.35945 11.6775C6.13794 11.6775 6.87912 11.5112 7.54813 11.2115C7.60359 11.1867 7.65856 11.1609 7.713 11.1343L6.84127 10.2625C6.38092 10.4356 5.88181 10.5304 5.35945 10.5304C3.03304 10.5304 1.14711 8.64447 1.14711 6.31806Z'
                    fill='#444444'
                  />
                  <path d='M8.66686 9.6945L11.9857 13.0413' stroke='#444444' />
                </svg>
              }
              onChange={onChangeTitle}
            />
            <Button type='submit' text='검색' color={ButtonColor.primary} size='filter' />
            <Button type='button' text='초기화' color={ButtonColor.delete} size='filter' onClick={resetFilter} />
          </form>
        </div>

        <Button size='add' text='콘텐츠 등록' color={ButtonColor.primary} onClick={moveToAddPage} />
      </div>

      {/* 테이블 */}
      <ContentTable
        contentItems={contentsList}
        currentPage={currentPage}
        totalElements={content && content.totalElements}
        refetchContents={fetchContents}
        onChangePage={onClickPage}
        screenType={screenType}
        searchWord={searchWord}
        resetPage={() => setCurrentPage(0)}
      />
    </div>
  );
};

export default ContentContainer;
