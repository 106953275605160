import { AxiosError } from 'axios';
import useScroll from 'hooks/common/useScroll';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import useWorkspaceStore from 'stores/workspace';
import SettingNavigation from './navigation/SettingNavigation';

const SettingContainer = () => {
  const { workspaceId } = useParams();
  const { getWorkspace } = useWorkspace();
  const location = useLocation();
  const workspaceStore = useWorkspaceStore();

  const [currentMenu, setCurrentMenu] = useState(RoutePath.basicSetting);

  useScroll({ currentMenu });

  const fetchWorkspace = async () => {
    if (workspaceId) {
      try {
        const result = await getWorkspace(workspaceId);

        if (result) {
          workspaceStore.setWorkspaceName(result.data.name);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          throw error;
        }
      }
    }
  };

  useEffect(() => {
    fetchWorkspace();
  }, []);

  useEffect(() => {
    setCurrentMenu(location.pathname as RoutePath);
  }, [location.pathname]);

  return (
    <div className='flex flex-col w-full h-full '>
      {/* navi */}
      <div className='relative top-0 left-0 flex items-center justify-center w-full h-full'>
        <div className='flex w-[1400px]'>
          <SettingNavigation setCurrentMenu={setCurrentMenu} />

          <div className='flex flex-col w-full min-h-[860px] p-10'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingContainer;
