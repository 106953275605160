import { AxiosError } from 'axios';
import NoContent from 'components/common/no-content/NoContent';
import AlertPopup from 'components/common/popup/AlertPopup';
import useScreensaver from 'hooks/feature/screensaver/useScreensaver';
import { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import useScreensaverStore from 'stores/screensaver';
import { ScreenType } from 'types/feature/content/contents';
import { handleSortOrderDesc } from 'utils/sort-order/handle/handleSortOrderDesc';
import ScreensaverListTableHeader from './header/ScreensaverListTableHeader';
import ScreensaverListTableRow from './row/ScreensaverListTableRow';

type ScreensaverListTableProps = {
  pointId: string;
  screenType: ScreenType;
  fetchScreensavers: (pointId: string, screenType: ScreenType) => Promise<void>;
};

const ScreensaversTable = ({ screenType, pointId, fetchScreensavers }: ScreensaverListTableProps) => {
  const { updateScreensaver } = useScreensaver();
  const { screensavers } = useScreensaverStore();

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleDragScreensaver = async (dropResult: DropResult) => {
    try {
      const sourceIndex = dropResult.source.index;
      const currentItemId = screensavers[sourceIndex].id;

      const sortOrder = handleSortOrderDesc(dropResult, screensavers);

      if (sortOrder) {
        await updateListTable(currentItemId, sortOrder);
      }
    } catch (e) {
      if (e instanceof Error) {
        handleAlert();
      }
    }
  };

  const updateListTable = async (currentItemId: string, sortOrder: number) => {
    try {
      await updateScreensaver(currentItemId, { sortOrder });
      await fetchScreensavers(pointId, screenType);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          handleAlert();
        }
      }
    }
  };

  const handleAlert = () => {
    setAlertMessage('스크린세이버 스케줄울 수정할 수 없습니다.');
    setOpenAlert(true);
  };

  return (
    <>
      <div className='w-full pr-2'>
        <table className='w-full cursor-default'>
          <ScreensaverListTableHeader />

          <DragDropContext onDragEnd={handleDragScreensaver}>
            <Droppable droppableId='table'>
              {provided => {
                return (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {screensavers
                      ?.sort((a, b) => b.sortOrder - a.sortOrder)
                      .map((screensaver, idx) => {
                        return (
                          <Draggable draggableId={`drag-${idx}`} index={idx} key={`drag-${idx}`}>
                            {draggableProvided => (
                              <ScreensaverListTableRow
                                index={idx}
                                provided={draggableProvided}
                                screensaver={screensaver}
                                pointId={pointId}
                                screenType={screenType}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </tbody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </table>

        <NoContent visible={screensavers?.length < 1} text='등록된 스크린세이버가 없습니다.' />
      </div>

      {openAlert && <AlertPopup message={alertMessage} onClick={() => setOpenAlert(false)} />}
    </>
  );
};
export default ScreensaversTable;
