export const replaceWorkspaceId = (router: string, workspaceId: string): string => {
  return router.replace(':workspaceId', workspaceId);
};

export const replacePointId = (router: string, pointId: string): string => {
  return router.replace(':pointId', pointId);
};

export const replaceContentId = (router: string, contentId: string): string => {
  return router.replace(':contentId', contentId);
};

export const replaceMachineId = (router: string, machineId: string): string => {
  return router.replace(':machineId', machineId);
};
