import { MultiLangs } from '../language/language';

export type MapStatus = {
  floor: string;
  ready: boolean;
  tenant: string;
};

export type MapObject = {
  id: string;
  status: MapStatus;
  version: string;
  clientId: string;
  clientSecret: string;
};

export type PointDetail = {
  hoursStart: string;
  hoursEnd: string;
  closedDays: string[];
};

export type MapPosition = {
  rotation: string;
  centerPositionX?: string;
  centerPositionY?: string;
  zoom: string;
};

export type Mobile = {
  id: string;
  machinePositionX: string;
  machinePositionY: string;
  mapPosition: MapPosition;
  mapMinZoom: string;
  mapMaxZoom: string;
  pointId: string;
};

export type HoursType = 'DATE' | 'TEXT';

export type PointOperation = {
  hoursDescription: string;
  hoursUsed: boolean;
  hoursStart: string;
  hoursEnd: string;
  closedDaysUsed: boolean;
  closedDays: string[];
  hoursText: MultiLangs;
  hoursType: HoursType;
};

export type Point = {
  id: string;
  name: MultiLangs;
  description: string;
  logo: Logo | null;
  operation: PointOperation;
  mapMinZoom: string;
  mapMaxZoom: string;
  mapPosition: MapPosition;
  mobile: Mobile;
  main?: boolean;
};

export type Logo = {
  name?: string;
  originalName?: string;
  delete?: boolean;
  uri?: string;
  size?: number;
  width?: number;
  height?: number;
};

export type MachineType = 'WEB' | 'DESKTOP';

export type WorkspaceType = 'RETAIL' | 'EXHIBITION' | 'OFFICE' | 'ETC'; // 상태 type
export type WorkspaceUseType = 'SERVICE' | 'DRAFT' | 'ARCHIVE' | 'TEST'; // 타입 usetype

export type NaviGationSpeedOption = 'LINEAR' | 'EASE_IN';

export enum NaviSpeedOptionKo {
  LINEAR = '일정한 속도 유지',
  EASE_IN = '점점 빠르게',
}

export type NavigationSetting = {
  lineThickness: number;
  navigationSpeed: number;
  navigationSpeedOption: NaviGationSpeedOption;
};

export type Workspace = {
  id: string;
  name: string;
  description: string;
  createdDate: string;
  updatedDate: string;
  map: MapObject;
  points: Point[];
  mobileUsed: boolean;
  webUsed: boolean;
  zoomButtonUsed: boolean;
  machineType: MachineType;
  type: WorkspaceType;
  useType: WorkspaceUseType;
  mobile: Mobile;
  parkingUsed: boolean;
  clientPath: string;
  clientPathModified: boolean;
  webEndpoint: string;
  mobileEndpoint: string;
  lineThickness: number;
  navigationSpeed: number;
  navigationSpeedOption: NaviGationSpeedOption;
};

export type WorkspaceForm = {
  name: string;
  description: string;
  mobileUsed: boolean;
  webUsed: boolean;
  zoomButtonUsed: boolean;
  mapId?: string;
  machineType?: MachineType;
  parkingUsed: boolean;
  type: WorkspaceType | string;
  useType: WorkspaceUseType | string;
  clientPath?: string;
};

export type InputStatus = 'default' | 'success' | 'error';

export type WorkspaceBasicForm = {
  name: string;
  description: string;
  zoomButtonUsed: boolean;
  type: WorkspaceType;
  useType: WorkspaceUseType;
  parkingUsed: boolean;
};
