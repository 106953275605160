import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { SCREEN_TYPE_FILTER } from 'data/filter';
import { ChangeEvent } from 'react';
import { Point } from 'types/feature/workspace/workspace';

type FilterContainerProps = {
  points: Point[];
  selectedType: string;
  selectedPointId: string;
  handleChangePoint: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleChangeType: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const FilterContainer = ({
  points,
  selectedType,
  selectedPointId,
  handleChangePoint,
  handleChangeType,
}: FilterContainerProps) => {
  return (
    <div className='flex gap-2.5'>
      <CommonSelectBox
        options={points.map(point => ({
          id: point.id,
          value: point.name.ko,
        }))}
        selected={selectedPointId}
        onChange={handleChangePoint}
        width='w-40'
      />

      <CommonSelectBox
        options={SCREEN_TYPE_FILTER}
        selected={selectedType}
        onChange={handleChangeType}
        width='w-[120px]'
      />
    </div>
  );
};

export default FilterContainer;
