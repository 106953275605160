import { AxiosError } from 'axios';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { ICON } from 'constants/icons';
import useAuth from 'hooks/common/useAuth';
import useUserAccount from 'hooks/common/useUserAccount';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAccountStore from 'stores/account';
import { RoutePath } from 'types/common/paths';

const OuterNav = () => {
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const accountStore = useAccountStore();

  const { getAccount } = useUserAccount();
  const { logout } = useAuth();
  const navigate = useNavigate();

  // 계정 정보
  const fetchAccount = async () => {
    try {
      const result = await getAccount();
      if (result) {
        accountStore.setAccount(result);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('계정 정보를 불러올 수 없습니다.');
        }
      }
    }
  };

  // 로그아웃
  const handleLogout = async () => {
    try {
      await logout();

      setOpenConfirmPopup(false);
      accountStore.setAccount(null);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('로그아웃을 할 수 없습니다.');
        }
      }
    }
  };

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <div className='flex items-center justify-between w-[1400px] h-20'>
      <div onClick={() => navigate(RoutePath.workspaces)} className='flex gap-2 cursor-pointer'>
        {/* 부서명 */}
        {/* {accountStore.account?.department && (
          <div className='text-xl font-semibold'>{accountStore.account.department}</div>
        )} */}

        {/* 계정명 */}
        {accountStore.account?.name && <span className='text-xl font-bold'>{accountStore.account.name}</span>}
        <span className='text-xl text-gray-800'>워크스페이스</span>
      </div>

      {/* 로그아웃 버튼 */}
      <span onClick={() => setOpenConfirmPopup(true)} className='cursor-pointer'>
        <img src={ICON.LOGOUT} alt='로그아웃' />
      </span>

      {openConfirmPopup && (
        <ConfirmPopup
          message='로그아웃 하시겠습니까?'
          onClickOk={handleLogout}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}

      {openAlertPopup && (
        <AlertPopup
          message={alertMessage}
          onClick={() => {
            setOpenAlertPopup(false);
          }}
        />
      )}
    </div>
  );
};
export default OuterNav;
