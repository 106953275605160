import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import usePoint from 'hooks/feature/point/usePoint';
import React, { useState } from 'react';
import { TextType } from 'types/common/text';
import { PointKiosk } from 'types/feature/workspace/point';
import { validDefaultZoom, validMinMaxZoom } from 'utils/common/validZoom';
import { handle400Error } from 'utils/error/handle400Error';

type Props = {
  webForm: PointKiosk;
  onChangeKioskForm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeKioskMapPosition: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pointId: string;
  fetchPoint: (pointId: string) => Promise<void>;
};

const PointKioskMapPosition = ({
  onChangeKioskForm,
  webForm,
  onChangeKioskMapPosition,
  pointId,
  fetchPoint,
}: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { putPointMapPosition } = usePoint();

  const onSaveKiosk = async () => {
    try {
      if (!webForm) return;
      // 줌값 유효성 처리
      const minZoom = Number(webForm.mapMinZoom);
      const maxZoom = Number(webForm.mapMaxZoom);
      const defaultZoom = Number(webForm.mapPosition.zoom);

      if (!validMinMaxZoom(minZoom, maxZoom)) {
        setOpenAlert(true);
        setAlertMessage(`지도 zoom 기본값은 최소값과\n최대값 사이의 숫자만 가능합니다.`);
        setOpenConfirm(false);
        return;
      }

      if (!validDefaultZoom(defaultZoom, minZoom, maxZoom)) {
        setOpenAlert(true);
        setAlertMessage(`zoom값은 15 ~ 24 사이의 값만 입력 가능합니다.`);
        setOpenConfirm(false);
        return;
      }

      await putPointMapPosition(pointId, webForm);
      await fetchPoint(pointId);
      setOpenConfirm(false);
      setOpenAlert(true);
      setAlertMessage('지도 기본 설정값을 저장하였습니다.');
    } catch (error) {
      handle400Error(error, () => {
        setOpenAlert(true);
        setAlertMessage('지도 기본 설정값을 수정할 수 없습니다.');
      });
    }
  };

  return (
    <section className='flex flex-col items-start gap-8 px-10 py-6 border border-[#CCC] w-full'>
      <Title text='키오스크' titleType='h3_bold' />

      <div className='flex flex-col gap-5'>
        <FlexFormControl
          name='센터 좌표'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>X</div>
                <Input
                  width='w-[90px]'
                  name='centerPositionX'
                  placeholder='1000.123'
                  onChange={onChangeKioskMapPosition}
                  // type='number'
                  value={webForm.mapPosition.centerPositionX}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>Y</div>

                <Input
                  width='w-[90px]'
                  name='centerPositionY'
                  placeholder='1000.123'
                  onChange={onChangeKioskMapPosition}
                  // type='number'
                  value={webForm.mapPosition.centerPositionY}
                />
              </div>
            </div>
          }
        />

        <FlexFormControl
          name='방향각'
          control={
            <div className='pl-[70px]'>
              <Input
                width='w-[90px]'
                name='rotation'
                placeholder='rotation'
                onChange={onChangeKioskMapPosition}
                // type='number'
                value={webForm.mapPosition.rotation}
              />
            </div>
          }
        />

        <FlexFormControl
          name='줌 레벨'
          control={
            <div className='flex gap-10'>
              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최소값</div>

                <Input
                  // type='number'
                  width='w-[90px]'
                  name='mapMinZoom'
                  placeholder='15~22'
                  onChange={onChangeKioskForm}
                  min='0'
                  max={webForm.mapPosition.zoom || '24'}
                  value={webForm.mapMinZoom || ''}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>기본값</div>
                <Input
                  // type='number'
                  width='w-[90px]'
                  name='zoom'
                  placeholder='15~22'
                  onChange={onChangeKioskMapPosition}
                  min={webForm.mapMinZoom || '0'}
                  max={webForm.mapMaxZoom || '24'}
                  value={webForm.mapPosition.zoom || ''}
                />
              </div>

              <div className='flex gap-[10px] items-center'>
                <div className={`${TextType.h4} h-[18px] w-[60px] text-gray-99`}>최대값</div>
                <Input
                  // type='number'
                  width='w-[90px]'
                  placeholder='15~22'
                  name='mapMaxZoom'
                  onChange={onChangeKioskForm}
                  value={webForm.mapMaxZoom || ''}
                  min={webForm.mapPosition.zoom || '0'}
                  max='24'
                />
              </div>
            </div>
          }
        />
      </div>

      <div className={`flex justify-center w-full mt-10`}>
        <Button text='저장' type='button' color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
      </div>

      {openConfirm && (
        <ConfirmPopup
          message='지도 기본 설정을 저장하시겠습니까?'
          onClickOk={onSaveKiosk}
          onClickCancel={() => {
            setOpenConfirm(false);
            setOpenAlert(false);
          }}
        />
      )}

      {/* 성공/실패 팝업 */}
      {openAlert && <AlertPopup onClick={() => setOpenAlert(false)} message={alertMessage} />}
    </section>
  );
};

export default PointKioskMapPosition;
