import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import FailPopup from 'components/common/popup/FailPopup';
import SucceedPopup from 'components/common/popup/SucceedPopup';
import Title from 'components/common/title/Title';
import { OPERATION_GUIDES } from 'data/guide/setting';
import usePoint from 'hooks/feature/point/usePoint';
import { ChangeEvent, useEffect, useState } from 'react';
import { HoursType, Point, PointOperation } from 'types/feature/workspace/workspace';
import { validLength } from 'utils/length/validLength';
import { WORD_LIMIT } from './constant';
import PointBusinessHours from './PointBusinessHours';
import PointHoliday from './PointHoliday';
import PointTab from '../basic/PointTab';

type Props = {
  pointId: string;
};
const PointOperationSetting = ({ pointId }: Props) => {
  // state
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [point, setPoint] = useState<Point | null>(null);
  const [operation, setOperation] = useState<PointOperation | null>(null);

  // hook
  const { putPointOperation, getPoint } = usePoint();

  // 운영 시간 수정
  const onChangeHours = (name: string, value: string) => {
    return setOperation(prev => {
      if (!prev) return null;

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // 운영 시간 수정
  const onChangeHoursText = (name: string, value: string) => {
    if (!validLength(value, WORD_LIMIT)) {
      setOpenFail(true);

      return setOperation(prev => {
        if (!prev) return null;
        return {
          ...prev,
          hoursText: {
            ...prev.hoursText,
            [name]: value.slice(0, WORD_LIMIT),
          },
        };
      });
    }

    return setOperation(prev => {
      if (!prev) return null;

      return {
        ...prev,
        hoursText: {
          ...prev.hoursText,
          [name]: value,
        },
      };
    });
  };

  /**
   * 운영시간 사용 유무 수정
   */
  const onChangeHoursUsed = () => {
    return setOperation(prev => {
      if (!prev) return null;

      return {
        ...prev,
        hoursUsed: !prev.hoursUsed,
      };
    });
  };

  // point 조회
  const fetchPoint = async (id: string) => {
    const response = await getPoint(id);

    if (response) {
      setPoint(response);
      setOperation(response.operation);
    }
  };

  /**
   * 빌딩 정보를 업데이트한다.
   */
  const updatePoint = async () => {
    try {
      if (!point || !operation) return;

      await putPointOperation(point.id, {
        ...operation,
      });
      await fetchPoint(point.id);

      setOpenConfirm(false);
      setOpenSuccess(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenFail(true);
        }
      }
    }
  };

  /**
   * 휴무일 사용 유무 수정
   */
  const onChangeHolidayUsed = () => {
    setOperation(prev => {
      if (!prev) return null;
      return {
        ...prev,
        closedDaysUsed: !prev.closedDaysUsed,
      };
    });
  };

  /**
   * 휴점일 수정
   */
  const onChangeHoliday = (closedDays: string[]) => {
    setOperation(prev => {
      if (!prev) return null;
      return {
        ...prev,
        closedDays,
      };
    });
  };

  /**
   * 운영시간 노출 타입을 변경한다.
   */
  const onChangeHoursType = (e: ChangeEvent<HTMLInputElement>) => {
    setOperation(prev => {
      if (!prev) return null;
      return {
        ...prev,
        hoursType: e.target.value as HoursType,
      };
    });
  };

  useEffect(() => {
    fetchPoint(pointId);
  }, [pointId]);

  if (!operation || !point) {
    return <> </>;
  }
  return (
    <section className='flex flex-col gap-6 p-10 bg-white border border-gray-ea'>
      <Title text='운영시간 및 휴무일' titleType='h2' />

      <div className='w-[500px]'>
        <GuideDescription guides={OPERATION_GUIDES} />
      </div>

      {/* 포인트 탭 */}
      <PointTab point={point} />

      <div className='flex flex-col gap-5 pb-10'>
        <Title text='운영시간' titleType='h3_bold' />
        <PointBusinessHours
          operation={operation}
          onChangeHours={onChangeHours}
          onChangeHoursUsed={onChangeHoursUsed}
          onChangeHoursText={onChangeHoursText}
          onChangeHoursType={onChangeHoursType}
        />
      </div>

      {/* 휴무일 */}
      <div className='flex flex-col gap-5'>
        <Title text='휴무일' titleType='h3_bold' />
        <PointHoliday
          operation={operation}
          onChangeHolidayUsed={onChangeHolidayUsed}
          onChangeHoliday={onChangeHoliday}
        />
      </div>

      <div className='flex justify-center w-full mt-5'>
        <Button text='저장' type='button' size={120} color={ButtonColor.primary} onClick={() => setOpenConfirm(true)} />
      </div>

      {/* 확인 팝업 */}
      {openConfirm && (
        <ConfirmPopup
          message='포인트 정보를 수정하시겠습니까?'
          onClickCancel={() => setOpenConfirm(false)}
          onClickOk={updatePoint}
        />
      )}
      {/* 성공 팝업 */}
      {openSuccess && <SucceedPopup text='저장 완료되었습니다.' closeModal={() => setOpenSuccess(false)} />}

      {/* 실패 팝업 */}
      {openFail && (
        <FailPopup
          closeModal={() => setOpenFail(false)}
          text={`운영시간 대체 설명은 ${WORD_LIMIT}자까지 작성 가능합니다.`}
        />
      )}
    </section>
  );
};
export default PointOperationSetting;
