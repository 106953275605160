import { IMAGES } from 'constants/images';
import React from 'react';

const LoadingContainer = () => {
  return (
    <div className={`w-full h-full bg-gray-cc relative z-30 top-0 flex justify-center items-center`}>
      <img src={IMAGES.LOADING} alt='loading' />
    </div>
  );
};

export default LoadingContainer;
