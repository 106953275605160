import { ScreenType } from 'types/feature/content/contents';

type Props = {
  screenType: ScreenType;
};

const MapScreen = ({ screenType }: Props) => {
  return (
    <div className='absolute z-10 w-full h-full'>
      {screenType === 'HORIZONTAL' ? (
        <div className='flex flex-col justify-between h-full'>
          <div className='w-full h-[127px] bg-black opacity-60' />
          <div className='w-full h-[127px] bg-black opacity-60' />
        </div>
      ) : (
        <div className='h-full'>
          <div className='h-[59px] bg-black opacity-60' />
          <div className='flex h-[546px] justify-between'>
            <div className='w-[150px] h-full bg-black opacity-60' />
            <div className='w-[150px] h-full bg-black opacity-60' />
          </div>
          <div className='h-[59px] bg-black opacity-60' />
        </div>
      )}
    </div>
  );
};

export default MapScreen;
