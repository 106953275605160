import RequiredMark from 'components/common/label/required/RequiredMark';
import React from 'react';

const ScreensaverAddTableHeader = () => {
  return (
    <>
      <colgroup>
        <col width='5%' />
        <col width='10%' />
        <col width='10%' />
        <col width='35%' />
        <col width='10%' />
        <col width='40%' />
      </colgroup>

      <thead>
        <tr className='whitespace-nowrap'>
          <th />
          <th className='px-2 text-center'>기기 타입</th>
          <th className='px-2 text-center'>파일 유형</th>
          <th className='px-2 text-left'>타이틀</th>
          <th className='px-2 text-left' rowSpan={2}>
            노출타입
            <RequiredMark />
          </th>
          <th className='px-2' rowSpan={2}>
            시작 날짜 및 종료 날짜
            <RequiredMark />
          </th>
        </tr>
      </thead>
    </>
  );
};
export default ScreensaverAddTableHeader;
