import { AxiosError } from 'axios';
import GuideDescription from 'components/common/description/GuideDescription';
import FileDropZone from 'components/common/file/drop-zone/FileDropZone';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import Title from 'components/common/title/Title';
import { IMAGES } from 'constants/images';
import { LOGO_GUIDES } from 'data/guide/setting';
import useFileUpload from 'hooks/common/useFileUpload';
import { useEffect, useState } from 'react';
import { FileType } from 'types/common/file/file';
import { FileUploadType } from 'types/common/file/fileUpload';
import { Logo, Point } from 'types/feature/workspace/workspace';
import { returnFileAccept } from 'utils/file/extension/returnFileAccept';
import { returnFileMaxSize } from 'utils/file/size/returnFileSize';

type Props = {
  onChangePointLogo: (logo: Logo) => void;
  point?: Point;
};
const PointLogo = ({ onChangePointLogo, point }: Props) => {
  const [fileName, setFileName] = useState('');
  const [fileUri, setFileUri] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  const { uploadFile } = useFileUpload();

  // 파일 삭제
  const deleteFile = () => {
    setFileName('');
    setFileUri('');

    onChangePointLogo({
      name: '',
      originalName: '',
    });
  };

  // 파일 업로드 실패 시
  const openFileErrorAlert = (message: string) => {
    setAlertMessage(message);
    setOpenAlertPopup(true);
    setFileLoading(false);
  };

  const onChangeFile = async (file: File) => {
    try {
      if (file) {
        setFileLoading(true);

        const response = await uploadFile(file, FileUploadType.LOGO_IMAGE);

        if (response) {
          setFileUri(response.fileUri);
          setFileName(response.fileName);

          onChangePointLogo({
            name: response.fileName,
            originalName: file.name,
            uri: response.fileUri,
          });

          setFileLoading(false);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setOpenAlertPopup(true);
          setAlertMessage('오류가 발생했습니다.');
          setFileLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setFileUri(point?.logo?.uri || '');
    setFileName(point?.logo?.originalName || '');
  }, [point?.logo]);
  return (
    <>
      <section className='flex w-full gap-10'>
        <div className='flex flex-col gap-2.5'>
          {/* 파일 업로드 */}
          <FileDropZone
            maxSize={returnFileMaxSize(FileUploadType.LOGO_IMAGE)}
            uploadFile={onChangeFile}
            fileUri={fileUri}
            fileType={FileType.image}
            deleteFile={deleteFile}
            accept={returnFileAccept(FileUploadType.LOGO_IMAGE)}
            openFileErrorAlert={openFileErrorAlert}
            loading={fileLoading}
            fileName={fileName}
            width='w-[400px]'
            height='h-[140px]'
            isLogo
          />

          {/* 가이드 */}
          <GuideDescription guides={LOGO_GUIDES} />
        </div>

        <div className='w-[450px] flex flex-col gap-8'>
          <Title text='노출 위치' titleType='h4' />
          <img src={IMAGES.SERVICE_LOGO} alt='로고 노출 위치' />
        </div>
      </section>

      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
      {openConfirmPopup && (
        <ConfirmPopup
          message={'로고를 삭제 하시겠습니까?'}
          onClickOk={() => {}}
          onClickCancel={() => setOpenConfirmPopup(false)}
        />
      )}
    </>
  );
};

export default PointLogo;
