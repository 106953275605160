import Button from '../button/Button';
import { ButtonColor } from '../button/Button.type';
import ModalContainer from '../modal/ModalContainer';

type Props = {
  message: string;
  onClick: () => void;
  width?: string;
};

const AlertNoKeybordPopup = ({ message, onClick, width = '' }: Props) => {
  return (
    <ModalContainer>
      <div className={`flex flex-col items-center gap-5 p-20 px-24 z-50 bg-white ${width}`}>
        <pre className='font-sans text-sm text-center break-words'>{message}</pre>
        <Button onClick={onClick} color={ButtonColor.primary} text='확인' type='button' />
      </div>
    </ModalContainer>
  );
};

export default AlertNoKeybordPopup;
