import FormControl from 'components/common/form-control/FormControl';
import CommonSelectBox from 'components/common/select/CommonSelectBox';
import Title from 'components/common/title/Title';
import { MACHINE_PLACE_TYPE_FILTER } from 'data/filter';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { MachinePlaceType } from 'types/feature/menu/machine/machine';
import WebMachineAddForm from './web/WebMachineAddForm';

type Props = {
  machineType: string;
  placeType: MachinePlaceType;
  setMachineBasicForm: Dispatch<SetStateAction<any>>;
};

const BasicMachineRegisterForm = ({ machineType, placeType, setMachineBasicForm }: Props) => {
  const handleChangeMachinePlace = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setMachineBasicForm((prev: any) => ({
      ...prev,
      placeType: value,
    }));
  };

  return (
    <div className='flex flex-col gap-10'>
      <div className='flex flex-col gap-5'>
        <Title text='기본 정보' titleType='h3_bold' />
        <main className='flex w-full gap-3'>
          <section className='flex flex-col w-full gap-3'>
            <FormControl
              name='구분'
              control={
                <CommonSelectBox
                  width='w-[155px]'
                  selected={placeType}
                  onChange={handleChangeMachinePlace}
                  options={MACHINE_PLACE_TYPE_FILTER}
                />
              }
            />
          </section>
        </main>
      </div>

      {machineType === 'WEB' && <WebMachineAddForm setMachineBasicForm={setMachineBasicForm} />}
    </div>
  );
};

export default BasicMachineRegisterForm;
