import FlexFormControl from 'components/common/form-control/FlexFormControl';
import Input from 'components/common/input/Input';
import { PointOperation } from 'types/feature/workspace/workspace';

type Props = {
  operation: PointOperation;
  onChangeHours: (name: string, value: string) => void;
};

const HoursTime = ({ operation, onChangeHours }: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChangeHours(name, value);
  };

  return (
    <div className='flex flex-col gap-2'>
      <FlexFormControl
        name='시작'
        labelSize={50}
        control={
          <Input
            width='w-48'
            type='time'
            name='hoursStart'
            onChange={onChange}
            value={operation.hoursStart || ''}
            key='hoursStart'
          />
        }
      />

      <FlexFormControl
        name='종료'
        labelSize={50}
        control={
          <Input
            width='w-48'
            type='time'
            name='hoursEnd'
            value={operation.hoursEnd || ''}
            onChange={onChange}
            key='hoursEnd'
          />
        }
      />
    </div>
  );
};
export default HoursTime;
