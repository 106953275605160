import RangeCalendar from 'components/common/calendar/RangeCalendar';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import DisplaySelectBox from 'components/feature/ad/screensaver/add/content/table/display/select/DisplaySelectBox';
import { IMAGES } from 'constants/images';
import dayjs from 'dayjs';
import useCustomPage from 'hooks/feature/custom-page/useCustomPage';
import { ChangeEvent, useState } from 'react';
import { DisplayType } from 'types/common/display';
import { CustomBoardContent } from 'types/feature/custom-page/customPage';

type Props = {
  customBoard: CustomBoardContent;
};

const UpdateCustomDate = ({ customBoard }: Props) => {
  // ! 상시노출일 경우에는 endDate를 null로 넣어줌
  const initialEndDate = customBoard.displayType === DisplayType.always ? null : new Date(customBoard.endDate);

  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [displayType, setDisplayType] = useState(customBoard.displayType);
  const [startDate, setStartDate] = useState<Date | null>(new Date(customBoard.startDate));
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

  const { updateCustomBoard } = useCustomPage();

  const handleChangeDisplayType = (e: ChangeEvent<HTMLSelectElement>) => {
    setDisplayType(e.target.value as DisplayType);
    setEndDate(null);
  };

  // 상시 설정
  const handleChangeDate = (date: Date | null) => {
    setStartDate(date);
  };

  // 기간 설정
  const handleRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  // 날짜 수정
  const handleSaveDate = async () => {
    try {
      if (displayType === DisplayType.duration) {
        if (endDate === null) {
          setOpenAlertPopup(true);
          setOpenConfirmPopup(false);
          setAlertMessage('종료 날짜가 필요합니다.');
          return;
        }
      }

      if (displayType === DisplayType.always) {
        await updateCustomBoard(customBoard.id, {
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(startDate).format('YYYY-MM-DD'),
          displayType,
        });
      } else {
        await updateCustomBoard(customBoard.id, {
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          displayType,
        });
      }

      setOpenConfirmPopup(false);
      setIsEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`flex items-center w-full gap-4`}>
        <div className='flex w-full gap-2'>
          <DisplaySelectBox selected={displayType} handleChange={handleChangeDisplayType} disabled={!isEdit} />
          <RangeCalendar
            displayType={displayType}
            disabled={!isEdit}
            startDate={startDate}
            endDate={endDate}
            handleDate={handleChangeDate}
            handleRange={handleRangeDate}
          />
        </div>
        {/* buttons */}
        {isEdit ? (
          <div className='flex gap-2'>
            {/* //TODO: 취소 시 원래 값으로 변경해줘야 함 */}
            <div onClick={() => setIsEdit(!isEdit)} className='w-[20px] cursor-pointer'>
              <img src={IMAGES.CANCEL} alt='취소' />
            </div>
            <div onClick={() => setOpenConfirmPopup(true)} className='w-[20px] cursor-pointer'>
              <img src={IMAGES.SUCCESS} alt='저장' />
            </div>
          </div>
        ) : (
          <div className='cursor-pointer' onClick={() => setIsEdit(!isEdit)}>
            <img src={IMAGES.PENCIL} alt='수정' />
          </div>
        )}
      </div>
      {openConfirmPopup && (
        <ConfirmPopup
          message='기간을 변경하시겠습니까?'
          onClickCancel={() => setOpenConfirmPopup(false)}
          onClickOk={handleSaveDate}
        />
      )}
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default UpdateCustomDate;
