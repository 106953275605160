import { useAxiosInternal } from 'api/useAxiosInternal';
import { Endpoint } from 'data/endPoint';
import { ApiResponse } from 'types/api/response';
import { Point } from 'types/feature/workspace/workspace';

const useInternalPoint = () => {
  const { api } = useAxiosInternal();

  const getInternalPoint = async (pointId: string) => {
    const result = await api.get<ApiResponse<Point>>(`${Endpoint.internalPoints}/${pointId}`);

    if (result) {
      return result.data;
    }
  };

  return {
    getInternalPoint,
  };
};
export default useInternalPoint;
