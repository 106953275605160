import UsedBadge from 'components/common/badge/used-badge/UsedBadge';
import CalendarHeader, { CalendarHeaderProps } from 'components/common/calendar/header/CalendarHeader';
import GuideDescription from 'components/common/description/GuideDescription';
import FlexFormControl from 'components/common/form-control/FlexFormControl';
import AlertPopup from 'components/common/popup/AlertPopup';
import { ICON } from 'constants/icons';
import { HOLIDAY_GUIDES } from 'data/guide/setting';
import 'dayjs/locale/ko';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { PointOperation } from 'types/feature/workspace/workspace';
import { closedDayToFormat, convertCurrentDay } from 'utils/convert-date/convertDate';

type Props = {
  operation: PointOperation;
  onChangeHolidayUsed: () => void;
  onChangeHoliday: (closedDays: string[]) => void;
};

const PointHoliday = ({ operation, onChangeHolidayUsed, onChangeHoliday }: Props) => {
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const holidays = operation.closedDays;

  const renderHeader = ({ decreaseMonth, increaseMonth, date }: CalendarHeaderProps) => (
    <CalendarHeader decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} date={date} />
  );

  const handleClickCalendar = (date: Date) => {
    if (holidays) {
      if (holidays.length > 3) {
        setOpenAlertPopup(true);
        setAlertMessage('휴점일은 최대 4개까지 등록 가능합니다.');
        return;
      }

      const dayArr = [...holidays, closedDayToFormat(date)];
      const filteredDays = Array.from(new Set(dayArr));

      onChangeHoliday(filteredDays);
    }
  };

  const resetHolidays = () => {
    onChangeHoliday([]);
  };

  const deleteOneHoliday = (day: string) => {
    if (holidays && holidays.length > 0) {
      const filteredHolidayList = holidays.filter(holiday => holiday !== day);

      onChangeHoliday(filteredHolidayList);
    }
  };

  return (
    <>
      <div className='relative flex flex-col gap-4'>
        <FlexFormControl
          name='휴무일 사용 여부'
          control={<UsedBadge used={operation.closedDaysUsed} toggleBadge={onChangeHolidayUsed} />}
        />

        {operation.closedDaysUsed && (
          <FlexFormControl
            name='날짜 선택'
            control={
              <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-2'>
                  <GuideDescription guides={HOLIDAY_GUIDES} width='w-[300px]' />

                  <div onClick={resetHolidays} className='cursor-pointer'>
                    <img src={ICON.TRASH_BUTTON} alt='삭제' />
                  </div>
                </div>

                {/* 선택된 날짜 목록 */}
                <div className='grid w-full grid-cols-5 gap-2'>
                  {holidays
                    // 날짜순으로 sorting
                    ?.sort((a, b) => {
                      return new Date(a).getTime() - new Date(b).getTime();
                    })
                    .map((holiday, index) => (
                      <div
                        onClick={() => deleteOneHoliday(holiday)}
                        key={`${holiday}-${index}`}
                        className='flex items-center justify-between w-full min-w-full gap-1 px-4 py-2 bg-white border rounded-sm cursor-pointer border-gray-22 shrink-0'
                      >
                        <div className='text-xs font-normal'>{convertCurrentDay(closedDayToFormat(holiday))}</div>
                        <img src={ICON.CLOSE} alt='삭제' className='h-[10px]' />
                      </div>
                    ))}
                </div>

                <div className='flex w-[360px]'>
                  <DatePicker
                    className='z-20'
                    onChange={handleClickCalendar}
                    dateFormat='MM.dd'
                    startDate={new Date()}
                    endDate={new Date()}
                    renderCustomHeader={renderHeader}
                    inline
                  />
                </div>
              </div>
            }
          />
        )}
      </div>
      {openAlertPopup && <AlertPopup message={alertMessage} onClick={() => setOpenAlertPopup(false)} />}
    </>
  );
};

export default PointHoliday;
