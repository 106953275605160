import { useAxios } from 'api/useAxios';
import { Endpoint } from 'data/endPoint';
import { useParams } from 'react-router-dom';
import { ScreenType } from 'types/feature/content/contents';

const useWaitingList = () => {
  const { api } = useAxios();
  const { workspaceId } = useParams();

  // ! 페이지네이션 없이 Contents 가져오는 함수 (스크린세이버 스케줄 추가에서 사용)
  const getContentsWithoutPage = async (screenType: ScreenType) => {
    if (!workspaceId) return;

    const res = await api.get<any>(Endpoint.contents, {
      params: {
        workspaceId,
        screenType,
        // ! 등록된 콘텐츠가 1만개가 넘어가면 스케줄 추가 페이지에 페이지네이션 추가 해줘야 함
        page: 0,
        size: 10000,
        title: '',
      },
    });

    return res.data.content;
  };

  return { getContentsWithoutPage };
};
export default useWaitingList;
