import CommonSelectBox from 'components/common/select/CommonSelectBox';
import { DISPLAY_TYPE } from 'data/filter';
import { ChangeEvent } from 'react';
import { DisplayType } from 'types/common/display';

type DisplaySelectBoxProps = {
  selected: DisplayType;
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
};

const DisplaySelectBox = ({ selected, handleChange, disabled }: DisplaySelectBoxProps) => {
  return (
    <CommonSelectBox
      disabled={disabled}
      options={DISPLAY_TYPE}
      selected={selected}
      width='w-24'
      height='h-9'
      onChange={handleChange}
    />
  );
};
export default DisplaySelectBox;
