import React from 'react';

const TypeCard = ({ type }: { type: string }) => {
  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <div
      className='flex justify-center items-center h-[18px] px-[6px] rounded-[4px]'
      style={{
        backgroundColor: '#FFF',
        border: `1px solid #444`,
      }}
    >
      <span
        className='inline-block text-center text-[11px] font-medium leading-[12px] tracking-[-0.22px]'
        style={{
          color: '#444',
        }}
      >
        {capitalizeFirstLetter(type)}
      </span>
    </div>
  );
};

export default TypeCard;
