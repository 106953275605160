/* eslint-disable react/require-default-props */
import Pagination from 'components/common/pagination/Pagination';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import ThumbnailTableRowCell from 'components/common/thumbnail/ThumbnailTableRowCell';
import { ICON } from 'constants/icons';
import useContents from 'hooks/feature/content/useContents';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'types/common/paths';
import { TextType } from 'types/common/text';
import { ContentItem, ContentTypeCodeKo, ScreenType, ScreenTypeCodeKo } from 'types/feature/content/contents';
import { replaceContentId, replaceWorkspaceId } from 'utils/common/replaceId';
import ContentTableHeader from './header/ContentTableHeader';

type ContentTableProps = {
  contentItems: ContentItem[];
  totalElements: number | undefined;
  currentPage: number;
  onChangePage: (pageNum: number) => void;
  screenType: ScreenType;
  searchWord: string;
  refetchContents: (pageNum: number, title: string, screenType: ScreenType) => Promise<void>;
  resetPage: () => void;
};

const ContentTable = ({
  contentItems,
  currentPage,
  totalElements,
  onChangePage,
  screenType,
  searchWord,
  refetchContents,
  resetPage,
}: ContentTableProps) => {
  // hook
  const { deleteContent } = useContents();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  // state
  const [isDelete, setIsDelete] = useState(false);
  const [deleteContentId, setDeleteContentId] = useState('');

  // table row 클릭
  const onClickTableRow = (contentId: string) => {
    if (workspaceId) {
      navigate(replaceContentId(replaceWorkspaceId(RoutePath.contentDetail, workspaceId), contentId), {
        state: {
          pageIndex: currentPage || 0,
          screenType,
          searchWord,
        },
      });
    }
  };

  // 테이블의 삭제 버튼 클릭
  const deleteContentRow = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();
    setDeleteContentId(id);
    setIsDelete(true);
  };

  // 컨텐츠 삭제, 목록 조회
  const deleteAndRefetchContent = async () => {
    try {
      if (deleteContentId) {
        await deleteContent(deleteContentId);
        await refetchContents(0, searchWord, screenType);
        resetPage();
        window.history.replaceState(
          {
            pageIndex: 0,
            screenType,
            searchWord,
          },
          '',
        );
        setIsDelete(false);
      }
    } catch (e) {
      console.warn('콘텐츠를 삭제할 수 없습니다.', e);
    }
  };

  return (
    <div className='flex flex-col justify-between h-[591px]'>
      <table className='w-full'>
        <ContentTableHeader />

        <tbody>
          {
            // 데이터 없을 경우
            contentItems.length < 1 ? (
              <tr className='h-[52px]'>
                <td colSpan={5}>
                  <div className='flex justify-center w-full text-sm text-gray-99'>등록된 콘텐츠가 없습니다.</div>
                </td>
              </tr>
            ) : (
              // 데이터 있을 경우
              contentItems.map(row => (
                <tr key={row.id} className='cursor-pointer h-[48px]' onClick={onClickTableRow.bind(this, row.id)}>
                  {/* 구분 가로/세로 */}
                  <td>
                    <div className={`${TextType.body1} text-gray-44`}>{ScreenTypeCodeKo[row.screenType]}</div>
                  </td>

                  {/* 파일 유형 */}
                  <td className={`${TextType.body1} text-gray-44`}>{ContentTypeCodeKo[row.fileType]}</td>

                  {/* 타이틀 */}
                  <td>
                    <ThumbnailTableRowCell
                      title={row.title}
                      files={row.file}
                      fileType={row.fileType}
                      titleStyle={TextType.h4_bold}
                    />
                  </td>

                  {/* 최근 수정일 */}
                  <td className={`${TextType.body1} text-gray-44`}>{row.updatedDate}</td>

                  {/* 삭제 */}
                  <td onClick={e => deleteContentRow(e, row.id)}>
                    <div className='flex items-center justify-center'>
                      <img src={ICON.TRASH_BUTTON} alt='삭제' />
                    </div>
                  </td>
                </tr>
              ))
            )
          }
        </tbody>
      </table>

      {/* 페이지네이션 */}
      {contentItems.length > 0 && (
        <div>
          <Pagination totalElements={totalElements ?? 0} currentPage={currentPage} onChangePage={onChangePage} />
        </div>
      )}

      {isDelete && (
        <ConfirmPopup
          message='정말 삭제하시겠습니까?'
          onClickOk={deleteAndRefetchContent}
          onClickCancel={() => setIsDelete(false)}
        />
      )}
    </div>
  );
};

export default ContentTable;
