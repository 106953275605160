/* eslint-disable no-nested-ternary */
import { AxiosError } from 'axios';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import GuideDescription from 'components/common/description/GuideDescription';
import Input from 'components/common/input/Input';
import AlertPopup from 'components/common/popup/AlertPopup';
import ConfirmPopup from 'components/common/popup/ConfirmPopup';
import { URL_SETTING_GUIDES } from 'data/guide/setting';
import useWorkspace from 'hooks/feature/workspace/useWorkspace';
import { ChangeEvent, useEffect, useState } from 'react';
import { InputStatus, Workspace } from 'types/feature/workspace/workspace';

type Props = {
  workspace: Workspace;
  fetchWorkspace: () => Promise<void>;
};

const UrlIdInput = ({ workspace, fetchWorkspace }: Props) => {
  // state
  const [registeredClientPathList, setRegisteredClientPathList] = useState<string[]>([]);
  const [clientPath, setClientPath] = useState<string>(workspace.clientPath || '');
  const [isConfirm, setIsConfirm] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [inputStatus, setInputStatus] = useState<InputStatus>('default');
  const [isCheckDuplicated, setIsCheckDuplicated] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  // hook
  const { getWorkspaces, patchWorkspaceClientPath } = useWorkspace();
  // var
  const nothingChanged = workspace.clientPath === clientPath;

  /**
   * client path 를 변경한다.
   * @param {ChangeEvent<HTMLInputElement>} e
   */
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCheckDuplicated(false);
    setIsDuplicate(false);
    setInputStatus(validateClientPath(e.target.value));
    setClientPath(e.target.value);
  };

  /**
   * 중복체크 버튼을 클릭한다.
   */
  const onClickDuplicateButton = () => {
    if (!clientPath) return;

    setIsCheckDuplicated(true);

    const isIdDuplicated = checkIdDuplicate(registeredClientPathList, clientPath);
    setIsDuplicate(isIdDuplicated);

    if (!isIdDuplicated) {
      setInputStatus('default');
    }
  };

  /**
   * client path 를 변경한다.
   */
  const onClickSave = async () => {
    try {
      resetPopups();
      await patchWorkspaceClientPath(workspace.id, clientPath);
      await fetchWorkspace();
      setIsAlert(true);
      setAlertMessage('URL ID를 변경했습니다.');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status && error.response.status === 400) {
          setIsAlert(true);
          setAlertMessage('URL ID 변경에 실패했습니다.');
        }
      }
    }
  };

  /**
   * @desc 등록되어 있는 client path 를 조회한다.
   */
  const fetchRegisteredClientPathList = async () => {
    const workspaceList = await getWorkspaces();
    const clientPaths = workspaceList.map(work => work.clientPath);
    setRegisteredClientPathList(clientPaths);
  };

  /**
   * 컨펌, 알랏 리셋
   */
  const resetPopups = () => {
    setIsConfirm(false);
    setIsAlert(false);
    setAlertMessage('');
  };

  useEffect(() => {
    fetchRegisteredClientPathList();
  }, []);

  useEffect(() => {
    if (nothingChanged) {
      setIsDuplicate(false);
      setIsCheckDuplicated(false);
      setInputStatus('default');
    }
  }, [nothingChanged]);

  return (
    <section className='flex flex-col gap-2.5'>
      <div className='flex gap-2.5 items-start'>
        <div className='flex flex-col gap-2.5'>
          <Input
            width='w-72'
            name='clientPath'
            status={inputStatus}
            showWarning={isDuplicate || inputStatus === 'error'}
            placeholder={workspace.clientPath}
            value={clientPath}
            onChange={onChangeInput}
            disabled={workspace.clientPathModified}
          />

          {/* 에러메시지 */}
          {!workspace.clientPathModified && (
            <span
              className={`${
                isDuplicate
                  ? 'text-[#D94545]'
                  : isCheckDuplicated && !isDuplicate
                  ? 'text-gray-99'
                  : getTextStyle(inputStatus)
              } text-[11px] font-medium leading-[14px] tracking-[-0.22px]`}
            >
              {isDuplicate
                ? '중복된 아이디가 존재합니다.'
                : isCheckDuplicated && !isDuplicate
                ? '설정된 아이디로 URL이 변경됩니다.'
                : getMessage(inputStatus)}
            </span>
          )}
        </div>

        {!workspace.clientPathModified && (
          <div className='flex items-center gap-2'>
            {/* 중복체크 버튼 */}
            <Button
              text='중복체크'
              type='button'
              // 입력한 값이 기존에 등록된 값과 같거나 입력한 값이 올바르지 않을 경우 중복체크를 할 필요가 없다.
              disabled={nothingChanged || inputStatus === 'error'}
              size={80}
              color={ButtonColor.secondary}
              onClick={onClickDuplicateButton}
            />

            {/* 저장 버튼 */}
            <Button
              text='저장'
              // 중복검사를 안했거나 아이디가 중복되었다면 저장할 수 없다.
              disabled={!isCheckDuplicated || isDuplicate}
              size={80}
              type='button'
              color={ButtonColor.primary}
              onClick={() => {
                setIsAlert(false);
                setIsConfirm(true);
              }}
            />
          </div>
        )}
      </div>

      {!workspace.clientPathModified && (
        <GuideDescription width='w-[360px]' guides={URL_SETTING_GUIDES} icon={false} listDisc />
      )}

      {isConfirm && (
        <ConfirmPopup message='URL ID를 수정하시겠습니까?' onClickCancel={resetPopups} onClickOk={onClickSave} />
      )}
      {isAlert && <AlertPopup onClick={resetPopups} message={alertMessage} />}
    </section>
  );
};

export default UrlIdInput;

/**
 * 메시지에 적용할 스타일을 반환한다.
 * @param {InputStatus} status
 * @returns {string} 에러메시지 스타일
 */
const getTextStyle = (status: InputStatus): string => {
  switch (status) {
    case 'success':
      return 'text-primary-DA_Green';
    case 'error':
      return 'text-[#D94545]';
    default:
      return 'text-gray-99';
  }
};

/**
 * 인풋 값에 따라 에러메시지를 반환한다.
 * @param {InputStatus} status
 * @returns {string} 에러 메시지
 */
const getMessage = (status: InputStatus): string => {
  switch (status) {
    case 'success':
      return '사용 가능한 아이디입니다.';
    case 'error':
      return '아이디를 확인해주세요.';
    default:
      return '설정된 아이디로 URL이 변경됩니다.';
  }
};

/**
 * 아이디 중복검사를 한다.
 * @param {string[]} list 기존에 존재하는 아이디 목록
 * @param {string} id 중복을 검사할 아이디
 * @returns {boolean} 중복 여부
 */
const checkIdDuplicate = (list: string[], id: string): boolean => {
  if (list.includes(id)) {
    return true;
  }
  return false;
};

/**
 * input 값의 유효성을 검사한다.
 * @param {string} value
 * @returns {boolean} 검사 결과
 */
const validateClientPath = (value: string): InputStatus => {
  // 공백이 포함된 경우
  if (/\s/.test(value)) {
    return 'error';
  }

  // 허용된 문자: 영문 대소문자, 숫자, '-', '_'
  const regex = /^[A-Za-z0-9-_]{1,16}$/;
  if (!regex.test(value)) {
    return 'error';
  }

  // 입력이 유효한 경우
  return 'success';
};
