export const Endpoint = {
  // internal
  internalDashBoardsMachineStatuses: '/internal/dashboards/machine-statuses',
  internalFloors: '/internal/floors',
  internalPoints: '/internal/points',
  internalFileManagerMachinesCommand: '/internal/file-manager-machines/:machineId/commands',

  // external
  auth: '/admin/auth/token',
  account: '/admin/accounts',
  logout: '/admin/auth/logout',

  workspaces: '/admin/workspaces',
  workspaceV2: '/admin/v2/workspaces/:workspaceId',
  workspace: '/admin/workspaces/:workspaceId',
  workspaceClientPath: '/admin/workspaces/:workspaceId/client-path',
  workspaceWebUsed: '/admin/workspaces/:workspaceId/web-used',
  workspaceMobileUsed: '/admin/workspaces/:workspaceId/mobile-used',
  workspaceNavigation: '/admin/workspaces/:workspaceId/navigation',

  points: '/admin/points',
  mainPoint: '/admin/points/:pointId/main',
  pointOperation: '/admin/points/:pointId/operation',
  pointMapPosition: '/admin/points/:pointId/map-position',

  // 스크린세이버 콘텐츠
  contents: '/admin/contents',
  content: '/admin/contents/:contentId',
  screensavers: '/admin/screensavers',
  screensaversV2: '/admin/v2/screensavers',

  // 테넌트
  tenants: '/admin/tenants',
  tenantDetail: '/admin/tenants/:tenantId',
  tenantCustomFields: '/admin/tenant-custom-fields/:tenantCustomFieldId',

  // 카테고리
  tenantCategoryGroups: '/admin/tenant-category-groups',
  tenantCategoryGroup: '/admin/tenant-category-groups/:tenantCategoryGroupId',
  poiCategories: '/admin/poi-content-categories',

  // 파일 업로드
  files: '/admin/files',

  // 설정
  customField: '/admin/custom-fields',

  // 메뉴
  mainMenus: '/admin/main-menus',
  mainMenusV2: '/v2/admin/main-menus',
  mainMenuIconTypes: '/admin/main-menu-icons',

  customMainMenuTypes: '/admin/codes/custom-main-menu-types',

  // 커스텀 페이지
  customPage: '/admin/custom-pages/:customPageId',
  customBoardAdd: '/admin/custom-boards',
  customBoard: '/admin/custom-boards/:customBoardId',

  // 언어
  language: '/admin/languages',

  // 기기
  connectedMachines: '/admin/connect-machines',
  resetConnectedMachines: '/admin/connect-machines/:machineId/reset',

  // 기기 관리 웹
  machines: '/admin/machines',
  machine: '/admin/machines/:machineId',
  machinesDiscard: '/admin/machines/:machineId/discard',

  // 기기 관리 데스크탑
  desktopMachines: '/admin/desktop-machines/',
  desktopMachine: '/admin/desktop-machines/:machineId',
  desktopMachinesDiscard: '/admin/desktop-machines/:machineId/discard',

  // 층
  floor: '/admin/floors',

  // 테마
  themes: '/admin/themes',
  activeColorSet: '/admin/theme-color-sets/:colorSetId/use',

  // 모바일
  mobiles: '/admin/mobiles/:mobileId',

  // 동기화
  sync: '/admin/synchronization',
  syncCheck: '/admin/synchronization/check',
  syncResult: '/admin/synchronization/result',
};
